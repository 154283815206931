import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react"
import { useMutation } from "@tanstack/react-query"
import { createPatient, CreatePatientType } from "api/patient"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"
import React from "react"
import Attention from "../../../assets/attention.svg"
import AButton from "../../../components/AButton"
import useToast from "../../../hooks/useToast"

interface IProps {
  isOpen: boolean
  onClose: () => void
  cancelRef: React.MutableRefObject<null>
  patient: CreatePatientType | undefined
  onPatientSuccess: (data: { id?: number; password?: string }) => void
  shouldFixEmail: () => void
}
const CreateNoVerifiedEmailPatient: React.FC<IProps> = ({
  isOpen,
  onClose,
  cancelRef,
  patient,
  onPatientSuccess,
  shouldFixEmail,
}) => {
  const toast = useToast()
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()

  const createPatientMutation = useMutation(createPatient)

  const handleNewPatient = () => {
    if (patient === undefined) return
    const patientWithUnknownEmail = {
      ...patient,
      hasUnknownEmail: true,
    }
    createPatientMutation.mutate(patientWithUnknownEmail, {
      onSuccess: (data) => {
        // Patient andrew plus
        onPatientSuccess({ id: data.id, password: data.temp_password })
        onClose()
      },
      onError: (data: any) => {
        onCollaboratorUnauthorized(data)
        toast({
          status: "error",
          title: data.response.data.error.message,
        })
      },
    })
  }
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent maxW={724} borderRadius={8} py={8}>
        <AlertDialogHeader fontSize={18}>
          <Flex alignItems={"center"} gap={2}>
            <Image
              src={Attention}
              alt="attention icon"
              w={"40px"}
              h={"40px"}
              pb={1}
            />
            <Text>Attention, l'e-mail saisi semble incorrect</Text>
          </Flex>
        </AlertDialogHeader>
        <AlertDialogBody>
          Veuillez confirmer avec votre patient la saisi de l'e-mail. En cas
          d'erreur, votre patient ne pourra pas recevoir son mot de passe
          temporaire, si se connecter à l'application.
        </AlertDialogBody>
        <AlertDialogFooter gap={4}>
          <AButton
            ml={3}
            text="Ignorer l'alerte"
            p="12px 25px"
            variant="tertiary"
            onClick={handleNewPatient}
          />
          <AButton
            ref={cancelRef}
            onClick={shouldFixEmail}
            text="Corriger l'e-mail"
            p="12px 45px"
          />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default CreateNoVerifiedEmailPatient
