import { useQuery } from "@tanstack/react-query"
import { getShouldDisplayOnboarding } from "api/osteo"
import { useAppContext } from "AppContext"
import { useEffect, useState } from "react"

/**
 * Hook permettant de gérer l'affichage de l'onboarding
 * On l'affiche que si le serveur le demande et qu'il n'a jamais été affiché localement
 */
const useShouldDisplayOnboarding = () => {
  const [localOnboardingDisplayed, setLocalOnboardingDisplayed] = useState<
    boolean | undefined
  >(undefined)
  const [shouldDisplayOnboarding, setShouldDisplayOnboarding] = useState(false)

  const displayedOnboarding = () => {
    localStorage.setItem(ONBOARDING_DISPLAYED_KEY, "true")
    setLocalOnboardingDisplayed(true)
  }

  const ONBOARDING_DISPLAYED_KEY = "onboardingDisplayed"

  const { user } = useAppContext()
  const { data } = useQuery({
    queryKey: ["shouldDisplayOnboarding"],
    queryFn: getShouldDisplayOnboarding,
    enabled: Boolean(user),
  })

  useEffect(() => {
    setLocalOnboardingDisplayed(
      Boolean(localStorage.getItem(ONBOARDING_DISPLAYED_KEY))
    )
  }, [])

  useEffect(() => {
    if (localOnboardingDisplayed === undefined) return //Don't set value until storage is not set
    if (localOnboardingDisplayed === true) {
      setShouldDisplayOnboarding(false)
    } else if (data?.shouldDisplayOnboarding === true) {
      setShouldDisplayOnboarding(true)
    } else {
      setShouldDisplayOnboarding(false)
    }
  }, [localOnboardingDisplayed, data])

  return {
    shouldDisplayOnboarding,
    displayedOnboarding,
  }
}

export default useShouldDisplayOnboarding
