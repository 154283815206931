import {
  AspectRatio,
  Box,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { TrainingVideoUploadedType } from "api/appointmentReason"
import { FaPlay, FaPlus } from "react-icons/fa"
import AButton from "components/AButton"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { deleteTrainingVideoUploaded } from "api/trainingVideoExercice"
import useToast from "hooks/useToast"
import AModalHeader from "components/AModalHeader/AModalHeader"
import AddUploadedExerciceModal from "pages/NewAppointment/components/SelectExercices/components/AddUploadedExerciceModal"
import NewExerciceModal from "pages/NewProgram/components/NewExerciceModal"
import DeleteUplaodedExerciceModal from "components/AUploadedExerciceCard/DeleteUplaodedExerciceModal"
import ProgramOptionsButton from "pages/NewAppointment/components/SelectExercices/components/AddProgramModal/ProgramOptionsButton"
interface IProps {
  video: TrainingVideoUploadedType
  title?: string
  isSelecting?: boolean
  isSelected?: boolean
  isDisabled?: boolean
  onClick: () => void
  onAdd?: () => void
  isAddCard?: boolean
  appointmentReason?: number | undefined
  canEdit?: boolean
}

const AUploadedExerciceCardBase: React.FC<IProps> = ({
  video,
  title,
  isSelecting,
  isSelected,
  isDisabled,
  onClick,
  onAdd,
  isAddCard = true,
  appointmentReason,
  canEdit = false,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure()
  const {
    isOpen: deleteModalIsOpen,
    onOpen: deleteModalOnOpen,
    onClose: deleteModalOnClose,
  } = useDisclosure()

  const toast = useToast()

  const deleteTrainingVideoUploadedMutation = useMutation({
    mutationFn: deleteTrainingVideoUploaded,
    onSuccess: async () => {
      await queryClient.refetchQueries([
        "uploadedVideos",
        appointmentReason ?? -1,
      ])
      deleteModalOnClose()
      toast({
        status: "success",
        title: "Exercice supprimé avec succès",
      })
      onClose()
    },
    onError: () => {
      toast({
        status: "error",
        title: "Une erreur est survenue lors de la suppression",
      })
    },
  })
  const queryClient = useQueryClient()
  const [hasError, setHasError] = useState(false)

  const handleDelete = () => {
    deleteTrainingVideoUploadedMutation.mutate(video.id)
  }

  const handleEditUploadedExercice = () => {
    onClose()
    onEditModalOpen()
  }

  return (
    <>
      <Box w={"100%"}>
        <Box
          bg="white"
          w="100%"
          overflow="hidden"
          cursor={isDisabled ? "not-allowed" : undefined}
          opacity={isDisabled ? 0.4 : 1}
        >
          <AspectRatio ratio={2.37}>
            <Box
              borderRadius={9}
              pos="relative"
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              {hasError && (
                <Flex
                  w="100%"
                  h="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner />
                </Flex>
              )}
              <Image
                alt={video.title}
                src={video.bunnyCDN.preview}
                h="100%"
                objectFit="cover"
                style={{
                  visibility: hasError ? "hidden" : "visible",
                  width: hasError ? "0" : "100%",
                }}
                onError={(e) => {
                  setHasError(true)
                  // Optionnel : retry après un délai
                  setTimeout(() => {
                    setHasError(false)
                    ;(e.target as HTMLImageElement).src = video.bunnyCDN.preview
                  }, 200)
                }}
              />
              {isHovering && !isDisabled && (
                <Flex
                  bg="rgba(17, 23, 45, 0.5)"
                  pos="absolute"
                  top={0}
                  left={0}
                  w="100%"
                  h="100%"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={
                    isDisabled
                      ? () => null
                      : () => {
                          if (isAddCard) {
                            onAdd?.()
                          } else {
                            onOpen()
                          }
                        }
                  }
                >
                  <Image
                    src={video.bunnyCDN.webp}
                    alt={video.title}
                    width="100%"
                    height="100%"
                    objectFit="cover"
                  />

                  <Flex pos="absolute" bottom={2} right={2}>
                    <Flex
                      cursor="pointer"
                      opacity={0.5}
                      _hover={{ opacity: 1 }}
                      bg="#11172D"
                      p={3}
                      borderRadius={"100%"}
                      justifyContent="center"
                      alignItems="center"
                      h={10}
                      w={10}
                      onClick={
                        isDisabled
                          ? () => null
                          : (e) => {
                              e.stopPropagation()
                              onOpen()
                            }
                      }
                    >
                      <FaPlay
                        className="button-icon"
                        fontSize={16}
                        color="#fdd7c0"
                      />
                    </Flex>
                    {isAddCard && (
                      <Flex
                        cursor="pointer"
                        opacity={0.5}
                        _hover={{ opacity: 1 }}
                        bg="#11172D"
                        p={3}
                        borderRadius={"100%"}
                        justifyContent="center"
                        alignItems="center"
                        h={10}
                        w={10}
                        onClick={
                          onAdd
                            ? (e) => {
                                e.stopPropagation()
                                onAdd()
                              }
                            : undefined
                        }
                        ml={1}
                      >
                        <FaPlus
                          className="button-icon"
                          fontSize={20}
                          color="#fdd7c0"
                        />
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              )}
            </Box>
          </AspectRatio>

          <Flex
            pt={2}
            alignItems="center"
            onClick={onClick}
            cursor="pointer"
            as="button"
          >
            <Box
              onClick={isAddCard && !isDisabled ? () => onAdd?.() : undefined}
            >
              <div>
                <Text fontSize={14} fontWeight={700} color="tertiary.500">
                  Niveau {video.level}
                </Text>
              </div>
            </Box>
          </Flex>
        </Box>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay bg="blackAlpha.400" />
          <ModalContent maxW="724">
            <Box
              pos="relative"
              borderRadius={9}
              bg="white"
              w={724}
              h={465}
              overflow={"hidden"}
            >
              <AModalHeader
                title={video.title}
                handleEdit={handleEditUploadedExercice}
                handleDelete={deleteModalOnOpen}
                handleClose={onClose}
                p={4}
                canEdit={canEdit}
              />
              <Box pos="relative" pt="56.25%">
                <iframe
                  src={`${video.bunnyCDN.video}?autoplay=true&loop=false&muted=true&preload=true&responsive=true`}
                  loading="lazy"
                  style={{
                    border: 0,
                    position: "absolute",
                    top: 0,
                    height: "100%",
                    width: "100%",
                  }}
                  allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                  allowFullScreen
                  title={video.title}
                />
              </Box>
            </Box>
          </ModalContent>
        </Modal>
        <DeleteUplaodedExerciceModal
          isOpen={deleteModalIsOpen}
          onClose={deleteModalOnClose}
          handleDelete={handleDelete}
        />
      </Box>
      {isEditModalOpen && (
        <NewExerciceModal
          isOpen={isEditModalOpen}
          onClose={onEditModalClose}
          currentVideo={video}
        />
      )}
    </>
  )
}

export default AUploadedExerciceCardBase
