import { Flex, Image, Link } from "@chakra-ui/react"
import {
  SelectedExerciceType,
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
} from "api/appointmentReason"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"
import SelectProgramExercices from "pages/NewProgram/components/SelectProgramExercices"
import React, { useState } from "react"
import arrowBack from "../../assets/arrow_back.svg"
import { Link as RouterLink } from "react-router-dom"

const NewProgram = () => {
  const [reasonType, setReasonType] = useState<"zones" | "pathologies" | null>(
    "zones"
  )
  const [reason, setReason] = useState<ZonePathologieType>()
  const [selectedExercices, setSelectedExercices] = useState<
    SelectedExerciceType[]
  >([])
  const [selectedUploadedExercices, setSelectedUploadedExercices] = useState<
    SelectedUploadedExerciceType[]
  >([])
  const [selectedVideoOrder, setSelectedVideoOrder] = useState<
    SelectedVideoOrderType[]
  >([])

  return (
    <Flex direction="column">
      <Link as={RouterLink} to="/exercices" m="16px" mb="0px" w="fit-content">
        <Flex
          mb={6}
          cursor="pointer"
          p="8px"
          bgColor="primary.200"
          w="fit-content"
          display="block"
          rounded="9px"
        >
          <Image src={arrowBack} width="24px" height="24px" />
        </Flex>
      </Link>
      <Flex pb="100px">
        <SelectProgramExercices
          reason={reason}
          setReason={setReason}
          reasonType={reasonType}
          setReasonType={setReasonType}
          selectedExercices={selectedExercices}
          setSelectedExercices={setSelectedExercices}
          selectedUploadedExercices={selectedUploadedExercices}
          setSelectedUploadedExercices={setSelectedUploadedExercices}
          selectedVideoOrder={selectedVideoOrder}
          setSelectedVideoOrder={setSelectedVideoOrder}
          isCreatingProgram
        />
      </Flex>
    </Flex>
  )
}

export default NewProgram
