import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  Heading,
  Text,
  ModalCloseButton,
} from "@chakra-ui/react"
import AButton from "components/AButton"
import React from "react"

interface ADisableAppointmentModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  description: string
  onConfirm: () => void
  onRefuse: () => void
}

const ADisableAppointmentModal = ({
  isOpen,
  onClose,
  title,
  description,
  onConfirm,
  onRefuse,
}: ADisableAppointmentModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton aria-label="Fermer" />
        <ModalHeader>
          <Heading fontSize="24px" fontWeight="bold">
            {title}
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text>{description}</Text>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="end" gap="2" w="full">
            <AButton
              text="Annuler"
              variant="custom"
              onClick={onClose}
              bg="none"
            />
            <AButton text="Non" variant="tertiary" onClick={onRefuse} />
            <AButton text="Oui" variant="primary" onClick={onConfirm} />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ADisableAppointmentModal
