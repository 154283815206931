import { AddIcon } from "@chakra-ui/icons"
import { Box, Flex, Heading, useDisclosure } from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { updateAppointment, updateAppointmentIsActive } from "api/appointments"
import { useAppContext } from "AppContext"
import ADisableAppointmentModal from "components/ADisableAppointmentModal"
import PatientReviews from "components/PatientReviews"
import { format, isBefore } from "date-fns"
import useIsJobKineOrPodo from "hooks/useIsJobKineOrPodo"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import useToast from "hooks/useToast"
import AppointmentMedias from "pages/Appointment/components/AppointmentMedias"
import Stats from "pages/Patient/components/Stats"
import React, { useCallback, useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import getUtcDate from "utils/getUtcDate"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import {
  getPatient,
  getPatientReviews,
  PatientAppointmentType,
  PatientType,
} from "../../api/patient"
import AButton from "../../components/AButton"
import ASkeleton from "../../components/ASkeleton"
import AppointmentCard from "../Patient/components/AppointmentCard"
import Infos from "../Patient/components/Infos"
import EditableInput from "./components/EditableInput"
import Training from "./components/Training"

const Appointment = () => {
  const { patientId, appointmentId } = useParams()
  const navigate = useNavigate()
  const [patient, setPatient] = React.useState<PatientType | undefined>(
    undefined
  )
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()
  const { currentScope } = useAppContext()
  const [currentAppointment, setCurrentAppointment] =
    React.useState<PatientAppointmentType | null>(null)
  const getPatientParams = {
    patientId,
    ...getCurrentScopeParams(currentScope),
  }

  const { data: reviews } = useQuery({
    queryKey: ["getPatientReviews", patientId],
    queryFn: () => getPatientReviews({ patientId: parseInt(patientId ?? "") }),
    enabled: !!patientId,
  })

  const { isLoading } = useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(getPatientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error: any) =>
        retryPatientNoAccessRight(failureCount, error) &&
        retryCollaboratorUnauthorized(failureCount, error),
      onSuccess: (data) => {
        setPatient(data)
      },
      onError: (error: any) => {
        onPatientNoAccessRight(error)
        onCollaboratorUnauthorized(error)
      },
    }
  )

  const toast = useToast()
  const queryClient = useQueryClient()
  const deactivateLastAppointmentMutation = useMutation(
    updateAppointmentIsActive,
    {
      onError: () => {
        toast({
          status: "error",
          title: "Une erreur s'est produite",
        })
      },
      onSuccess: () => {
        toast({
          status: "success",
          title: `Consultation désactivée avec succès`,
        })

        if (patientId) {
          queryClient.invalidateQueries(["getPatient", patientId.toString()])
        }
        navigate(`/patients/${patientId}/appointments/new`)
      },
    }
  )
  const hasMoreThanOneAppointmentActive = useMemo(() => {
    return (
      (patient?.appointments?.filter(
        (appointment) => appointment.isActive && !appointment.treatment
      )?.length ?? 0) > 1
    )
  }, [patient?.appointments])

  useEffect(() => {
    setCurrentAppointment(
      !appointmentId
        ? null
        : patient?.appointments?.find(
            (appointment) => appointment.id === parseInt(appointmentId)
          ) ?? null
    )
  }, [appointmentId, patient])

  const handleNewAppointment = (desactivateTreatment: boolean) => {
    if (desactivateTreatment && lastAppointmentActive?.id) {
      deactivateLastAppointmentMutation.mutate({
        id: lastAppointmentActive.id.toString(),
        isActive: false,
      })
    } else {
      navigate(`/patients/${patientId}/appointments/new`)
    }
  }

  const isJobKineOrPodo = useIsJobKineOrPodo()
  const updateAppointmentMutation = useMutation(updateAppointment)

  const lastAppointmentActive = useMemo(() => {
    return patient?.appointments?.find((appointment) => appointment.isActive)
  }, [patient?.appointments])

  const handleEditableInputChange = (
    type: string,
    value: string,
    setSubmitting: (isSubmitting: boolean) => void,
    onClose: () => void
  ) => {
    if (!currentAppointment?.id) return

    updateAppointmentMutation.mutate(
      { id: currentAppointment?.id?.toString(), data: { [type]: value } },
      {
        onSuccess: (data: PatientAppointmentType) => {
          if (!setCurrentAppointment) return

          setSubmitting(false)
          setCurrentAppointment({
            ...currentAppointment,
            osteoAdvice: data.osteoAdvice,
            internalNote: data.internalNote,
            secondaryInternalNote: data.secondaryInternalNote,
            patientTitle: data.patientTitle,
          })

          if (type === "patientTitle") {
            queryClient.invalidateQueries(["getPatient", patientId?.toString()])
          }

          onClose()
          toast({
            status: "success",
            title: "Modifications enregistrées",
          })
        },
        onError: () => {
          setSubmitting(false)
          toast({
            status: "error",
            title: "Une erreur s'est produite",
          })
        },
      }
    )
  }

  const onNewAppointmentTapped = useCallback(() => {
    if (lastAppointmentActive) {
      onOpen()
    } else {
      handleNewAppointment(false)
    }
  }, [lastAppointmentActive])

  if (!appointmentId || !currentAppointment) return null

  return (
    <Box flex={1} mr={6}>
      <>
        <Flex>
          <Box flex={1}>
            <ASkeleton isLoaded={!isLoading}>
              <Heading as="h1" mt={7} mb={8} fontSize={28} color="tertiary.500">
                {patient?.firstname} {patient?.lastname}
              </Heading>
            </ASkeleton>
            <Flex>
              <Infos
                isLoading={isLoading}
                patient={patient}
                setPatient={setPatient}
              />
            </Flex>
            <Heading mt={7} mb={8} fontSize={28} color="tertiary.500">
              Consultation du{" "}
              {format(
                getUtcDate(currentAppointment?.meetingDate),
                "dd/MM/yyyy"
              )}
            </Heading>
            <Flex direction={"row"} mb={4} wrap={"wrap"}>
              <EditableInput
                value={currentAppointment.patientTitle}
                onChange={(value, setSubmitting, onClose) =>
                  handleEditableInputChange(
                    "patientTitle",
                    value,
                    setSubmitting,
                    onClose
                  )
                }
                type="patientTitle"
              />
            </Flex>
            <Flex gap={4} direction={"row"} mb={4} wrap={"wrap"}>
              <EditableInput
                value={currentAppointment.internalNote}
                onChange={(value, setSubmitting, onClose) =>
                  handleEditableInputChange(
                    "internalNote",
                    value,
                    setSubmitting,
                    onClose
                  )
                }
                type="internalNote"
              />
              <EditableInput
                value={currentAppointment.secondaryInternalNote}
                onChange={(value, setSubmitting, onClose) =>
                  handleEditableInputChange(
                    "secondaryInternalNote",
                    value,
                    setSubmitting,
                    onClose
                  )
                }
                type="secondaryInternalNote"
              />
              <EditableInput
                value={currentAppointment.osteoAdvice}
                onChange={(value, setSubmitting, onClose) =>
                  handleEditableInputChange(
                    "osteoAdvice",
                    value,
                    setSubmitting,
                    onClose
                  )
                }
                type="osteoAdvice"
              />
            </Flex>
            {reviews && reviews.length > 0 && patient && (
              <PatientReviews reviews={reviews} patient={patient} />
            )}
            {currentAppointment.trainingPlaylist &&
              currentAppointment.trainingPlaylist.exercices.length !== 0 && (
                <Training
                  appointment={currentAppointment}
                  patientId={patientId}
                  setAppointment={setCurrentAppointment}
                />
              )}
            <AppointmentMedias
              selectedMedias={currentAppointment.explorerMedias ?? []}
              appointment={currentAppointment}
              patientId={patientId}
            />
            <Stats patient={patient} />
          </Box>
          <Box w={348} ml={6}>
            {isJobKineOrPodo ? (
              <AButton
                text="Nouveau motif de consultation"
                fontFamily="Montserrat"
                variant="kine"
                mb={6}
                leftIcon={<AddIcon fontSize={12} />}
                onClick={() => {
                  navigate(`/patients/${patientId}/appointments/new/treament/0`)
                }}
              />
            ) : (
              <AButton
                text="Créer une consultation"
                px={120}
                py={3}
                mt={3}
                mb={10}
                w="100%"
                fontFamily="Montserrat"
                onClick={onNewAppointmentTapped}
                leftIcon={<AddIcon fontSize={12} />}
              />
            )}

            <Box py={4} pl={4} pr={27} bg="primary.200" borderRadius={9}>
              <Heading mb={3} fontSize={18}>
                Consultations
              </Heading>
              {patient?.appointments
                ?.sort((a, b) =>
                  isBefore(new Date(a.createdAt), new Date(b.createdAt))
                    ? 1
                    : -1
                )
                .map(
                  ({
                    id,
                    meetingDate,
                    osteo,
                    academie,
                    center,
                    isActive,
                    patientTitle,
                  }) => (
                    <AppointmentCard
                      key={id}
                      link={`/patients/${patientId}/appointments/${id}`}
                      date={format(getUtcDate(meetingDate), "dd/MM/yyyy")}
                      name={`${osteo.firstname} ${osteo.lastname}`}
                      academie={academie}
                      center={center}
                      isSelected={id === parseInt(appointmentId)}
                      isActive={isActive}
                      patientId={parseInt(patientId ?? "")}
                      appointmentId={id}
                      patientTitle={patientTitle}
                      isLastAppointmentActive={!hasMoreThanOneAppointmentActive}
                    />
                  )
                )}
            </Box>
          </Box>
        </Flex>
      </>
      <ADisableAppointmentModal
        isOpen={isOpen}
        onClose={onClose}
        title="Nouvelle consultation"
        description="Ce patient à déjà une consultation en cours voulez-vous la désactiver ?"
        onConfirm={() => handleNewAppointment(true)}
        onRefuse={() => handleNewAppointment(false)}
      />
    </Box>
  )
}

export default Appointment
