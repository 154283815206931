import React, { useRef } from "react"
import { PatientReviewType } from "api/patient"
import { PatientType } from "api/patient"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react"
import PainReview from "components/PatientReviews/PainReview"
import Review from "components/PatientReviews/Review"

interface AllReviewsModalProps {
  reviews: PatientReviewType[]
  patient: PatientType
  isOpen: boolean
  onClose: () => void
}

const AllReviewsModal = ({
  reviews,
  patient,
  isOpen,
  onClose,
}: AllReviewsModalProps) => {
  const cancelRef = useRef<HTMLButtonElement>(null)
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent
        maxW={724}
        maxH="90vh"
        overflowY="auto"
        borderRadius={8}
        py={8}
      >
        <AlertDialogHeader fontSize={18}>Retours patients</AlertDialogHeader>
        <AlertDialogBody display={"flex"} flexDirection={"column"} gap={4}>
          {reviews.map((review) => {
            if (review.type === "pain") {
              return <PainReview review={review} patient={patient} />
            } else {
              return <Review review={review} patient={patient} />
            }
          })}
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default AllReviewsModal
