import { Text } from "@chakra-ui/react"

type Props = {
  step: number
  totalSteps: number
}

export default function OnboardingStep({ step, totalSteps }: Props) {
  return (
    <Text fontSize="lg" color="gray.800" mb={4} fontWeight="bold">
      Étape {step} / {totalSteps}
    </Text>
  )
}
