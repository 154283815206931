import { AddIcon } from "@chakra-ui/icons"
import { Flex, Text, useDisclosure } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { PatientType } from "api/patient"
import { updateTreatmentIsActive } from "api/treatment"
import AButton from "components/AButton"
import ADisableAppointmentModal from "components/ADisableAppointmentModal"
import AEditButton from "components/AEditButton"
import { format } from "date-fns"
import useJobIsKineOrPodo from "hooks/useIsJobKineOrPodo"
import useToast from "hooks/useToast"
import EditInfos from "pages/Patient/components/EditInfos"
import NewPassword from "pages/Patient/components/NewPassword"
import { useRef } from "react"
import { useNavigate } from "react-router-dom"

interface TreatmentHeaderProps {
  patient: PatientType
  isInPatientPage?: boolean
}

const TreatmentHeader = ({
  patient,
  isInPatientPage = false,
}: TreatmentHeaderProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenNewPassword,
    onOpen: onOpenNewPassword,
    onClose: onCloseNewPassword,
  } = useDisclosure()
  const navigate = useNavigate()
  const toast = useToast()
  const cancelRef = useRef(null)
  const appointments = patient.appointments
  const lastAppointment = appointments?.[appointments?.length - 1]
  const {
    isOpen: isOpenNewTreatment,
    onOpen: onOpenNewTreatment,
    onClose: onCloseNewTreatment,
  } = useDisclosure()
  const queryClient = useQueryClient()

  const lastAppointmentActive = patient.appointments?.find(
    (appointment) => appointment.isActive
  )

  const updateTreatmentIsActiveMutation = useMutation(updateTreatmentIsActive, {
    onError: () => {
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    },
    onSuccess: () => {
      toast({
        status: "success",
        title: `Traitement désactivé avec succès`,
      })

      queryClient.invalidateQueries(["getPatient", patient.id?.toString()])
      navigate(`/patients/${patient.id}/appointments/new/treament/0`)
    },
  })

  const handleNewTreatment = (desactivateTreatment: boolean) => {
    if (desactivateTreatment) {
      if (!lastAppointmentActive?.treatment?.id) return
      updateTreatmentIsActiveMutation.mutate({
        treatmentId: lastAppointmentActive?.treatment?.id,
        isActive: false,
      })
    } else {
      navigate(`/patients/${patient.id}/appointments/new/treament/0`)
    }
  }

  const handleClickNewTreatment = () => {
    if (lastAppointmentActive && lastAppointmentActive?.treatment?.id) {
      //Make sure the last appointment is active and has a treatment before asking to deactive it.
      onOpenNewTreatment()
    } else {
      navigate(`/patients/${patient.id}/appointments/new/treament/0`)
    }
  }

  const isJobKineOrPodo = useJobIsKineOrPodo()
  return (
    <Flex direction={"column"}>
      <Flex
        justify={"space-between"}
        mt={isInPatientPage ? 8 : 2}
        align={"center"}
      >
        <Flex
          direction={"column"}
          align={"start"}
          gap={2}
          w={"full"}
          maxW={"500px"}
        >
          <Flex justify={"space-between"} w={"full"}>
            <Text
              fontWeight={"bold"}
              fontSize={"28px"}
              w={"80%"}
              flexWrap={"wrap"}
              height={"fit-content"}
            >
              {patient.firstname} {patient.lastname}
            </Text>
            <AEditButton hasNoBg onClick={onOpen} />
          </Flex>
          <Flex gap={8} justify={"space-between"} w={"full"} pr={6}>
            <Text>{patient.account?.email}</Text>
            <Text>{patient.telephone}</Text>
            <Text>
              {patient?.birthday
                ? format(new Date(patient?.birthday), "dd/MM/yyyy")
                : ""}
            </Text>
          </Flex>
          <AButton
            text="Renvoyer les identifiants"
            onClick={onOpenNewPassword}
            variant="custom"
            bg={"transparent"}
            color="primary.500"
            textDecor="underline"
            padding={0}
            height="auto"
            fontSize={14}
            _hover={{
              bg: "transparent",
            }}
          />
        </Flex>
        {isJobKineOrPodo ? (
          <Flex flexDirection={"column"} gap={4}>
            <AButton
              text="Nouveau motif de consultation"
              fontFamily="Montserrat"
              variant="kine"
              leftIcon={<AddIcon fontSize={12} />}
              onClick={handleClickNewTreatment}
            />
            {lastAppointment && lastAppointment.treatment && (
              <AButton
                text="Nouvelle consultation"
                fontFamily="Montserrat"
                variant="secondary"
                mb={6}
                leftIcon={<AddIcon fontSize={12} />}
                onClick={() => {
                  navigate(
                    `/patients/${patient.id}/appointments/new/treament/${
                      lastAppointment.treatment!.id
                    }`
                  )
                }}
              />
            )}
          </Flex>
        ) : (
          <Flex flexDirection={"column"} gap={4}>
            <AButton
              text="Nouvelle consultation"
              fontFamily="Montserrat"
              variant="secondary"
              onClick={() => {
                navigate(`/patients/${patient.id}/appointments/new`)
              }}
              leftIcon={<AddIcon fontSize={12} />}
            />
          </Flex>
        )}
      </Flex>
      <NewPassword
        onClose={onCloseNewPassword}
        isOpen={isOpenNewPassword}
        cancelRef={cancelRef}
        patient={patient}
      />
      <EditInfos
        onClose={onClose}
        isOpen={isOpen}
        cancelRef={cancelRef}
        patient={patient}
      />
      <ADisableAppointmentModal
        isOpen={isOpenNewTreatment}
        onClose={onCloseNewTreatment}
        title="Nouveau traitement"
        description="Ce patient à déjà un traitement en cours voulez-vous le désactiver ?"
        onConfirm={() => handleNewTreatment(true)}
        onRefuse={() => handleNewTreatment(false)}
      />
    </Flex>
  )
}

export default TreatmentHeader
