import { PatientType } from "api/patient"
import axiosInstance from "./axiosInstance"
export interface Osteo {
  id: number
  firstname: string
  lastname: string
}

export interface TeleconsultationType {
  id: number
  patient: PatientType
  osteo: Osteo
  scheduledDate: string
  price: number
  isPaid: boolean
  isFinished: boolean
  privateNote: string
  linkelloKey: string
  linkelloSignature: string
  patientConsent: boolean
  uid: string
  isCancelled: boolean
}

export interface TeleconsultationInputType {
  scheduledDate: string
  startTime: string
  price: number
  isPaid: boolean
  isFinished: boolean
  privateNote: string
}

export type CreateTeleconsultationType = Omit<
  TeleconsultationType,
  "id" | "patient" | "osteo"
> & {
  patient: number
  osteo: number
  timeZone: string
}

export type EditTeleconsultationType = Omit<
  TeleconsultationType,
  "patient" | "osteo"
> & {
  patient: number
  osteo: number
  timeZone: string
}

export const getAllTeleconsultation = async () => {
  const { data } = await axiosInstance.get<TeleconsultationType[]>(
    `/teleconsultation`
  )
  return data
}

export const getTeleconsultationByPatientId = async (patientId: string) => {
  const { data } = await axiosInstance.get<TeleconsultationType[]>(
    `/teleconsultation/patient/find/${patientId}`
  )
  return data
}

export const getTeleconsultationById = async (id: string) => {
  const { data } = await axiosInstance.get<TeleconsultationType>(
    `/teleconsultation/${id}`
  )
  return data
}

export const getPatientTeleconsultationById = async (uid: string) => {
  const { data } = await axiosInstance.get<TeleconsultationType>(
    `/teleconsultation-public/${uid}`
  )
  return data
}

export const createTeleconsultation = async ({
  ...params
}: CreateTeleconsultationType) => {
  const response = await axiosInstance.post<CreateTeleconsultationType>(
    "/teleconsultation",
    {
      ...params,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
  )
  return response.data
}

export const editTeleconsultation = async ({
  ...params
}: EditTeleconsultationType) => {
  const response = await axiosInstance.put<EditTeleconsultationType>(
    `/teleconsultation/${params.id}`,
    {
      ...params,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
  )
  return response.data
}

export type EndTeleconsultationType = {
  id: number
  privateNote: string
  price: number
}

export const endTeleconsultation = async ({
  id,
  ...params
}: EndTeleconsultationType) => {
  const response = await axiosInstance.post(
    `/teleconsultation/${id}/end`,
    params
  )
  return response.data
}

export const deleteTeleconsultation = async (id: number) => {
  const response = await axiosInstance.delete(`/teleconsultation/${id}`)
  return response.data
}

export const createStripeAccount = async () => {
  const response = await axiosInstance.post(
    "/teleconsultation/createStripeAccount"
  )

  return response.data
}

export const getStripeConnectLoginLink = async (account: string) => {
  const response = await axiosInstance.get<{ url: string; error: string }>(
    `/teleconsultation/createStripeLoginLink/${account}`
  )
  return response.data
}

export const checkTeleconsultation = async (
  uid: string,
  data: { name: string; consent: boolean }
): Promise<{ success: boolean; displayname: string }> => {
  const response = await axiosInstance.post<{
    success: boolean
    displayname: string
  }>(`/teleconsultation/${uid}/checkName`, data)
  return response.data
}

export const remindTeleconsultationPayment = async (id: number) => {
  const response = await axiosInstance.put(
    `/teleconsultation/remindPayment/${id}`
  )
  return response.data
}

export const getTeleconsultationAccess = async (centerId?: number) => {
  if (centerId) {
    const response = await axiosInstance.get(
      `/teleconsultation/me/access?center_id=${centerId}`
    )
    return response.data
  }
  //Make sure the local storage still have the jwt token before calling the api. It appears it sometimes doesn't.
  const token = localStorage.getItem("jwt")
  if (!token) {
    return
  }
  const response = await axiosInstance.get(`/teleconsultation/me/access`)
  return response.data
}

export const saveStripeConnectAccount = async (account: string) => {
  const response = await axiosInstance.post(
    `/teleconsultation/saveStripeAccount`,
    { account }
  )

  return response.data
}

export const getTeleconsultationUpgradeProrata = async () => {
  const response = await axiosInstance.get(`/teleconsultation/upgrade/prorata`)
  return response.data
}

export const upgradeTeleconsultation = async () => {
  const response = await axiosInstance.post(`/teleconsultation/upgrade`)
  return response.data
}

export const getTeleconsultationUpgradeProrataCenter = async (
  centerId: number
) => {
  const response = await axiosInstance.get(
    `/teleconsultation/upgrade/center/prorata/${centerId}`
  )
  return response.data
}

export const upgradeTeleconsultationCenter = async (centerId: number) => {
  const response = await axiosInstance.post(
    `/teleconsultation/upgrade/center/${centerId}`
  )
  return response.data
}
