import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Text,
  Heading,
  Image,
  Box,
} from "@chakra-ui/react"
import ZonesPathologies from "pages/Exercices/components/ExercicesLists/components/ZonesPathologies/ZonesPathologies"
import {
  ZonePathologieType,
  getZones,
  getZonesAndPathos,
} from "pages/NewAppointment/newAppointment.mock"

import arrowBack from "../../../../assets/arrow_back.svg"
import arrowDropDown from "assets/arrow_drop_down.svg"
import { useEffect, useState } from "react"
import {
  EducationalContentType,
  EducationalGroupContentType,
  getAllEducationalContent,
} from "api/educationalContent"
import SelectedDiscovers from "pages/NewAppointment/components/SelectDiscovers/components/SelectedDiscovers/SelectedDiscovers"
import EducationalContentAccordionItem from "./components/EducationalContentAccordionItem"
import AButton from "components/AButton"
import { useQuery } from "@tanstack/react-query"
import useJobIsKineOrPodo from "hooks/useIsJobKineOrPodo"
import { getAppointmentReasons } from "api/appointmentReason"

interface EducationalContentProps {
  setStep?: (step: number) => void
  onNext?: () => void
  onBack?: () => void
  selectedEducationalContent: EducationalContentType[]
  setSelectedEducationalContent: React.Dispatch<
    React.SetStateAction<EducationalContentType[]>
  >
  isSubmitting: boolean
}
const EducationalContent = ({
  setStep,
  onNext,
  onBack,
  selectedEducationalContent,
  setSelectedEducationalContent,
  isSubmitting,
}: EducationalContentProps) => {
  const [selectedZone, setSelectedZone] = useState<
    ZonePathologieType | undefined
  >(undefined)
  const { data } = useQuery({
    queryKey: ["allEducationalContent"],
    queryFn: getAllEducationalContent,
  })
  const isJobKineOrPodo = useJobIsKineOrPodo()
  const [generalContent, setGeneralContent] = useState<
    EducationalContentType[]
  >([])
  const [allGeneralContent, setAllGeneralContent] = useState<
    EducationalContentType[]
  >([])

  const [filteredEducationalContent, setFilteredEducationalContent] = useState<
    EducationalGroupContentType[]
  >([])

  const [allFilteredEducationalContent, setAllFilteredEducationalContent] =
    useState<EducationalContentType[]>([])

  const formatContent = (content: EducationalContentType[]) =>
    content.reduce((acc: EducationalGroupContentType[], item) => {
      const currentGroups = item.groups
      const newGroups = currentGroups.map((group) => ({
        ...group,
        content: item,
      }))

      // Traiter chaque groupe individuellement
      const updatedAcc = [...acc]
      newGroups.forEach((newGroup) => {
        const existingGroupIndex = updatedAcc.findIndex(
          (accItem) => accItem.id === newGroup.id
        )

        if (existingGroupIndex !== -1) {
          // Le groupe existe déjà, ajouter le contenu à ce groupe
          updatedAcc[existingGroupIndex] = {
            ...updatedAcc[existingGroupIndex],
            content: [
              ...updatedAcc[existingGroupIndex].content,
              newGroup.content,
            ],
          }
        } else {
          // Le groupe n'existe pas, l'ajouter à l'acc
          updatedAcc.push({
            id: newGroup.id,
            name: newGroup.name,
            content: [newGroup.content],
          })
        }
      })

      return updatedAcc
    }, [])

  useEffect(() => {
    if (data) {
      const generalContent = data.filter(
        (item) => !item.groups || item.groups.length === 0
      )
      const filteredContent = data.filter((item) => item.groups.length > 0)
      setGeneralContent(generalContent)
      setAllGeneralContent(generalContent)
      setFilteredEducationalContent(formatContent(filteredContent))
      setAllFilteredEducationalContent(filteredContent)
    }
  }, [data])

  const handleGetEducationalContent = async (
    zone: ZonePathologieType | undefined
  ) => {
    if (!zone) {
      setSelectedZone(undefined)
      setFilteredEducationalContent(
        formatContent(allFilteredEducationalContent)
      )
      setGeneralContent(allGeneralContent)
      return
    }

    setSelectedZone(zone)

    const newFilteredContent = allFilteredEducationalContent.filter(
      (item) =>
        item.appointmentReasons.length === 0 ||
        item.appointmentReasons.find((reason) => reason.id === zone.id)
    )

    const newGeneralContent = allGeneralContent.filter(
      (item) =>
        item.appointmentReasons.length === 0 ||
        item.appointmentReasons.find((reason) => reason.id === zone.id)
    )
    setFilteredEducationalContent(formatContent(newFilteredContent))
    setGeneralContent(newGeneralContent)
  }

  const onCheckEducationalContent = (content: EducationalContentType) => {
    setSelectedEducationalContent((prev) => {
      const isAlreadyInList = prev.some((item) => item.id === content.id)
      if (isAlreadyInList) {
        return prev.filter((item) => item.id !== content.id)
      } else {
        return [...prev, content]
      }
    })
  }

  const { data: allAppointmentReasons } = useQuery({
    queryKey: ["appointmentReasons"],
    queryFn: getAppointmentReasons,
  })
  return (
    <Box p="16px" width="full" pb={"120px"}>
      <Flex>
        <Flex direction="column" pos="sticky" top={4} h="fit-content">
          <Flex gap={4} align={"center"} mb={6}>
            <Flex
              cursor="pointer"
              onClick={setStep ? () => setStep(1) : onBack}
              p="8px"
              bgColor="primary.200"
              w="fit-content"
              display="block"
              rounded="9px"
              pos="sticky"
              top={4}
            >
              <Image src={arrowBack} width="24px" height="24px" />
            </Flex>
          </Flex>
          <Flex alignItems={"center"}>
            <Flex justifyContent="center" w="full">
              <ZonesPathologies
                datas={getZonesAndPathos(allAppointmentReasons)}
                selectedData={selectedZone}
                setSelectedData={handleGetEducationalContent}
              />
            </Flex>
          </Flex>
        </Flex>
        <Box flex={1} px="60px" position="relative">
          <Heading fontSize={26} color="tertiary.500" mb={6}>
            Contenu éducation santé
          </Heading>
          <Text fontSize="18px" mb={6}>
            Sélectionnez du contenu pour informer votre patient
          </Text>
          {filteredEducationalContent.length !== 0 && (
            <Flex direction={"column"} mb={"52px"}>
              <Accordion allowMultiple>
                {filteredEducationalContent
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((group, index) => (
                    <AccordionItem
                      key={index}
                      border={"none"}
                      mt={index !== 0 ? "30px" : "0"}
                    >
                      {({ isExpanded }) => (
                        <>
                          <AccordionButton gap={"10px"}>
                            <Image
                              src={arrowDropDown}
                              width={"10px"}
                              height={"10px"}
                              transform={
                                isExpanded ? "rotate(0deg)" : "rotate(180deg)"
                              }
                              transition="transform 0.2s ease"
                            />
                            <Text textAlign={"left"} fontWeight={"bold"}>
                              {group.name}
                            </Text>
                          </AccordionButton>
                          <AccordionPanel padding={0}>
                            {group.content.map((ct, index) => (
                              <EducationalContentAccordionItem
                                key={index}
                                content={ct}
                                isEvenIndex={index % 2 === 0}
                                onCheck={onCheckEducationalContent}
                                isChecked={selectedEducationalContent.some(
                                  (item) => item.id === ct.id
                                )}
                              />
                            ))}
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                  ))}
              </Accordion>
            </Flex>
          )}
          {generalContent.map((content, index) => (
            <Box key={index} height={"46px"}>
              <EducationalContentAccordionItem
                content={content}
                isEvenIndex={index % 2 === 0}
                onCheck={onCheckEducationalContent}
                isChecked={selectedEducationalContent.some(
                  (item) => item.id === content.id
                )}
              />
            </Box>
          ))}
        </Box>
        <Flex w={"420px"}>
          {selectedEducationalContent && (
            <SelectedDiscovers
              selectedDiscovers={selectedEducationalContent}
              setSelectedDiscovers={setSelectedEducationalContent}
            />
          )}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="full"
        gap={2}
        fontFamily="Montserrat"
        bg="primary.200"
        py={6}
        position="fixed"
        bottom={0}
        left={0}
        zIndex={100}
      >
        <AButton
          text={onNext ? "Modifier le contenu Éducatif" : "Suivant"}
          px={120}
          py={3}
          onClick={setStep ? () => setStep(3) : onNext}
          isDisabled={isSubmitting}
        />
      </Flex>
    </Box>
  )
}

export default EducationalContent
