import React, { useEffect } from "react"
import { PatientReviewType, PatientType } from "api/patient"
import { Box, Button, Flex, Heading, useDisclosure } from "@chakra-ui/react"
import PainReview from "./PatientReviews/PainReview"
import Review from "./PatientReviews/Review"
import AllReviewsModal from "components/PatientReviews/AllReviewsModal"
import { useSearchParams } from "react-router-dom"

interface PatientReviewsProps {
  reviews: PatientReviewType[]
  patient: PatientType
}

const PatientReviews = ({ reviews, patient }: PatientReviewsProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchParams] = useSearchParams()
  const isReviewModalOpen = searchParams.get("isReviewModalOpen")

  useEffect(() => {
    if (isReviewModalOpen) {
      onOpen()
    }
  }, [isReviewModalOpen, onOpen])

  return (
    <Box mb={4} p={4} bg="white" borderRadius={8}>
      <Heading as="h3" fontSize={18} mb={4}>
        Retours patient
      </Heading>
      <Flex justifyContent="space-between">
        {reviews.slice(0, 2).map((review) => {
          if (review.type === "pain") {
            return (
              <PainReview
                review={review}
                patient={patient}
                width="calc(50% - 8px)"
              />
            )
          } else {
            return (
              <Review
                review={review}
                patient={patient}
                width="calc(50% - 8px)"
              />
            )
          }
        })}
      </Flex>
      {reviews.length > 2 && (
        <Flex justifyContent={"center"}>
          <Button
            textDecor={"underline"}
            fontSize={14}
            fontWeight={"bold"}
            textAlign={"center"}
            mt={2}
            onClick={onOpen}
            bg={"transparent"}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
          >
            Voir tous les retours
          </Button>
        </Flex>
      )}
      <AllReviewsModal
        reviews={reviews}
        patient={patient}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  )
}

export default PatientReviews
