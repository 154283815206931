import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  getTeleconsultationUpgradeProrata,
  getTeleconsultationUpgradeProrataCenter,
  upgradeTeleconsultation,
  upgradeTeleconsultationCenter,
} from "api/teleconsultation"
import { useAppContext } from "AppContext"
import AButton from "components/AButton"
import { formatDistance, isSameDay } from "date-fns"
import { fr } from "date-fns/locale"
import useToast from "hooks/useToast"
import { useMemo } from "react"
import userAdminsCenterGroupInCurrentScope from "utils/currentScope/userAdminsCenterGroupInCurrentScope"

interface UpgradeTeleconsultationModalProps {
  isOpen: boolean
  onClose: () => void
}
const UpgradeTeleconsultationModal = ({
  isOpen,
  onClose,
}: UpgradeTeleconsultationModalProps) => {
  const toast = useToast()
  const { currentScope, user } = useAppContext()
  const queryClient = useQueryClient()
  const isCenterAdmin = userAdminsCenterGroupInCurrentScope(user, currentScope)

  const { data: prorata } = useQuery({
    queryKey: ["teleconsultation-upgrade-prorata"],
    queryFn: () =>
      currentScope?.type === "center"
        ? getTeleconsultationUpgradeProrataCenter(currentScope.id)
        : getTeleconsultationUpgradeProrata(),
    enabled:
      !currentScope || (currentScope?.type === "center" && isCenterAdmin),
  })

  const isTrialing = useMemo(() => {
    return prorata?.subscription?.status === "trialing"
  }, [prorata])

  const { mutate, isLoading } = useMutation(
    () =>
      currentScope?.type === "center"
        ? upgradeTeleconsultationCenter(currentScope.id)
        : upgradeTeleconsultation(),
    {
      onError() {
        toast({
          title: "Erreur lors de l'activation de la téléconsultation",
          status: "error",
        })
      },
      onSuccess() {
        toast({
          title: "Téléconsultation activée",
          status: "success",
        })
        queryClient.invalidateQueries({
          queryKey: ["getTeleconsultationAccess", currentScope?.id],
        })
        onClose()
      },
    }
  )

  const amountToPay = (
    prorata?.upcomingInvoice.amount_remaining / 100
  ).toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const quantity = prorata?.quantity ?? 1
  const newRecurringPrice = (
    (prorata?.newPrice.unit_amount / 100) *
    quantity
  ).toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const interval =
    prorata?.newPrice.recurring.interval === "month" ? "mois" : "an"

  const getPeriodEnd = () => {
    if (!prorata?.upcomingInvoice?.period_end) return null

    if (isSameDay(prorata?.upcomingInvoice.period_end * 1000, Date.now()))
      return "aujourd'hui"

    return formatDistance(
      prorata?.upcomingInvoice.period_end * 1000,
      new Date(),
      {
        addSuffix: true,
        locale: fr,
      }
    )
  }

  const description = useMemo(() => {
    if (isTrialing) {
      return `Essayez dès maintenant l'abonnement incluant la téléconsultation sans surcout, puis ${newRecurringPrice}€ / ${interval} (avant remboursement FAMI) lors du prochain renouvellement.`
    }
    return `Passez à l’abonnement incluant la téléconsultation pour ${amountToPay}€ ${getPeriodEnd()}, puis ${newRecurringPrice}€ / ${interval} (avant remboursement FAMI) lors du prochain renouvellement`
  }, [isTrialing, amountToPay, getPeriodEnd, newRecurringPrice, interval])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Activer la téléconsultation</ModalHeader>
        <ModalBody>
          <Text>{description}</Text>
        </ModalBody>
        <ModalFooter>
          <Flex gap="16px" justifyContent="flex-end">
            <AButton text="Annuler" variant="tertiary" onClick={onClose} />
            <AButton
              text="Activer"
              isLoading={isLoading}
              onClick={() => mutate()}
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpgradeTeleconsultationModal
