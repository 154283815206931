import { Box, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react"
import React, { useRef } from "react"
import convertBreakLineToBr from "utils/convertLineBreakToBr"
import AEditButton from "../../../components/AEditButton"
import ASkeleton from "../../../components/ASkeleton"
import EditableInputModal from "./EditableInputModal"

interface IProps {
  isLoading?: boolean
  value?: string
  onChange?: (
    value: string,
    setSubmitting: (isSubmitting: boolean) => void,
    onClose: () => void
  ) => void
  type:
    | "osteoAdvice"
    | "internalNote"
    | "secondaryInternalNote"
    | "patientTitle"
    | "teleconsultationPrivateNote"
}

const title = {
  osteoAdvice: "Recommandation patient",
  internalNote: "Vos notes privées",
  secondaryInternalNote: "",
  patientTitle: "Titre du programme (visible par le patient)",
  teleconsultationPrivateNote: "Note de téléconsultation",
}

const emptyText = {
  osteoAdvice: "Aucune recommandation pour le moment",
  internalNote: "Aucune note complémentaire pour le moment",
  secondaryInternalNote: "Aucune note privée pour le moment",
  patientTitle: "Aucun titre pour le moment",
  teleconsultationPrivateNote: "Aucune note de téléconsultation pour le moment",
}

const EditableInput: React.FC<IProps> = ({
  isLoading,
  value,
  onChange,
  type,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  if (!value) return null

  return (
    <>
      <Flex
        pos="relative"
        backgroundColor={"white"}
        borderRadius={8}
        direction={"column"}
        padding={4}
        gap={4}
        flex={1}
      >
        <Heading as="h3" fontSize={14}>
          {title[type]}
        </Heading>
        <ASkeleton isLoaded={!isLoading}>
          <Box
            bg={
              type === "osteoAdvice" || type === "patientTitle"
                ? "common.100"
                : "osteow.secondary.200"
            }
            p={2}
            borderRadius={9}
            pos="relative"
            borderColor={
              type === "osteoAdvice" || type === "patientTitle"
                ? "common.300"
                : "osteow.secondary.400"
            }
            borderWidth={1}
          >
            {value ? (
              <Box
                fontSize={14}
                lineHeight="18px"
                dangerouslySetInnerHTML={{
                  __html: convertBreakLineToBr(value),
                }}
              />
            ) : (
              <Text fontSize={14} lineHeight="18px" as={"i"}>
                {emptyText[type]}
              </Text>
            )}
          </Box>
          {type !== "teleconsultationPrivateNote" && (
            <AEditButton
              pos="absolute"
              top={2}
              right={2}
              onClick={onOpen}
              ariaLabel="Modifier la recommandation"
            />
          )}
        </ASkeleton>
      </Flex>
      {type !== "teleconsultationPrivateNote" && (
        <EditableInputModal
          onClose={onClose}
          isOpen={isOpen}
          cancelRef={cancelRef}
          value={value}
          onChange={onChange}
          type={type}
        />
      )}
    </>
  )
}

export default EditableInput
