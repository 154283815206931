import { AddIcon } from "@chakra-ui/icons"
import { Flex, Text, useDisclosure } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { TreatmentType, updateTreatmentIsActive } from "api/treatment"
import ANewButton from "components/ANewButton"
import ASwitch from "components/ASwitch/ASwitch"
import AllAppointmentModal from "components/Treatment/AllAppointmentModal"
import { format } from "date-fns"
import useJobIsKineOrPodo from "hooks/useIsJobKineOrPodo"
import useToast from "hooks/useToast"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import getUtcDate from "utils/getUtcDate"

interface TreatmentCardProps {
  treatment: TreatmentType
  isSelected: boolean
  isLastTreatmentActive: boolean
}

const TreatmentCard = ({
  treatment,
  isSelected,
  isLastTreatmentActive,
}: TreatmentCardProps) => {
  const updateTreatmentIsActiveMutation = useMutation(updateTreatmentIsActive)
  const toast = useToast()
  const sortedAppointments = treatment.appointments.sort((a, b) => b.id - a.id)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    isOpen: isAllAppointmentsModalOpen,
    onOpen: onOpenAllAppointmentsModal,
    onClose: onCloseAllAppointmentsModal,
  } = useDisclosure()

  const displayedAppointments = sortedAppointments.slice(0, 3)
  const hasMoreAppointments = sortedAppointments.length > 3

  const handleSelectTreatment = () => {
    navigate(
      `/patients/${treatment.patient.id}/treatment/${treatment.id}/appointment/${treatment.appointments[0].id}`
    )
  }

  const isJobKineOrPodo = useJobIsKineOrPodo()

  const [isActive, setIsActive] = useState(
    treatment.appointments.find((appointment) => appointment.isActive) !==
      undefined
  )

  const handleOnIsActiveChange = (isActive: boolean) => {
    if (isLastTreatmentActive && !isActive) {
      toast({
        status: "error",
        title: "Le patient doit avoir au moins un traitement actif",
      })
      return
    }
    setIsActive((prev) => !prev)

    updateTreatmentIsActiveMutation.mutate(
      {
        treatmentId: treatment.id,
        isActive,
      },
      {
        onError: () => {
          setIsActive((prev) => !prev)
          toast({
            status: "error",
            title: "Une erreur s'est produite",
          })
        },
        onSuccess: () => {
          toast({
            status: "success",
            title: `Traitement ${
              isActive ? "activé" : "désactivé"
            } avec succès`,
          })

          queryClient.invalidateQueries([
            "getPatient",
            treatment.patient.id?.toString(),
          ])
        },
      }
    )
  }

  return (
    <Flex
      borderRadius={9}
      p={4}
      border={isSelected ? "2px solid" : "none"}
      borderColor={"valid.300"}
      bg={isSelected ? "secondary.100" : "white"}
      direction={"column"}
      cursor={isSelected ? "default" : "pointer"}
      onClick={handleSelectTreatment}
    >
      <Flex justify={"space-between"}>
        <Text fontSize={16} fontWeight={"bold"}>
          {treatment.title}
        </Text>
        {treatment.id !== -1 && (
          <ASwitch isChecked={isActive} onChange={handleOnIsActiveChange} />
        )}
      </Flex>
      <Flex direction={"column"} mt={4} gap={"8px"}>
        {displayedAppointments.map((appointment) => (
          <Text fontWeight={"bold"} key={appointment.id}>
            {format(getUtcDate(appointment.meetingDate || ""), "dd/MM/yyyy")}
          </Text>
        ))}
        {hasMoreAppointments && isSelected && (
          <Text
            onClick={onOpenAllAppointmentsModal}
            textDecoration={"underline"}
            cursor={"pointer"}
          >
            Voir plus
          </Text>
        )}
        {isSelected && treatment.id !== -1 && isJobKineOrPodo && (
          <ANewButton
            text="Nouvelle consultation"
            variant="teleconsultation"
            icon={<AddIcon fontSize={"12px"} />}
            onClick={(e) => {
              e.stopPropagation()
              navigate(
                `/patients/${treatment.patient.id}/appointments/new/treament/${treatment.id}`
              )
            }}
          />
        )}
      </Flex>
      {isAllAppointmentsModalOpen && (
        <AllAppointmentModal
          isOpen={isAllAppointmentsModalOpen}
          onClose={onCloseAllAppointmentsModal}
          appointments={treatment.appointments}
          treatmentTitle={treatment.title}
        />
      )}
    </Flex>
  )
}

export default TreatmentCard
