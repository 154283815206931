import { SearchIcon } from "@chakra-ui/icons"
import {
  Box,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { AppointmentReasonType } from "api/appointmentReason"
import cancelIcon from "assets/cancel.svg"
import resizeIcon from "assets/resize.svg"
import { useRef, useState } from "react"

interface ClinicalPicturesProps {
  appointmentReasonTitle: string
  appointmentReason: AppointmentReasonType
  selectedClinicalPicture: string | null
  setSelectedClinicalPicture: React.Dispatch<
    React.SetStateAction<string | null>
  >
}

const ClinicalPictures = ({
  appointmentReasonTitle,
  appointmentReason,
  selectedClinicalPicture,
  setSelectedClinicalPicture,
}: ClinicalPicturesProps) => {
  const [clinicalPictureSearch, setClinicalPictureSearch] = useState("")
  const [isSearching, setIsSearching] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const inputRef = useRef<HTMLInputElement>(null)

  const handleResetClinicalFilter = () => {
    setClinicalPictureSearch("")
    setSelectedClinicalPicture(null)
  }

  const highlightText = (text: string, search: string) => {
    if (!search) return text

    const regex = new RegExp(`(${search})`, "gi")
    const parts = text.split(regex)

    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === search.toLowerCase() ? (
            <Text as="span" color="primary.700" fontWeight="bold" key={index}>
              {part}
            </Text>
          ) : (
            <Text as="span" key={index}>
              {part}
            </Text>
          )
        )}
      </>
    )
  }

  const filteredClinicalPictures = appointmentReason.clinicalPictures
    .filter((clinicalPicture) =>
      clinicalPicture.name
        .toLowerCase()
        .includes(clinicalPictureSearch.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name))

  if (filteredClinicalPictures.length === 0) return null
  return (
    <>
      {!isOpen && (
        <Flex
          direction="column"
          bg={"white"}
          p={4}
          borderRadius={8}
          border="1px solid"
          borderColor="common.300"
          maxW={"300px"}
          mt={"30px"}
        >
          <>
            <Flex justify={"space-between"} align={"center"}>
              <Text fontWeight={700} fontSize={"14px"}>
                {appointmentReasonTitle}
              </Text>
              <Image src={resizeIcon} onClick={onOpen} cursor={"pointer"} />
            </Flex>

            <Flex direction="column" mt={2}>
              <Flex
                align="center"
                cursor="pointer"
                onClick={handleResetClinicalFilter}
                mb={2}
              >
                <Box
                  w="16px"
                  h="16px"
                  borderRadius="50%"
                  border="2px solid"
                  borderColor={
                    selectedClinicalPicture === null
                      ? "primary.500"
                      : "gray.300"
                  }
                  mr={2}
                  position="relative"
                >
                  {selectedClinicalPicture === null && (
                    <Box
                      position="absolute"
                      top="2px"
                      left="2px"
                      w="8px"
                      h="8px"
                      borderRadius="50%"
                      bg="primary.500"
                    />
                  )}
                </Box>
                <Text>Sans filtre</Text>
              </Flex>
              <Flex
                justify={"space-between"}
                align={"center"}
                mt={4}
                py={isSearching ? 0 : 2}
              >
                <Text fontWeight={700}>Tableau clinique</Text>
                {isSearching ? (
                  <Flex maxW={"125px"} align={"center"}>
                    <Input
                      ref={inputRef}
                      w={"80%"}
                      placeholder="Rechercher"
                      border="none"
                      paddingX={2}
                      fontSize="14px"
                      fontWeight={400}
                      bg="white"
                      color={"common.400"}
                      _focusVisible={{ border: "none" }}
                      onChange={(evt) =>
                        setClinicalPictureSearch(evt.target.value)
                      }
                      value={clinicalPictureSearch}
                    />
                    <Image
                      src={cancelIcon}
                      onClick={() => {
                        setIsSearching(false)
                        setClinicalPictureSearch("")
                      }}
                      cursor={"pointer"}
                    />
                  </Flex>
                ) : (
                  <SearchIcon
                    onClick={() => {
                      setIsSearching(true)
                      setTimeout(() => inputRef.current?.focus(), 0)
                    }}
                    cursor={"pointer"}
                  />
                )}
              </Flex>

              <Flex direction="column" mt={2} maxH={"230px"} overflowY={"auto"}>
                {filteredClinicalPictures.map((clinicalPicture) => (
                  <Flex
                    key={clinicalPicture.id}
                    align="center"
                    cursor="pointer"
                    onClick={() => {
                      setSelectedClinicalPicture(clinicalPicture.id.toString())
                      onClose()
                    }}
                    mb={2}
                  >
                    <Box
                      minW="16px"
                      minH="16px"
                      borderRadius="50%"
                      border="2px solid"
                      borderColor={
                        selectedClinicalPicture ===
                        clinicalPicture.id.toString()
                          ? "primary.500"
                          : "gray.300"
                      }
                      mr={2}
                      position="relative"
                    >
                      {selectedClinicalPicture ===
                        clinicalPicture.id.toString() && (
                        <Box
                          position="absolute"
                          top="2px"
                          left="2px"
                          w="8px"
                          h="8px"
                          borderRadius="50%"
                          bg="primary.500"
                        />
                      )}
                    </Box>
                    {/* <Text>{clinicalPicture.Name}</Text> */}
                    <Text>
                      {highlightText(
                        clinicalPicture.name,
                        clinicalPictureSearch
                      )}
                    </Text>
                  </Flex>
                ))}
                {filteredClinicalPictures.length === 0 && (
                  <Text>
                    Aucun tableau clinique ne correspond à la recherche
                  </Text>
                )}
              </Flex>
            </Flex>
          </>
        </Flex>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{appointmentReasonTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <Flex justify={"space-between"} align={"center"}>
                <Text fontWeight={700} fontSize={"14px"}>
                  {appointmentReasonTitle}
                </Text>
              </Flex>

              <Flex direction="column" mt={2}>
                <Flex
                  align="center"
                  cursor="pointer"
                  onClick={handleResetClinicalFilter}
                  mb={2}
                >
                  <Box
                    w="16px"
                    h="16px"
                    borderRadius="50%"
                    border="2px solid"
                    borderColor={
                      selectedClinicalPicture === null
                        ? "primary.500"
                        : "gray.300"
                    }
                    mr={2}
                    position="relative"
                  >
                    {selectedClinicalPicture === null && (
                      <Box
                        position="absolute"
                        top="2px"
                        left="2px"
                        w="8px"
                        h="8px"
                        borderRadius="50%"
                        bg="primary.500"
                      />
                    )}
                  </Box>
                  <Text>Sans filtre</Text>
                </Flex>
                <Flex
                  justify={"space-between"}
                  align={"center"}
                  mt={4}
                  py={isSearching ? 0 : 2}
                >
                  <Text fontWeight={700}>Tableau clinique</Text>
                  {isSearching ? (
                    <Flex maxW={"125px"} align={"center"}>
                      <Input
                        ref={inputRef}
                        w={"80%"}
                        placeholder="Rechercher"
                        border="none"
                        paddingX={2}
                        fontSize="14px"
                        fontWeight={400}
                        bg="white"
                        color={"common.400"}
                        _focusVisible={{ border: "none" }}
                        onChange={(evt) =>
                          setClinicalPictureSearch(evt.target.value)
                        }
                        value={clinicalPictureSearch}
                      />
                      <Image
                        src={cancelIcon}
                        onClick={() => {
                          setIsSearching(false)
                          setClinicalPictureSearch("")
                        }}
                        cursor={"pointer"}
                      />
                    </Flex>
                  ) : (
                    <SearchIcon
                      onClick={() => {
                        setIsSearching(true)
                        setTimeout(() => inputRef.current?.focus(), 0)
                      }}
                      cursor={"pointer"}
                    />
                  )}
                </Flex>

                <Flex direction="column" mt={2}>
                  {filteredClinicalPictures.map((clinicalPicture) => (
                    <Flex
                      key={clinicalPicture.id}
                      align="center"
                      cursor="pointer"
                      onClick={() => {
                        setSelectedClinicalPicture(
                          clinicalPicture.id.toString()
                        )
                        onClose()
                      }}
                      mb={2}
                    >
                      <Box
                        minW="16px"
                        minH="16px"
                        borderRadius="50%"
                        border="2px solid"
                        borderColor={
                          selectedClinicalPicture ===
                          clinicalPicture.id.toString()
                            ? "primary.500"
                            : "gray.300"
                        }
                        mr={2}
                        position="relative"
                      >
                        {selectedClinicalPicture ===
                          clinicalPicture.id.toString() && (
                          <Box
                            position="absolute"
                            top="2px"
                            left="2px"
                            w="8px"
                            h="8px"
                            borderRadius="50%"
                            bg="primary.500"
                          />
                        )}
                      </Box>
                      <Text>
                        {highlightText(
                          clinicalPicture.name,
                          clinicalPictureSearch
                        )}
                      </Text>
                    </Flex>
                  ))}
                  {filteredClinicalPictures.length === 0 && (
                    <Text>
                      Aucun tableau clinique ne correspond à la recherche
                    </Text>
                  )}
                </Flex>
              </Flex>
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ClinicalPictures
