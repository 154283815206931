import { InfoOutlineIcon } from "@chakra-ui/icons"
import { Box, Flex, Icon, Text } from "@chakra-ui/react"
import { useMemo } from "react"

type EmailInfoAlertProps = {
  therapistEmail?: string
}

export const OnboardingAddPatientInfo = ({
  therapistEmail,
}: EmailInfoAlertProps) => {
  const exampleEmail = useMemo(() => {
    if (!therapistEmail) return ""
    const emailParts = therapistEmail.split("@")
    return `${emailParts[0]}+patient@${emailParts[1]}`
  }, [therapistEmail])
  return (
    <Box bg="primary.200" p={6} borderRadius="md">
      <Flex gap={3}>
        <Icon as={InfoOutlineIcon} boxSize={6} color="black" mt={1} />
        <Box>
          <Text fontSize="md" color="black" mb={2}>
            Votre compte patient doit utiliser{" "}
            <strong>une autre adresse mail</strong> que votre accès thérapeute (
            {therapistEmail}).
          </Text>

          <Text fontSize="md" color="black">
            <Text
              as="span"
              textDecoration="underline"
              fontWeight="medium"
              textUnderlineOffset={4}
            >
              Astuce
            </Text>
            : vous pouvez utiliser l'adresse <b>{exampleEmail}</b> tout en
            recevant vos emails sur votre boite de réception.
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}
