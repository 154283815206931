import { Flex } from "@chakra-ui/react"
import NewPatient from "pages/NewPatient/NewPatient"

type Props = {
  onPatientCreated: (patiendId: number) => void
}

export default function OnboardingStep2({ onPatientCreated }: Props) {
  return (
    <Flex backgroundColor="primary.100">
      <NewPatient isOnboarding onPatientReady={onPatientCreated} />
    </Flex>
  )
}
