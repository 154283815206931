import { AddIcon, TimeIcon, ViewIcon } from "@chakra-ui/icons"
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { useQueryClient, useMutation } from "@tanstack/react-query"
import { deleteProgram, ProgramType } from "api/program"
import AButton from "components/AButton"
import useJobIsOsteo from "hooks/useJobIsOsteo"
import useToast from "hooks/useToast"
import DeleteProgramModal from "pages/NewAppointment/components/SelectExercices/components/AddProgramModal/DeleteProgramModal"
import ProgramOptionsButton from "pages/NewAppointment/components/SelectExercices/components/AddProgramModal/ProgramOptionsButton"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getTotalDuration } from "utils/getTotalDuration"

interface ProgramCardProps {
  program: ProgramType
  selectedProgram: ProgramType | null
  setSelectedProgram: React.Dispatch<React.SetStateAction<ProgramType | null>>
  isLooking?: boolean
  canEdit?: boolean
}

const ProgramCard: FC<ProgramCardProps> = ({
  program,
  selectedProgram,
  setSelectedProgram,
  isLooking = false,
  canEdit = false,
}) => {
  const isJobOsteo = useJobIsOsteo()
  const [isHovering, setIsHovering] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const toast = useToast()

  const {
    isOpen: deleteModalIsOpen,
    onOpen: deleteModalOnOpen,
    onClose: deleteModalOnClose,
  } = useDisclosure()

  const deleteProgramMutation = useMutation({
    mutationFn: deleteProgram,
    onSuccess: async () => {
      await queryClient.refetchQueries(["getMyPrograms"])
      deleteModalOnClose()
      toast({
        status: "success",
        title: "Exercice supprimé avec succès",
      })
      setSelectedProgram(null)
    },
    onError: () => {
      toast({
        status: "error",
        title: "Une erreur est survenue lors de la suppression",
      })
    },
  })

  const handleDelete = () => {
    deleteProgramMutation.mutate(program.id)
  }

  const handleEditProgram = () => {
    navigate(`/programs/edit/${program.id}`)
  }

  return (
    <>
      <Flex direction="column" w="calc(33% - 16px)" gap="8px" mb={6}>
        <Box
          position="relative"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {isHovering && (
            <Flex
              bg="rgba(17, 23, 45, 0.5)"
              pos="absolute"
              top={0}
              left={0}
              w="100%"
              h="100%"
              alignItems="center"
              justifyContent="center"
            >
              <AButton
                text={isLooking ? "Détail" : "Ajouter"}
                variant="tertiary"
                fontSize={12}
                leftIcon={isLooking ? <ViewIcon /> : <AddIcon />}
                onClick={() => setSelectedProgram(program)}
              />
            </Flex>
          )}
          {isHovering && isLooking && canEdit && (
            <Box
              position="absolute"
              top="8px"
              right="8px"
              bg="white"
              borderRadius={8}
            >
              <ProgramOptionsButton
                handleEdit={handleEditProgram}
                handleDelete={deleteModalOnOpen}
              />
            </Box>
          )}
          {program.trainingVideoPrograms.length > 0 && (
            <Image
              src={
                program.trainingVideoPrograms[0].trainingVideo?.bunnyCDN
                  ?.preview ??
                program.trainingVideoPrograms[0].trainingVideoUploaded?.bunnyCDN
                  .preview
              }
              rounded="8px"
              w="100%"
              h="155px"
              objectFit="cover"
            />
          )}
          {isJobOsteo && (
            <Flex
              position="absolute"
              alignItems="center"
              bg="common.100"
              px="8px"
              py="2px"
              gap={1}
              rounded="24px"
              fontSize="12px"
              right="10px"
              top="10px"
            >
              <TimeIcon />

              <Text fontWeight={600}>
                {getTotalDuration(program.trainingVideoPrograms)}
              </Text>
            </Flex>
          )}
          <Flex gap="8px" position="absolute" bottom="8px" left="8px" w="full">
            {program.trainingVideoPrograms.map((trainingVideo, index) => {
              if (index > 0 && index <= 3) {
                return (
                  <Image
                    w="20%"
                    key={trainingVideo.id}
                    src={
                      trainingVideo.trainingVideo?.bunnyCDN?.preview ??
                      trainingVideo.trainingVideoUploaded?.bunnyCDN.preview
                    }
                    rounded="4px"
                    border="1px solid"
                    borderColor="white"
                  />
                )
              }
              return null
            })}
            {program.trainingVideoPrograms.length > 4 && (
              <Flex
                w="12%"
                bg="white"
                justifyContent="center"
                alignItems="center"
                rounded="4px"
              >
                <Text fontWeight={700}>
                  +{program.trainingVideoPrograms.length - 4}
                </Text>
              </Flex>
            )}
          </Flex>
        </Box>
        <Heading as="h6" fontSize="18px" lineHeight="100%" flex={1}></Heading>
        <Text flex={1} fontWeight={600} lineHeight="100%">
          {program.title}
        </Text>
        <Text lineHeight="100%">niveau {program.level}</Text>
      </Flex>
      <DeleteProgramModal
        deleteModalIsOpen={deleteModalIsOpen}
        deleteModalOnClose={deleteModalOnClose}
        handleDelete={handleDelete}
      />
    </>
  )
}

export default ProgramCard
