import { Flex, MenuItem, Text, Icon, Tooltip } from "@chakra-ui/react"
import React from "react"
import { useAppContext } from "../../../AppContext"
import { MdSchool } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import troncateString from "utils/troncateString"

interface IProps {
  academy: AcademieType
}

const AAcademyItem: React.FC<IProps> = ({ academy }) => {
  const { setCurrentScope } = useAppContext()
  const navigate = useNavigate()

  return (
    <MenuItem
      minH="48px"
      background="primary.300"
      _hover={{ background: "primary.400" }}
      onClick={() => {
        setCurrentScope({ id: academy.id, type: "academy" })
        navigate("/dashboard")
      }}
    >
      <Tooltip label={academy.name} placement="right">
        <Flex alignItems="center">
          <Flex
            w={6}
            h={6}
            justifyContent="center"
            alignItems="center"
            bg="tertiary.500"
            borderRadius="50%"
          >
            <Icon fontSize={14} as={MdSchool} color="white" />
          </Flex>
          <Text ml={2} fontSize={16}>
            {troncateString(academy.name, 15)}
          </Text>
        </Flex>
      </Tooltip>
    </MenuItem>
  )
}

export default AAcademyItem
