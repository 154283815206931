import { Flex } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import Stats from "pages/Patient/components/Stats"

import { useMutation, useQuery } from "@tanstack/react-query"
import { getPatient, PatientType } from "../../api/patient"

import { useAppContext } from "AppContext"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import userHasNoPersonalAccount from "../../utils/user/userHasNoPersonalAccount"
import userHasPatientOnDifferentAccount from "utils/multi/userHasPatientOnDifferentAccount"
import RightTabKine from "components/Treatment/RightTabKine"
import TreatmentHeader from "components/Treatment/TreatmentHeader"
import { TreatmentType } from "api/treatment"
import { getTreatmentByPatientId } from "api/treatment"
import getCurrentScopeParams from "utils/getCurrentScopeParams"

const PatientKine = () => {
  const { patientId } = useParams()
  const { currentScope, user, setCurrentScope, setCenterAdminCurrentScope } =
    useAppContext()
  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const [searchParams] = useSearchParams()
  const osteoId = searchParams.get("osteoId")
  const centerId = searchParams.get("centerId")
  const academyId = searchParams.get("academyId")
  const isReviewModalOpen = searchParams.get("isReviewModalOpen")
  const hasPersonalAccount = !userHasNoPersonalAccount(user)
  const treatmentsMutation = useMutation(getTreatmentByPatientId)
  const [treatments, setTreatments] = React.useState<TreatmentType[]>([])
  const navigate = useNavigate()
  const patientParams = userHasPatientOnDifferentAccount({
    osteoId,
    centerId,
    academyId,
    hasPersonalAccount,
    user,
    currentScope,
    setCurrentScope,
    setCenterAdminCurrentScope,
    patientId,
  })

  const getPatientParams = {
    patientId,
    ...getCurrentScopeParams(currentScope),
  }

  const { data: patient } = useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(patientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error) =>
        retryPatientNoAccessRight(failureCount, error),
      onError: (error: any) => onPatientNoAccessRight(error),
    }
  )

  useEffect(() => {
    if (patient?.appointments) {
      const lastAppointmentIndex = patient.appointments.length - 1
      const lastAppointment = patient.appointments[lastAppointmentIndex]

      const params = new URLSearchParams()
      if (osteoId) params.set("osteoId", osteoId)
      if (centerId) params.set("centerId", centerId)
      if (academyId) params.set("academyId", academyId)
      if (isReviewModalOpen) params.set("isReviewModalOpen", isReviewModalOpen)

      if (lastAppointment && lastAppointment.treatment) {
        navigate(
          `/patients/${patient.id}/treatment/${
            lastAppointment.treatment.id
          }/appointment/${lastAppointment.id}?${params.toString()}`
        )
      }
    }
    if (patient?.id) {
      treatmentsMutation.mutate(
        {
          ...getPatientParams,
          patientId: patient.id.toString(),
        },
        {
          onSuccess: (data: TreatmentType[]) => {
            setTreatments(data)
          },
        }
      )
    }
  }, [patient])

  if (!patient) return null

  return (
    <Flex direction={"column"} gap={6}>
      <TreatmentHeader patient={patient} isInPatientPage={true} />
      {patient.checkups && patient.checkups.length > 0 ? (
        <Flex justify={"space-between"} align={"start"} gap={"20px"}>
          <Flex direction={"column"} w={"full"}>
            <Stats patient={patient} isInPatientKinePage={true} />
          </Flex>
          {patient.id !== undefined && (
            <RightTabKine patient={patient} treatments={treatments} />
          )}
        </Flex>
      ) : (
        <Flex justify={"end"} w={"full"}>
          <RightTabKine patient={patient} treatments={treatments} />
        </Flex>
      )}
    </Flex>
  )
}

export default PatientKine
