import { ChevronDownIcon } from "@chakra-ui/icons"
import * as Yup from "yup"
import {
  Box,
  Text,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Textarea,
  FormErrorMessage,
  FormControl,
  Switch,
} from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  getAllTrainingVideoExerciceType,
  TrainingVideoExerciceType,
} from "api/trainingVideoExerciceType"
import AAutocompleteMultiple from "components/AAutocompleteMultiple/AAutocompleteMultiple"
import AButton from "components/AButton"
import ANumberInput from "components/Field/ANumberInput"
import { Field, Form, Formik } from "formik"
import {
  ZonePathologieType,
  getZonesAndPathos,
} from "pages/NewAppointment/newAppointment.mock"
import { useEffect, useState } from "react"
import {
  createTrainingVideoUploaded,
  updateTrainingVideoUploaded,
} from "api/trainingVideoExercice"
import useToast from "hooks/useToast"
import useJobIsKineOrPodo from "hooks/useIsJobKineOrPodo"
import { useDropzone } from "react-dropzone"
import {
  getAppointmentReasons,
  TrainingVideoUploadedType,
} from "api/appointmentReason"

interface NewExerciceModalProps {
  isOpen: boolean
  onClose: () => void
  appointmentReason?: number
  currentVideo?: TrainingVideoUploadedType
}

const renderItem = ({ name }: ZonePathologieType) => name
const getKey = ({ id }: ZonePathologieType) => id.toString()

const NewExerciceModal = ({
  isOpen,
  onClose,
  appointmentReason,
  currentVideo,
}: NewExerciceModalProps) => {
  const { data: trainingVideoExerciceType } = useQuery(
    ["trainingVideoExerciceType"],
    getAllTrainingVideoExerciceType
  )
  const isJobKineOrPodo = useJobIsKineOrPodo()
  const queryClient = useQueryClient()

  const { data: allAppointmentReasons } = useQuery({
    queryKey: ["appointmentReasons"],
    queryFn: getAppointmentReasons,
  })

  getZonesAndPathos(allAppointmentReasons)

  const toast = useToast()
  const [level, setLevel] = useState<number>(currentVideo?.level ?? 1)
  const [zones, setZones] = useState<ZonePathologieType[]>(
    (currentVideo?.appointmentReasons
      ?.map(({ id }) =>
        getZonesAndPathos(allAppointmentReasons).find((zone) => zone.id === id)
      )
      .filter((item) => item) as ZonePathologieType[]) ?? []
  )

  const [zonesError, setZonesError] = useState<string>("")
  const [series, setSeries] = useState<number>(currentVideo?.config.series ?? 1)
  const [duration, setDuration] = useState<number>(
    currentVideo?.config.duration ?? 30
  )
  const [typeValue, setTypeValue] = useState<number>(
    currentVideo?.config.repetitions ?? 30
  )
  const [rest, setRest] = useState<number>(
    currentVideo?.config.restDuration ?? 30
  )
  const [video, setVideo] = useState<File | null>(null)
  const [videoError, setVideoError] = useState<string>("")
  const [canUseWeight, setCanUseWeight] = useState<boolean>(
    currentVideo?.config.canUseWeight ?? false
  )
  const [weight, setWeight] = useState<number>(
    currentVideo?.config.weightInKg ?? 5
  )

  const [
    selectedTrainingVideoExerciceType,
    setSelectedTrainingVideoExerciceType,
  ] = useState<TrainingVideoExerciceType | null>(
    currentVideo?.config.types?.[0] ?? null
  )

  useEffect(() => {
    if (!currentVideo) return

    setLevel(currentVideo.level ?? 1)
    setZones(
      currentVideo?.appointmentReasons
        ?.map(({ id }) =>
          getZonesAndPathos(allAppointmentReasons).find(
            (zone) => zone.id === id
          )
        )
        .filter((item) => item) as ZonePathologieType[]
    )
    setSeries(currentVideo.config.series ?? 1)
    setDuration(currentVideo.config.duration ?? 30)
    setTypeValue(currentVideo.config.repetitions ?? 30)
    setRest(currentVideo.config.restDuration ?? 30)
    setCanUseWeight(currentVideo.config.canUseWeight ?? false)
    setWeight(currentVideo.config.weightInKg ?? 5)
  }, [currentVideo])

  const createTrainingVideoUploadedMutation = useMutation(
    createTrainingVideoUploaded
  )

  const updateTrainingVideoUploadedMutation = useMutation(
    updateTrainingVideoUploaded
  )

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    if (file.size > 250 * 1000 * 1000) {
      setVideoError("La taille de la vidéo ne doit pas dépasser 250Mo")
      return
    }
    setVideoError("")
    setVideo(file)
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "video/*": [] },
    maxFiles: 1,
    maxSize: 250 * 1000 * 1000,
  })

  useEffect(() => {
    if (currentVideo) {
      setSelectedTrainingVideoExerciceType(
        currentVideo.config.types?.[0] ?? null
      )
    }
    if (trainingVideoExerciceType && !currentVideo) {
      setSelectedTrainingVideoExerciceType(trainingVideoExerciceType[0])
    }
  }, [trainingVideoExerciceType, currentVideo])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.400" />
      <ModalContent
        maxW="356"
        maxH="90vh"
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "primary.300",
            borderRadius: "24px",
          },
        }}
      >
        <Formik
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Titre requis"),
            description: Yup.string().optional(),
          })}
          initialValues={{
            title: currentVideo?.title ?? "",
            description: currentVideo?.config?.description ?? "",
          }}
          onSubmit={({ title, description }, { setSubmitting }) => {
            if (!video && !currentVideo) {
              setSubmitting(false)
              setVideoError("Veuillez ajouter une vidéo")
              return
            } else {
              setVideoError("")
            }

            if (!zones.length) {
              setSubmitting(false)
              setZonesError("Veuillez sélectionner au moins une zone")
              return
            } else {
              setZonesError("")
            }

            if (!selectedTrainingVideoExerciceType) {
              return
            }

            const formData = new FormData()
            formData.append("title", title)
            formData.append("description", description)
            if (video && !currentVideo) {
              formData.append("video", video)
            }
            formData.append("level", level.toString())
            formData.append("zones", JSON.stringify(zones))
            formData.append("series", series.toString())
            formData.append("rest", rest.toString())
            formData.append("duration", duration.toString())
            formData.append("weight", weight.toString())
            formData.append("canUseWeight", canUseWeight.toString())
            formData.append(
              "serieType",
              selectedTrainingVideoExerciceType.id.toString()
            )
            if (selectedTrainingVideoExerciceType?.key !== "untilFailure")
              formData.append("serieValue", typeValue.toString())

            if (currentVideo) {
              updateTrainingVideoUploadedMutation.mutate(
                { id: currentVideo.id, data: formData },
                {
                  onError: (data: any) => {
                    setSubmitting(false)
                    toast({
                      status: "error",
                      title: data.response.data.error.message,
                    })
                  },
                  onSuccess: async () => {
                    setSubmitting(false)
                    await queryClient.refetchQueries([
                      "uploadedVideos",
                      appointmentReason ?? -1,
                    ])
                    onClose()
                    toast({
                      status: "success",
                      title: "Exercice modifié avec succès",
                    })
                  },
                }
              )
            } else {
              createTrainingVideoUploadedMutation.mutate(formData, {
                onError: (data: any) => {
                  setSubmitting(false)
                  toast({
                    status: "error",
                    title: data.response.data.error.message,
                  })
                },
                onSuccess: async () => {
                  setSubmitting(false)
                  await queryClient.refetchQueries([
                    "uploadedVideos",
                    appointmentReason ?? -1,
                  ])
                  onClose()
                  toast({
                    status: "success",
                    title: "Exercice créé avec succès",
                  })
                },
              })
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box
                pos="relative"
                borderRadius={9}
                bg="white"
                w={352}
                py="16px"
                px="16px"
              >
                <ModalCloseButton aria-label="Fermer" />
                <Heading fontSize={18} fontWeight="bold" mb={4}>
                  {currentVideo
                    ? "Modification de l'exercice"
                    : "Télécharger un exercice"}
                </Heading>
                {currentVideo ? null : (
                  <>
                    <Flex
                      {...getRootProps()}
                      bg="common.200"
                      flexDir="column"
                      justifyContent="center"
                      alignItems="center"
                      py="60px"
                      borderRadius="8px"
                      mb={2}
                      cursor="pointer"
                    >
                      <input {...getInputProps()} />
                      <Text color="common.500" fontSize="16px" fontWeight={700}>
                        {video
                          ? "Vidéo sélectionnée"
                          : "Cliquez pour ajouter votre vidéo"}
                      </Text>
                      <Text color="common.400" fontSize="14px">
                        {video ? video.name : "orientation paysage, max 250Mo"}
                      </Text>
                    </Flex>

                    {videoError && (
                      <Text color="red.500" fontSize="14px">
                        {videoError}
                      </Text>
                    )}
                  </>
                )}
                <Field name="title">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={form.errors.title && form.touched.title}
                      mb={1}
                    >
                      <Input
                        placeholder="Titre exercice"
                        border="none"
                        p={0}
                        fontSize="18px"
                        fontWeight={700}
                        _focusVisible={{ border: "none" }}
                        {...field}
                      />
                      <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Menu>
                  <MenuButton
                    w="fit-content"
                    px="8px"
                    py="4px"
                    border="1px solid #CBCCCF"
                    rounded="4px"
                    mb={2}
                    type="button"
                  >
                    <Flex alignItems="center" gap="6px">
                      <Text fontSize="14px">Niveau {level}</Text>
                      <ChevronDownIcon boxSize="20px" />
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    {[1, 2, 3].map((currLevel, index) => (
                      <MenuItem
                        fontSize="16px"
                        key={index + 1}
                        onClick={() => setLevel(currLevel)}
                      >
                        Niveau {currLevel}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
                <AAutocompleteMultiple
                  bg="white"
                  border="1px solid #CBCCCF"
                  width="100%"
                  placeholder="Sélectionner..."
                  selectedItems={zones}
                  setSelectedItems={setZones}
                  items={[
                    ...getZonesAndPathos(allAppointmentReasons),
                    {
                      code: "other",
                      name: "Autre",
                      id: -1,
                      left: "0",
                      top: "0",
                      schema: 1,
                      type: "zone",
                    },
                  ]}
                  renderItem={renderItem}
                  inputValue=""
                  getKey={getKey}
                  isLoading={false}
                />
                {zonesError && (
                  <Text color="red.500" fontSize="14px">
                    {zonesError}
                  </Text>
                )}

                <Field name="description">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={
                        form.errors.description && form.touched.description
                      }
                      mb={1}
                    >
                      <Textarea
                        borderColor="#CBCCCF"
                        my={2}
                        placeholder="Description de l'exercice"
                        rows={4}
                        {...field}
                      />
                      <FormErrorMessage>
                        {form.errors.description}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Text fontWeight={700} fontSize="14px" mb={2}>
                  Par défaut
                </Text>
                {isJobKineOrPodo ? (
                  <>
                    <Flex justifyContent="space-between" mb={2}>
                      <Text>Séries</Text>
                      <ANumberInput
                        name="series"
                        min={1}
                        max={10}
                        step={1}
                        defaultValue={series}
                        value={series}
                        setValue={setSeries}
                      />
                    </Flex>
                    <Flex justifyContent="space-between" mb={2} gap={4}>
                      <Menu>
                        <MenuButton
                          w="fit-content"
                          px="8px"
                          py="4px"
                          border="1px solid #CBCCCF"
                          rounded="4px"
                          mb={2}
                          type="button"
                        >
                          <Flex alignItems="center" gap="6px">
                            <Text fontSize="14px" textAlign="start">
                              {selectedTrainingVideoExerciceType?.title}
                            </Text>
                            <ChevronDownIcon boxSize="20px" />
                          </Flex>
                        </MenuButton>
                        <MenuList>
                          {trainingVideoExerciceType?.map((currType, index) => (
                            <MenuItem
                              fontSize="16px"
                              key={index + 1}
                              onClick={() =>
                                setSelectedTrainingVideoExerciceType(currType)
                              }
                              textAlign="start"
                            >
                              {currType.title}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                      {selectedTrainingVideoExerciceType?.key !==
                        "untilFailure" && (
                        <ANumberInput
                          name="typeValue"
                          value={typeValue}
                          setValue={setTypeValue}
                          defaultValue={typeValue}
                        />
                      )}
                    </Flex>
                    <Flex justifyContent="space-between" mb={4}>
                      <Text>Repos (en secondes)</Text>

                      <ANumberInput
                        name="rest"
                        value={rest}
                        setValue={setRest}
                        defaultValue={rest}
                      />
                    </Flex>
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      mb={4}
                    >
                      <Text>Compatible avec une charge</Text>
                      <Switch
                        checked={canUseWeight}
                        onChange={() => setCanUseWeight((prev) => !prev)}
                        colorScheme="orange"
                        defaultChecked={canUseWeight}
                      />
                    </Flex>

                    {canUseWeight && (
                      <Flex justifyContent="space-between" mb={4}>
                        <Text>Poids (en kg)</Text>

                        <ANumberInput
                          name="weight"
                          value={weight}
                          setValue={setWeight}
                          defaultValue={weight}
                          step={1}
                        />
                      </Flex>
                    )}
                  </>
                ) : (
                  <Flex justifyContent="space-between" mb={4}>
                    <Text>Durée (en secondes)</Text>

                    <ANumberInput
                      name="duration"
                      value={duration}
                      setValue={setDuration}
                      defaultValue={duration}
                    />
                  </Flex>
                )}
                <AButton
                  type="submit"
                  isLoading={isSubmitting}
                  text="Ajouter"
                  variant="custom"
                  w="100%"
                  bg="primary.200"
                  _hover={{ bg: "primary.300" }}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default NewExerciceModal
