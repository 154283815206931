import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  FormControl,
  Textarea,
} from "@chakra-ui/react"
import { Field, Form, Formik } from "formik"
import React from "react"
import AButton from "../../../components/AButton"

interface IProps {
  isOpen: boolean
  onClose: () => void
  cancelRef: React.MutableRefObject<null>
  value?: string
  onChange?: (
    value: string,
    setSubmitting: (isSubmitting: boolean) => void,
    onClose: () => void
  ) => void
  type:
    | "osteoAdvice"
    | "internalNote"
    | "secondaryInternalNote"
    | "patientTitle"
}

const title = {
  osteoAdvice: "Modifier le conseil de la consultation envoyé au patient",
  internalNote: "Modifier la note complémentaire (non visible par le patient)",
  secondaryInternalNote:
    "Modifier la note supplémentaire (non visible par le patient)",
  patientTitle: "Modifier le titre du programme",
}

const EditableInputModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  cancelRef,
  value,
  onChange,
  type,
}) => {
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent maxW={724} borderRadius={8} p={8}>
        <AlertDialogHeader mb={8} p={0} fontSize={18}>
          {title[type]}
        </AlertDialogHeader>

        <Formik
          initialValues={{ [type]: value }}
          onSubmit={(values, { setSubmitting }) => {
            const typeValue = values[type]
            if (!typeValue || !onChange) return

            onChange(typeValue, setSubmitting, onClose)
          }}
        >
          {(props) => (
            <Form>
              <Field name={type}>
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder={
                        type === "patientTitle"
                          ? "Visible par le patient"
                          : "Écrivez vos notes ici"
                      }
                      bg="common.100"
                      maxLength={type === "patientTitle" ? 25 : undefined}
                    />
                  </FormControl>
                )}
              </Field>
              <AlertDialogFooter justifyContent="flex-end" p={0} mt={14}>
                <AButton
                  ref={cancelRef}
                  onClick={onClose}
                  text="Annuler"
                  variant="tertiary"
                  py={3}
                  px={27}
                />
                <AButton
                  ml={3}
                  text="Enregistrer les modifications"
                  py={3}
                  px={4}
                  type="submit"
                  isLoading={props.isSubmitting}
                />
              </AlertDialogFooter>
            </Form>
          )}
        </Formik>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default EditableInputModal
