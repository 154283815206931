import { AddIcon } from "@chakra-ui/icons"
import {
  Flex,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { PatientType } from "api/patient"
import {
  getTeleconsultationByPatientId,
  TeleconsultationType,
} from "api/teleconsultation"
import ANewButton from "components/ANewButton"
import TeleconsultationList from "components/Teleconsultation/TeleconsultationList"
import React, { useMemo, useState } from "react"

import { TreatmentType } from "api/treatment"
import { useAppContext } from "AppContext"
import StripeConnectModal from "components/StripeConnect/StripeConnectModal"
import TeleconsultationModal from "components/Teleconsultation/TeleconsultationModal"
import TreatmentCard from "components/Treatment/TreatmentCard"

interface RightTabKineProps {
  patient: PatientType
  treatments: TreatmentType[]
  currentTreatment?: TreatmentType
  setSelectedTeleconsultation?: (teleconsultation: TeleconsultationType) => void
}

const RightTabKine: React.FC<RightTabKineProps> = ({
  patient,
  treatments,
  currentTreatment,
  setSelectedTeleconsultation,
}) => {
  const { user, hasTeleconsultationAccess } = useAppContext()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenStripeModal,
    onClose: onCloseStripeModal,
    onOpen: onOpenStripeModal,
  } = useDisclosure()

  const [editedTeleconsultation, setEditedTeleconsultation] = useState<
    TeleconsultationType | undefined
  >(undefined)

  const sortedTreatments = treatments.sort((a, b) => (a.id < b.id ? 1 : -1))

  const osteoId = user?.osteo?.id

  const { data: teleconsultations, isLoading } = useQuery({
    queryKey: ["getTeleconsultationByPatientId", patient?.id?.toString() || ""], // Assurez-vous que c'est une string
    queryFn: () =>
      getTeleconsultationByPatientId(patient?.id?.toString() || ""),
    enabled: !!patient?.id, // Ne lance la query que si patient.id existe
  })

  const handleOpenEditModal = (teleconsultation: TeleconsultationType) => {
    setEditedTeleconsultation(teleconsultation)
    onOpen()
  }

  const handleCloseEditModal = () => {
    setEditedTeleconsultation(undefined)
    onClose()
  }

  const handleClick = () => {
    onOpen()
  }

  const hasMoreThanOneTreatmentActive = useMemo(() => {
    return (
      sortedTreatments.filter((treatment) =>
        treatment.appointments.find((appointment) => appointment.isActive)
      ).length > 1
    )
  }, [sortedTreatments])

  return (
    <Tabs bg={"primary.200"} borderRadius={9} p={4} minH={"80vh"} w={"350px"}>
      {hasTeleconsultationAccess ? (
        <TabList>
          <Tab
            _selected={{
              color: "common.800",
              fontWeight: 700,
              borderBottom: "2px solid",
              borderColor: "black",
            }}
            fontSize={"18px"}
          >
            Traitement
          </Tab>
          <Tab
            _selected={{
              color: "common.800",
              fontWeight: 700,
              borderBottom: "2px solid",
              borderColor: "black",
            }}
            fontSize={"18px"}
          >
            Téléconsultation
          </Tab>
        </TabList>
      ) : (
        <Text fontSize={"18px"} fontWeight={700}>
          Traitement
        </Text>
      )}

      <TabPanels>
        <TabPanel padding={0} mt={4}>
          <Flex direction={"column"} gap={"8px"}>
            {sortedTreatments.length > 0 &&
              sortedTreatments.map((treatment, index) => (
                <TreatmentCard
                  key={index}
                  treatment={treatment}
                  isSelected={treatment.id === currentTreatment?.id}
                  isLastTreatmentActive={!hasMoreThanOneTreatmentActive}
                />
              ))}
          </Flex>
        </TabPanel>
        <TabPanel padding={0} mt={4}>
          <Flex direction={"column"} gap={"20px"} alignItems={"start"}>
            <ANewButton
              text="Nouvelle téléconsultation"
              variant="teleconsultation"
              icon={<AddIcon fontSize={"12px"} />}
              onClick={handleClick}
            />
            {isLoading ? (
              <Spinner color="primary.500" />
            ) : (
              <TeleconsultationList
                teleconsultations={teleconsultations}
                handleOpenEditModal={handleOpenEditModal}
                patientId={patient.id?.toString()}
                onTeleconsultationClick={setSelectedTeleconsultation}
              />
            )}
          </Flex>
          {osteoId && (
            <TeleconsultationModal
              isOpen={isOpen}
              onClose={handleCloseEditModal}
              osteoId={osteoId}
              isEditing={!!editedTeleconsultation}
              teleconsultation={editedTeleconsultation}
              patientId={patient.id?.toString()}
            />
          )}
          <StripeConnectModal
            isOpen={isOpenStripeModal}
            onClose={onCloseStripeModal}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default RightTabKine
