import mixpanel from "mixpanel-browser"

const logEvent = (event: string, properties?: Record<string, any>) => {
  mixpanel.track(event, properties)
}

const logNumberOfPatients = (numberOfPatients: number) => {
  mixpanel.people.set("patients_count", numberOfPatients)
}

const logNumberOfAppointments = (numberOfAppointments: number) => {
  mixpanel.people.set("appointments_count", numberOfAppointments)
}

export default {
  logEvent,
  logNumberOfPatients,
  logNumberOfAppointments,
}
