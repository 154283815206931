import { CheckIcon } from "@chakra-ui/icons"
import { Box, Text } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updateAppointmentIsActive } from "api/appointments"
import ASwitch from "components/ASwitch/ASwitch"
import useToast from "hooks/useToast"
import { Link } from "react-router-dom"
// HELPERS
interface getLinkAriaLabelProps {
  date: string
  name: string
  academie?: AcademieType
  center?: CenterType
}
const getLinkAriaLabel = ({
  date,
  name,
  academie,
  center,
}: getLinkAriaLabelProps) => {
  if (academie) {
    return `Voir la consultation du ${date} par ${name} de l'Académie ${academie.name}`
  }
  if (center) {
    return `Voir la consultation du ${date} par ${name} du Centre ${center.name}`
  }
  return `Voir la consultation du ${date} par ${name}`
}

// COMPONENTS
interface AppointmentCardProps extends getLinkAriaLabelProps {
  link: string
  isSelected: boolean
  isActive: boolean
  patientId: number
  appointmentId: number
  isLastAppointmentActive: boolean //Used to make sure user can't deactivate last active appointment
  patientTitle?: string
}

const AppointmentCard = ({
  date,
  name,
  academie,
  center,
  link,
  isSelected,
  isActive,
  patientId,
  appointmentId,
  patientTitle,
  isLastAppointmentActive,
}: AppointmentCardProps) => {
  const linkAriaLabel = getLinkAriaLabel({ date, name, academie, center })
  const toast = useToast()
  const queryClient = useQueryClient()

  const updateAppointmentMutation = useMutation(updateAppointmentIsActive, {
    onError: () => {
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    },
    onSuccess: () => {
      toast({
        status: "success",
        title: `Traitement ${!isActive ? "activé" : "désactivé"} avec succès`,
      })

      queryClient.invalidateQueries(["getPatient", patientId.toString()])
    },
  })

  const onAppointmentIsActiveChange = () => {
    console.log("Is last appointment active", isLastAppointmentActive)
    console.log("Is active", isActive)
    if (isLastAppointmentActive && isActive) {
      toast({
        status: "error",
        title: "Le patient doit avoir au moins une consultation active",
      })
      return
    }
    updateAppointmentMutation.mutate({
      id: appointmentId.toString(),
      isActive: !isActive,
    })
  }

  return (
    <Link to={link} aria-label={linkAriaLabel}>
      <Box
        mb={4}
        py={2}
        px={2}
        bg={isSelected ? "secondary.200" : "white"}
        _hover={{ bg: "primary.100" }}
        borderRadius={9}
        pos="relative"
      >
        {patientTitle && (
          <Text fontSize={14} color="tertiary.500" mb={0} fontWeight={700}>
            {patientTitle}
          </Text>
        )}
        <Text fontSize={14} color="tertiary.500" mb={2}>
          {date}
        </Text>
        <Text fontSize={12} fontWeight={400}>
          {name}
        </Text>
        <Box
          position="absolute"
          right={2}
          top={2}
          onClick={(e) => e.stopPropagation()}
        >
          <ASwitch
            isChecked={isActive}
            onChange={onAppointmentIsActiveChange}
          />
        </Box>
        {academie && <Text fontSize={16}>Académie : {academie.name}</Text>}
        {center && <Text fontSize={16}>Centre : {center.name}</Text>}
        {isSelected ? (
          <CheckIcon
            pos="absolute"
            fontSize={16}
            right={3}
            bottom={2}
            color="valid.500"
          />
        ) : null}
      </Box>
    </Link>
  )
}

export default AppointmentCard
