import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import AndrewSuperHero from "assets/illustrations/andrew-superheros.png"
import QRCode from "assets/mobileapp_qrcode.svg"
import AButton from "components/AButton"
import OnboardingStep from "pages/Onboarding/components/OnboardingStep"
type Props = {
  onFinsh: () => void
}

export default function OnboardingStep5({ onFinsh }: Props) {
  return (
    <Flex justifyContent="center" mt={7} pos="relative">
      <Box maxW="640px" w="100%">
        <Heading as="h1" fontSize={28} color="black" mb={2} mt={10}>
          Pour terminer, découvrez l'espace patient
        </Heading>
        <OnboardingStep step={5} totalSteps={5} />

        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text>
            Bravo ! Vous avez eu un premier aperçu de tout ce qu'Andrew® peut
            vous apporter. Découvrez maintenant votre espace patient et explorez
            comment Andrew® enrichit l'expérience de votre patientèle.
          </Text>
          <Box
            justifyContent="center"
            alignItems="center"
            backgroundColor={"primary.150"}
            width="100%"
            style={{ aspectRatio: 2 }}
            borderRadius={8}
            display="flex"
            mt={12}
          >
            <Image src={QRCode} alt="QR Code" width={200} height={200} mb={2} />
            <Text textAlign="center" maxWidth="200px" fontWeight="bold" mb={8}>
              Flashez le code pour télécharger l’app et voir votre première
              prescription
            </Text>
          </Box>
          <AButton
            text="Démarrer l'aventure 🚀"
            variant="primary"
            size="lg"
            mt={12}
            px={8}
            onClick={onFinsh}
          />
        </Box>
        <Image
          position="absolute"
          right={16}
          top={36}
          width="160px"
          style={{ aspectRatio: 164 / 200 }}
          src={AndrewSuperHero}
          alt="Andrew Super Hero"
        />
      </Box>
    </Flex>
  )
}
