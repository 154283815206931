import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import AButton from "components/AButton"
import useJobIsKineOrPodo from "hooks/useIsJobKineOrPodo"
import OnboardingStep from "pages/Onboarding/components/OnboardingStep"
import { InlineWidget } from "react-calendly"
type Props = {
  onNextStep: () => void
}

export default function OnboardingStep4({ onNextStep }: Props) {
  const isJobKineOrPodo = useJobIsKineOrPodo()

  return (
    <Flex flexDirection="column" alignItems="center" mt={7}>
      <Box maxW="640px" w="100%">
        <Box>
          <Heading as="h1" fontSize={28} color="black" mb={2} mt={10}>
            Réservez votre démo pour découvrir la totalité des fonctionnalités
            d'Andrew® en quelques minutes
          </Heading>

          <OnboardingStep step={4} totalSteps={5} />
        </Box>
        {isJobKineOrPodo && (
          <Text color="primary.800" fontSize="larger" fontWeight="bold">
            Et découvrez comment rembourser votre abonnement grâce aux nouvelles
            aides de l'État.
          </Text>
        )}
      </Box>

      <InlineWidget
        url="https://calendly.com/d/cq6g-wg8-9dm/30-minute-meeting"
        styles={{ width: "1000px", height: "700px" }}
      />

      <AButton
        text="Suivant"
        variant="primary"
        size="lg"
        px={8}
        mb={10}
        onClick={onNextStep}
      />
    </Flex>
  )
}
