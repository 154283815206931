import { Flex, Image, Link } from "@chakra-ui/react"
import {
  SelectedExerciceType,
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
} from "api/appointmentReason"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"
import SelectProgramExercices from "pages/NewProgram/components/SelectProgramExercices"
import React, { useEffect, useState } from "react"
import arrowBack from "../../assets/arrow_back.svg"
import { Link as RouterLink, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { getProgram } from "api/program"

const EditProgram = () => {
  const { programId } = useParams()

  const { data: program } = useQuery({
    queryKey: ["program", programId],
    queryFn: () => getProgram(Number(programId)),
    enabled: !!programId,
  })

  const [reasonType, setReasonType] = useState<"zones" | "pathologies" | null>(
    "zones"
  )
  const [reason, setReason] = useState<ZonePathologieType>()
  const [selectedExercices, setSelectedExercices] = useState<
    SelectedExerciceType[]
  >([])
  const [selectedUploadedExercices, setSelectedUploadedExercices] = useState<
    SelectedUploadedExerciceType[]
  >([])
  const [selectedVideoOrder, setSelectedVideoOrder] = useState<
    SelectedVideoOrderType[]
  >([])

  useEffect(() => {
    if (program) {
      const appointmentExercices: SelectedExerciceType[] =
        program.trainingVideoPrograms
          .map((exercice) => {
            if (!exercice.trainingVideoUploaded && exercice.trainingVideo) {
              const { trainingVideo, ...rest } = exercice
              return {
                ...rest,
                ...trainingVideo,
                videoGroup: trainingVideo.group[0].id,
              }
            }
          })
          .filter((ex) => ex) as SelectedExerciceType[]
      const appointmentExercicesUploaded: SelectedUploadedExerciceType[] =
        program.trainingVideoPrograms
          .map((exercice) => {
            if (exercice.trainingVideoUploaded && !exercice.trainingVideo) {
              const { trainingVideoUploaded, ...rest } = exercice
              return {
                ...rest,
                ...trainingVideoUploaded,
              }
            }
          })
          .filter((ex) => ex) as SelectedUploadedExerciceType[]

      setSelectedExercices(appointmentExercices)
      setSelectedUploadedExercices(appointmentExercicesUploaded)
      setSelectedVideoOrder(
        program.trainingVideoPrograms.map((exercice) => {
          return {
            id: exercice.trainingVideoUploaded
              ? exercice.trainingVideoUploaded.id
              : exercice.trainingVideo!.id,
            type: exercice.trainingVideoUploaded ? "uploaded_video" : "video",
          }
        })
      )
    }
  }, [program])

  return (
    <Flex direction="column">
      <Link as={RouterLink} to="/exercices" m="16px" mb="0px" w="fit-content">
        <Flex
          mb={6}
          cursor="pointer"
          p="8px"
          bgColor="primary.200"
          w="fit-content"
          display="block"
          rounded="9px"
        >
          <Image src={arrowBack} width="24px" height="24px" />
        </Flex>
      </Link>
      <Flex pb="100px">
        <SelectProgramExercices
          reason={reason}
          setReason={setReason}
          reasonType={reasonType}
          setReasonType={setReasonType}
          selectedExercices={selectedExercices}
          setSelectedExercices={setSelectedExercices}
          selectedUploadedExercices={selectedUploadedExercices}
          setSelectedUploadedExercices={setSelectedUploadedExercices}
          selectedVideoOrder={selectedVideoOrder}
          setSelectedVideoOrder={setSelectedVideoOrder}
          isCreatingProgram={false}
          currentProgram={program}
        />
      </Flex>
    </Flex>
  )
}

export default EditProgram
