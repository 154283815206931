import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import AEditButton from "components/AEditButton"
import { format } from "date-fns"
import React, { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  PatientAppointmentType,
  UserPainFeedbackType,
} from "../../../api/patient"
import addTwoMonths from "../../../utils/addTwoMonth"
import AButton from "components/AButton"
import NewPlaylistValidity from "./NewPlaylistValidity"
import TrainingExerciceOsteoCards from "pages/Appointment/components/TrainingExerciceOsteoCards"
import TrainingExerciceKineCards from "pages/Appointment/components/TrainingExerciceKineCards"
import TrainingKineGraph from "pages/Appointment/components/TrainingKineGraph"

interface IProps {
  appointment: PatientAppointmentType
  patientId: string | undefined
  setAppointment?: React.Dispatch<
    React.SetStateAction<PatientAppointmentType | null>
  >
  reviews?: UserPainFeedbackType[]
}

const Training: React.FC<IProps> = ({
  appointment,
  patientId,
  setAppointment,
  reviews = [],
}) => {
  const navigate = useNavigate()
  const cancelRef = useRef(null)

  const [selectedSeries, setSelectedSeries] = useState<string | null>(null)
  const {
    isOpen: isDelayModalOpen,
    onClose: onDelayModalClose,
    onOpen: onDelayModalOpen,
  } = useDisclosure()
  const isAppointmentKineType =
    appointment.job === null || appointment.job === undefined
      ? false
      : appointment.job.key === "kine" || appointment.job.key === "podo"
  const onEdit = () => {
    navigate(`/patients/${patientId}/appointments/${appointment.id}/edit`)
  }

  return (
    <div>
      <Box bg="white" p={4} borderRadius={9} pos="relative" maxW="full">
        <AEditButton
          pos="absolute"
          top={2}
          right={2}
          onClick={onEdit}
          ariaLabel="Modifier les exercices conseillés"
        />

        <Heading as="h3" fontSize={18} mb={2}>
          Exercices conseillés
        </Heading>
        <Text fontSize={12} mb={2}>
          Disponible jusqu’au{" "}
          {appointment.trainingPlaylist?.expiry !== undefined
            ? format(
                new Date(appointment.trainingPlaylist.expiry),
                "dd/MM/yyyy"
              )
            : format(
                addTwoMonths(new Date(appointment.meetingDate)),
                "dd/MM/yyyy"
              )}
        </Text>
        {appointment.trainingPlaylist?.mainReason !== null && (
          <>
            <Text fontSize={14}>
              <b>
                Motif principal de consultation :{" "}
                {appointment.trainingPlaylist?.mainReason.name}
              </b>
            </Text>
            {appointment.trainingPlaylist?.reasons && (
              <Text fontSize={14}>
                Autres motifs de consultation :{" "}
                {appointment.trainingPlaylist?.reasons
                  .map((reason) => reason.name)
                  .join(", ")}
              </Text>
            )}
          </>
        )}

        <Flex flexWrap={"nowrap"} gap={4} maxW="full">
          {!isAppointmentKineType ? (
            <TrainingExerciceOsteoCards appointment={appointment} />
          ) : (
            <Box maxW="full" w="full" overflow="hidden">
              {appointment.trainingPlaylist && (
                <TrainingKineGraph
                  data={appointment.trainingPlaylist}
                  selectedSeries={selectedSeries}
                  reviews={reviews}
                />
              )}
              <TrainingExerciceKineCards
                appointment={appointment}
                selectedSeries={selectedSeries}
                setSelectedSeries={setSelectedSeries}
              />
            </Box>
          )}
        </Flex>
        <Flex alignItems="center" justify="center" py="2">
          <AButton text="Prolonger la durée" onClick={onDelayModalOpen} />
        </Flex>
      </Box>
      <NewPlaylistValidity
        onClose={onDelayModalClose}
        isOpen={isDelayModalOpen}
        cancelRef={cancelRef}
        appointment={appointment}
        setAppointment={setAppointment}
      />
    </div>
  )
}

export default Training
