import { Flex, Switch, Text } from "@chakra-ui/react"
import { v4 as uuidv4 } from "uuid"

interface ASwitchProps {
  isChecked: boolean
  onChange: (isChecked: boolean) => void
}

const ASwitch = ({ isChecked, onChange }: ASwitchProps) => {
  const id = uuidv4()
  return (
    <Flex
      alignItems={"center"}
      gap={2}
      onClick={(e) => e.stopPropagation()}
      py="3px"
      px="4px"
      borderRadius={"4px"}
      bg={isChecked ? "secondary.300" : "common.200"}
      cursor={"pointer"}
    >
      <Text
        as="label"
        htmlFor={id}
        fontWeight={"bold"}
        fontSize={"14px"}
        color={isChecked ? "secondary.900" : "common.400"}
        cursor={"pointer"}
      >
        {isChecked ? "Actif" : "Inactif"}
      </Text>
      <Switch
        size="sm"
        id={id}
        colorScheme="green"
        isChecked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
      />
    </Flex>
  )
}

export default ASwitch
