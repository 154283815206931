import { TeleconsultationType } from "api/teleconsultation"
import { Flex, Text } from "@chakra-ui/react"
import TeleconsultationCard from "./TeleconsultationCard"
import { useState, useEffect } from "react"

interface TeleconsultationListProps {
  teleconsultations: TeleconsultationType[] | undefined
  handleOpenEditModal: (teleconsultation: TeleconsultationType) => void
  patientId?: string
  onTeleconsultationClick?: (teleconsultation: TeleconsultationType) => void
}
const TeleconsultationList = ({
  teleconsultations,
  handleOpenEditModal,
  patientId,
  onTeleconsultationClick,
}: TeleconsultationListProps) => {
  const [selectedTeleconsultation, setSelectedTeleconsultation] =
    useState<TeleconsultationType | null>(null)

  useEffect(() => {
    if (teleconsultations && teleconsultations.length > 0) {
      const firstTeleconsultation = teleconsultations[0]
      setSelectedTeleconsultation(firstTeleconsultation)
    } else {
      setSelectedTeleconsultation(null)
    }
  }, [teleconsultations])

  useEffect(() => {
    if (selectedTeleconsultation && onTeleconsultationClick) {
      onTeleconsultationClick(selectedTeleconsultation)
    }
  }, [selectedTeleconsultation, onTeleconsultationClick])

  if (teleconsultations?.length === 0) return null
  if (patientId) {
    return (
      <Flex direction={"column"} gap={"16px"} alignItems={"start"}>
        <Flex direction={"column"} gap={"8px"}>
          <Text fontWeight={"bold"}>À venir</Text>
          <Flex direction={"column"} gap={2}>
            {teleconsultations
              ?.filter((teleconsultation) => !teleconsultation.isFinished)
              .map((teleconsultation) => (
                <TeleconsultationCard
                  key={teleconsultation.id}
                  teleconsultation={teleconsultation}
                  isSelected={
                    selectedTeleconsultation?.id === teleconsultation.id
                  }
                  onClick={() => setSelectedTeleconsultation(teleconsultation)}
                  handleOpenEditModal={handleOpenEditModal}
                  patientId={patientId}
                />
              ))}
          </Flex>
        </Flex>
        <Flex direction={"column"} gap={"8px"}>
          <Text fontWeight={"bold"}>Passées</Text>
          <Flex direction={"column"} gap={2}>
            {teleconsultations
              ?.filter((teleconsultation) => teleconsultation.isFinished)
              .map((teleconsultation) => (
                <TeleconsultationCard
                  key={teleconsultation.id}
                  teleconsultation={teleconsultation}
                  isSelected={
                    selectedTeleconsultation?.id === teleconsultation.id
                  }
                  onClick={() => setSelectedTeleconsultation(teleconsultation)}
                  handleOpenEditModal={handleOpenEditModal}
                  patientId={patientId}
                />
              ))}
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap="8px">
      {!teleconsultations && <Text>Aucune téléconsultation</Text>}
      {teleconsultations &&
        teleconsultations.map((teleconsultation) => (
          <TeleconsultationCard
            key={teleconsultation.id}
            teleconsultation={teleconsultation}
            isSelected={selectedTeleconsultation?.id === teleconsultation.id}
            onClick={() => setSelectedTeleconsultation(teleconsultation)}
            handleOpenEditModal={handleOpenEditModal}
          />
        ))}
    </Flex>
  )
}

export default TeleconsultationList
