import { CheckIcon } from "@chakra-ui/icons"
import {
  AspectRatio,
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import { useAppContext } from "AppContext"
import AndrewFlying from "assets/illustrations/andrew-flying.png"
import AButton from "components/AButton"
import OnboardingStep from "pages/Onboarding/components/OnboardingStep"
import { OnboardingData } from "pages/Onboarding/OnboardingData"
import { useMemo, useRef } from "react"

type Props = {
  onNextStep: () => void
}

export default function OnboardingStep1({ onNextStep }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { user } = useAppContext()

  const job = useMemo(() => {
    if (user?.osteo?.jobs?.some((job) => job.key === "kine")) {
      return "kine"
    }
    if (user?.osteo?.jobs?.some((job) => job.key === "podo")) {
      return "podo"
    }

    return "osteo"
  }, [user])

  const headline = useMemo(() => {
    if (job === "kine") {
      return OnboardingData.kine.headline
    }

    if (job === "podo") {
      return OnboardingData.podo.headline
    }

    return OnboardingData.osteo.headline
  }, [job])

  const features = useMemo(() => {
    if (job === "kine") {
      return OnboardingData.kine.items
    }

    if (job === "podo") {
      return OnboardingData.podo.items
    }

    return OnboardingData.osteo.items
  }, [user])

  const video = useMemo(() => {
    if (job === "kine") {
      return OnboardingData.kine.video
    }

    if (job === "podo") {
      return OnboardingData.podo.video
    }

    return OnboardingData.osteo.video
  }, [user])

  return (
    <Flex justifyContent="center" alignSelf="center" mt={7}>
      <Box maxW="640px" w="100%">
        <Box>
          <Heading as="h1" fontSize={28} color="black" mb={2} mt={10}>
            Bienvenue {user?.osteo.firstname ?? ""}, découvrez les essentiels en
            moins de 5 minutes
          </Heading>
          <OnboardingStep step={1} totalSteps={5} />
        </Box>

        <Flex mt={16} backgroundColor={"primary.150"} p={12} zIndex={2}>
          <Box>
            <Text
              color="primary.800"
              fontSize="larger"
              fontWeight="bold"
              mb={6}
              maxW="350px"
            >
              {headline}
            </Text>

            <UnorderedList spacing={3} styleType="none" mb={8} ml={0} pl={0}>
              {features.map((item) => (
                <ListItem
                  key={
                    typeof item === "string"
                      ? item
                      : item?.props?.children.join("")
                  }
                >
                  <Flex align="center">
                    <Icon as={CheckIcon} color="primary.500" mr={2} />
                    <Text fontSize="lg">{item}</Text>
                  </Flex>
                </ListItem>
              ))}
            </UnorderedList>

            <AButton
              text="Démarrer"
              variant="primary"
              size="lg"
              px={8}
              onClick={onNextStep}
            />
          </Box>
        </Flex>
      </Box>

      <Box maxW="400px" mt={40} ml={8}>
        <AspectRatio ratio={9 / 16} width="300px">
          <video
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
            style={{ borderRadius: "10px" }}
            controls
            src={video}
          ></video>
        </AspectRatio>
      </Box>

      <Box width="15%" minWidth="100px" maxWidth="200px" zIndex={1}>
        <Image
          position="absolute"
          right="2%"
          top="2%"
          width="15%"
          style={{ aspectRatio: 216 / 126 }}
          src={AndrewFlying}
          alt="Andrew Aventurier"
        />
      </Box>
    </Flex>
  )
}
