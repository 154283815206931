import axiosInstance from "./axiosInstance"

export type UploadOsteoType = {
  firstname?: string
  lastname?: string
  avatar?: string | AvatarType
  sessionId?: string
  stripeCustomerId?: string
  hasUsedTrial?: boolean
  mainAddress?: AddressType
  secondaryAddress?: AddressType
  urlDoctolib?: string
  telephone?: string
  patients?: number[]
  hasAcceptedCGS?: boolean
  stripeConnectAccount?: string
}

export type UpdateOsteoParams = {
  id: string
  data: UploadOsteoType
}

export interface OsteoResponseType extends OsteoType {
  avatar: {
    data: {
      attributes: {
        url: string
      }
    }
  }
}

export const updateOsteo = async (params: UpdateOsteoParams) => {
  const response = await axiosInstance.post(`/osteo/me/update`, {
    data: params.data,
  })
  return response.data as OsteoResponseType
  // return response.data.data.attributes as OsteoResponseType
}

export const updateForumOsteo = async (params: UpdateOsteoParams) => {
  const response = await axiosInstance.put(
    `/osteo/forum/osteo/${params.id}`,
    params.data
  )
  return response.data
}

export type CreateOsteoType = {
  userId?: number
  password?: string
  firstname: string
  lastname: string
  email: string
  avatar?: string
  urlDoctolib?: string
  isOsteow?: boolean
  isTester?: boolean
  telephone?: string
  mainAddress?: AddressType
  secondaryAddress?: AddressType
  centerId?: number
  academieId?: number
  // cooptor?: string
  jobs?: number[]
  hasAcceptedCGS?: boolean
  isReminderActivated?: boolean
}

export const createOsteo = async (data: CreateOsteoType) => {
  const response = await axiosInstance.post(`/osteo/me/osteo/new`, {
    ...data,
  })
  return response.data
}

export type OsteoByAppointmentsCount = {
  id: number
  osteo: {
    firstname: string
    lastname: string
    id: number
    sessionId: string
    telephone: string
  }
  appointmentsCount: number
  createdAt: string
  email: string
}

export type GetOsteoByAppointmentsCountResponse = {
  data: OsteoByAppointmentsCount[]
  totalPage: number
}

export const getOsteoByAppointmentsCount = async (
  page: number,
  column: { id: number; label: string; key: string; order: string }
) => {
  const response = await axiosInstance.get<GetOsteoByAppointmentsCountResponse>(
    `/osteo/getOsteoByAppointmentsCount/${page}`,
    {
      params: {
        column: JSON.stringify(column),
      },
    }
  )
  return response.data
}

type OnboardingResponse = {
  shouldDisplayOnboarding: boolean
}

export const getShouldDisplayOnboarding = async () => {
  const response = await axiosInstance.get<OnboardingResponse>(
    `/osteo/me/onboarding`
  )
  return response.data
}
