import React from "react"
import { Modal, ModalOverlay, ModalContent, Text, Flex } from "@chakra-ui/react"
import AButton from "components/AButton"
interface DeleteUplaodedExerciceModalProps {
  isOpen: boolean
  onClose: () => void
  handleDelete: () => void
}

const DeleteUplaodedExerciceModal: React.FC<
  DeleteUplaodedExerciceModalProps
> = ({ isOpen, onClose, handleDelete }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.400" />
      <ModalContent p={8}>
        <Text>Voulez-vous vraiment supprimer cet exercice ?</Text>

        <Flex gap={4} justifyContent="flex-end" mt={8}>
          <AButton text="Annuler" variant="tertiary" onClick={onClose} />
          <AButton text="Supprimer" variant="primary" onClick={handleDelete} />
        </Flex>
      </ModalContent>
    </Modal>
  )
}

export default DeleteUplaodedExerciceModal
