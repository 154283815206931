import { AppointmentType } from "api/appointments"
import {
  GetPatientParams,
  PatientAppointmentType,
  PatientType,
} from "api/patient"
import axiosInstance from "./axiosInstance"

export interface TreatmentType {
  id: number
  title: string
  osteo: OsteoType
  patient: PatientType
  appointments: PatientAppointmentType[]
  patientTitle?: string
}

export const getTreatmentByPatientId = async ({
  patientId,
  academieId,
  centerId,
}: GetPatientParams) => {
  const { data } = await axiosInstance.get<TreatmentType[]>(
    `/treatment/all/patient/${patientId}`,
    {
      params: { academie_id: academieId, center_id: centerId },
    }
  )
  return data
}

export const getTreatmentById = async (treatmentId: number) => {
  const { data } = await axiosInstance.get<TreatmentType>(
    `/treatment/${treatmentId}`
  )
  return data
}

export const updateTreatmentIsActive = async ({
  treatmentId,
  isActive,
}: {
  treatmentId: number
  isActive: boolean
}) => {
  const { data } = await axiosInstance.put<TreatmentType>(
    `/treatment/${treatmentId}/is-active`,
    { isActive }
  )
  return data
}

export const updateTreatmentPatientTitle = async ({
  treatmentId,
  patientTitle,
}: {
  treatmentId: number
  patientTitle: string
}) => {
  const { data } = await axiosInstance.put<TreatmentType>(
    `/treatment/${treatmentId}/patient-title`,
    { patientTitle }
  )
  return data
}
