import { TreatmentType, updateTreatmentPatientTitle } from "api/treatment"
import React, { useMemo, useState } from "react"
import { Flex, Text } from "@chakra-ui/react"
import RightTabKine from "components/Treatment/RightTabKine"
import {
  CheckupType,
  PatientAppointmentType,
  PatientReviewType,
  PatientType,
  UserPainFeedbackType,
} from "api/patient"
import AppointmentMedias from "pages/Appointment/components/AppointmentMedias"
import EditableInput from "pages/Appointment/components/EditableInput"
import Training from "pages/Appointment/components/Training"
import AppointmentStats from "pages/Appointment/components/AppointmentStats"
import { isAfter, isBefore } from "date-fns"
import addTwoMonths from "utils/addTwoMonth"
import ScrollableSelectTab from "components/Treatment/ScrollableSelectTab"
import AppointmentEducationalContents from "components/Treatment/AppointmentEducationalContents"
import Stats from "pages/Patient/components/Stats"
import useToast from "hooks/useToast"
import { useMutation } from "@tanstack/react-query"
import { updateAppointment } from "api/appointments"
import PatientReviews from "components/PatientReviews"
import { TeleconsultationType } from "api/teleconsultation"

interface TreatmentsContainerProps {
  treatments: TreatmentType[]
  currentTreatment: TreatmentType
  setCurrentTreatment: (treatment: TreatmentType) => void
  currentAppointment: PatientAppointmentType
  setCurrentAppointment: React.Dispatch<
    React.SetStateAction<PatientAppointmentType | null>
  >
  patient: PatientType
  updateTreatment: () => void
  reviews: PatientReviewType[] | undefined
}
const TreatmentsContainer = ({
  treatments,
  currentTreatment,
  currentAppointment,
  setCurrentAppointment,
  setCurrentTreatment,
  patient,
  updateTreatment,
  reviews,
}: TreatmentsContainerProps) => {
  const toast = useToast()
  const [selectedTeleconsultation, setSelectedTeleconsultation] =
    useState<TeleconsultationType | null>(null)

  const updateAppointmentMutation = useMutation(updateAppointment)
  const updateTreatmentPatientTitleMutation = useMutation(
    updateTreatmentPatientTitle
  )
  const handleEditableInputChange = (
    type: string,
    value: string,
    setSubmitting: (isSubmitting: boolean) => void,
    onClose: () => void
  ) => {
    if (!currentAppointment?.id) return

    updateAppointmentMutation.mutate(
      { id: currentAppointment?.id?.toString(), data: { [type]: value } },
      {
        onSuccess: (data: PatientAppointmentType) => {
          if (!setCurrentAppointment) return

          setSubmitting(false)
          setCurrentAppointment({
            ...currentAppointment,
            osteoAdvice: data.osteoAdvice,
            internalNote: data.internalNote,
            secondaryInternalNote: data.secondaryInternalNote,
            patientTitle: data.patientTitle,
          })
          onClose()
          toast({
            status: "success",
            title: "Modifications enregistrées",
          })
        },
        onError: () => {
          setSubmitting(false)
          toast({
            status: "error",
            title: "Une erreur s'est produite",
          })
        },
      }
    )
  }

  const handleTreatmentPatientTitleChange = (
    type: string,
    value: string,
    setSubmitting: (isSubmitting: boolean) => void,
    onClose: () => void
  ) => {
    if (!currentTreatment?.id) return
    updateTreatmentPatientTitleMutation.mutate(
      { treatmentId: currentTreatment?.id, patientTitle: value },
      {
        onSuccess: (data: TreatmentType) => {
          if (!setCurrentTreatment) return

          setSubmitting(false)
          updateTreatment()
          onClose()
          toast({
            status: "success",
            title: "Modifications enregistrées",
          })
        },
        onError: () => {
          setSubmitting(false)
          toast({
            status: "error",
            title: "Une erreur s'est produite",
          })
        },
      }
    )
  }

  return (
    <Flex justify={"space-between"} gap={"20px"}>
      <Flex direction={"column"} w={"full"} maxW="calc(100% - 380px)">
        <Text fontWeight={"bold"} fontSize={"18px"}>
          {currentTreatment.title}
        </Text>
        {currentTreatment.patient.id && (
          <ScrollableSelectTab
            tabs={currentTreatment.appointments}
            otherProps={{
              patientId: currentTreatment.patient.id,
              treatmentId: currentTreatment.id,
            }}
          />
        )}
        <Flex direction={"row"} mb={4} wrap={"wrap"}>
          <EditableInput
            value={currentTreatment.patientTitle}
            onChange={(value, setSubmitting, onClose) =>
              handleTreatmentPatientTitleChange(
                "patientTitle",
                value,
                setSubmitting,
                onClose
              )
            }
            type="patientTitle"
          />
        </Flex>

        <Flex gap={4} direction={"row"} mb={4} wrap={"wrap"}>
          <EditableInput
            type="internalNote"
            value={currentAppointment.internalNote}
            onChange={(value, setSubmitting, onClose) =>
              handleEditableInputChange(
                "internalNote",
                value,
                setSubmitting,
                onClose
              )
            }
          />
          <EditableInput
            type="secondaryInternalNote"
            value={currentAppointment.secondaryInternalNote}
            onChange={(value, setSubmitting, onClose) =>
              handleEditableInputChange(
                "secondaryInternalNote",
                value,
                setSubmitting,
                onClose
              )
            }
          />
          <EditableInput
            type="osteoAdvice"
            value={currentAppointment.osteoAdvice}
            onChange={(value, setSubmitting, onClose) =>
              handleEditableInputChange(
                "osteoAdvice",
                value,
                setSubmitting,
                onClose
              )
            }
          />
          {selectedTeleconsultation && selectedTeleconsultation.privateNote && (
            <EditableInput
              type="teleconsultationPrivateNote"
              value={selectedTeleconsultation.privateNote}
            />
          )}
        </Flex>

        {reviews && reviews.length > 0 && (
          <PatientReviews reviews={reviews} patient={patient} />
        )}
        {currentAppointment.trainingPlaylist?.exercices?.length !== 0 &&
          currentTreatment.patient.id && (
            <Training
              appointment={currentAppointment}
              patientId={currentTreatment.patient.id.toString()}
              setAppointment={setCurrentAppointment}
              reviews={
                reviews?.filter(
                  (review) => review.type === "pain"
                ) as UserPainFeedbackType[]
              }
            />
          )}

        <AppointmentMedias
          selectedMedias={currentAppointment.explorerMedias ?? []}
          appointment={currentAppointment}
          patientId={currentTreatment.patient.id?.toString()}
        />

        <AppointmentEducationalContents
          selectedEducationalContents={
            currentAppointment.educationalContents ?? []
          }
          appointment={currentAppointment}
          patientId={currentTreatment.patient.id?.toString()}
        />

        <Flex direction={"column"} w={"full"}>
          <Stats patient={patient} isInPatientKinePage={true} />
        </Flex>
      </Flex>
      {currentTreatment.patient.id && (
        <RightTabKine
          patient={currentTreatment.patient}
          treatments={treatments}
          currentTreatment={currentTreatment}
          setSelectedTeleconsultation={setSelectedTeleconsultation}
        />
      )}
    </Flex>
  )
}

export default TreatmentsContainer
