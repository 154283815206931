import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { useAppContext } from "AppContext"
import AButton from "components/AButton"
import UpgradeTeleconsultationModal from "pages/Teleconsultation/UpgradeTeleconsultationModal"
import userAdminsCenterGroupInCurrentScope from "utils/currentScope/userAdminsCenterGroupInCurrentScope"

const NoTeleconsultationAccess = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { user, currentScope } = useAppContext()
  const isCenterAdmin = userAdminsCenterGroupInCurrentScope(user, currentScope)

  const handleClick = () => {
    if (currentScope?.type === "center" && !isCenterAdmin) {
      return
    } else {
      onOpen()
    }
  }
  return (
    <Flex direction="column" gap="24px">
      <Heading as="h1" fontSize={28} color="tertiary.500">
        Téléconsultations
      </Heading>
      <Center marginTop={"130px"}>
        <Flex
          bg="primary.250"
          p="24px"
          borderRadius="8px"
          padding="48px 16px 48px 48px"
          gap="60px"
          alignItems={"center"}
        >
          <Flex direction="column" gap="16px" fontSize="18px">
            <Text color="primary.700" fontWeight={"bold"}>
              Gagnez du temps avec la téléconsultation
            </Text>
            <Text mb="30px" maxW="420px">
              Mettez en place un suivi en téléconsultation pour ajuster le
              traitement de vos patients et libérez vous du temps
            </Text>
            <Box>
              <AButton
                text="Activer la téléconsultation"
                w="fit-content"
                onClick={handleClick}
                isDisabled={currentScope?.type === "center" && !isCenterAdmin}
              />
              {currentScope?.type === "center" && !isCenterAdmin && (
                <Text fontSize={"14px"} color="primary.700" mt="2">
                  Contacter l'administrateur du centre pour activer cette option
                </Text>
              )}
            </Box>
          </Flex>
          <Flex flexDir={"column"} gap="16px" alignItems={"center"}>
            <Image src="/super_hero.png" w="126px" />
            <Text
              px={8}
              py={0}
              bg="#FF9C00"
              fontSize={"22px"}
              fontWeight="bold"
            >
              350€ remboursé
            </Text>
            <Text maxW={"258px"} textAlign={"center"}>
              Bénéficiez d’un remboursement d’aide{" "}
              <Link
                textDecoration={"underline"}
                href="https://www.andrewapp.fr/blog/profitez-du-remboursement-de-350-eur-sur-andrew-grace-au-dispositif-fami"
                target={"_blank"}
              >
                via le dispositif FAMI
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Center>
      <UpgradeTeleconsultationModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}

export default NoTeleconsultationAccess
