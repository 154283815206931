import { Flex, Heading, Text } from "@chakra-ui/react"
import { GlobalStatsType } from "api/stats"
import Chart from "components/Stats/Chart"
import SingleData from "components/Stats/SingleData"
import getChartOptions from "utils/chart/getChartOptions"
import getMonthsAbrev from "utils/chart/getMonthsAbrev"
import getChartData from "../../utils/chart/getChartData"

interface GlobalStatsProps {
  globalStats: GlobalStatsType | undefined
}
const GlobalStats = ({ globalStats }: GlobalStatsProps) => {
  if (!globalStats) {
    return <Flex>Aucune donnée</Flex>
  }

  const monthAbreviationsAppointments = getMonthsAbrev(
    globalStats?.appointmentsByMonth || []
  )

  const seriesAppointmentsByMonth = getChartData(
    globalStats.appointmentsByMonth,
    ["Consultations"]
  )
  const optionsAppointmentsByMonth = getChartOptions(
    monthAbreviationsAppointments,
    ["#f5a087"]
  )

  return (
    <Flex direction={"column"} wrap={"wrap"} w={"full"}>
      <Heading as="h2" mb={2} fontSize={24} color="tertiary.500">
        Statistiques générales de l'utilisation de l'application Andrew
      </Heading>
      <Text mb={6}>Statistiques mises à jour toutes les 12 heures.</Text>
      <Flex direction={"column"} gap={6} w={"full"}>
        <Flex wrap={"wrap"} w={"full"} gap={6} justify={"flex-start"}>
          <SingleData
            value={globalStats.activePatients}
            label={"Nombre de patients actifs après la\nconsultation"}
            unit={"%"}
          />
          <SingleData
            // value={globalStats.averageEngagmentTime}
            value={47}
            label={"Temps moyen de l’utilisation de\nl’application par patient"}
            unit={"min"}
          />
          <SingleData
            value={globalStats.patientCompletingExosPercentage}
            label={"Part des patients finissant leurs\nexercices"}
            unit={"%"}
          />
        </Flex>
        <Flex wrap={"wrap"} w={"full"} gap={6} justify={"flex-start"}>
          <SingleData
            value={globalStats.averageScreenViewByPatient}
            label={"Moyenne du nombre d'écrans\nAndrew vus par patient"}
          />
          <SingleData
            value={globalStats.totalCountOfRatingOsteo}
            label={
              "Nombre de clics sur mettre 5 étoiles\n à votre praticien depuis février 2024"
            }
          />
          <SingleData
            value={globalStats.globalUserHealthCheckupCount}
            label={"Reponses aux questionnaires\nsanté"}
          />
        </Flex>

        <Flex wrap={"wrap"} w={"full"} gap={6} justify={"flex-start"}>
          <Flex gap={6} flexDir={"column"}>
            <SingleData
              value={globalStats.satisfactionRate.value}
              decimals={2}
              label={`Taux de satisfaction sur ${globalStats.satisfactionRate.count}\nvotes depuis le 1er juillet`}
              unit={"%"}
            />
            <SingleData
              value={globalStats.mapViewCount.value}
              label={`Nombre de passage total sur notre carte`}
              unit={""}
            />
          </Flex>

          <Flex gap={6}>
            <Chart
              title="Nombre de consultations"
              options={optionsAppointmentsByMonth}
              series={seriesAppointmentsByMonth}
              chartWidth={620}
              showTotalData={true}
              labelColor={"#ef7560"}
            />
          </Flex>
        </Flex>
        <Flex wrap={"wrap"} w={"full"} gap={6} justify={"flex-start"}>
          <SingleData
            value={globalStats.osteoNumber}
            label={`Nombre de thérapeutes`}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
export default GlobalStats
