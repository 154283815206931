import { useMutation } from "@tanstack/react-query"
import {
  SelectedDiscoverType,
  SelectedExerciceType,
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
} from "api/appointmentReason"
import { newAppointment } from "api/appointments"
import { EducationalContentType } from "api/educationalContent"
import { getAllJobs, JobType } from "api/job"
import { BabyMediaTag } from "api/media"
import { getTreatmentById } from "api/treatment"
import { useAppContext } from "AppContext"
import { format } from "date-fns"
import useToast from "hooks/useToast"
import { babyMediaTags } from "pages/EditAppointment/EditExplorerAppointment"
import EducationalContent from "pages/NewAppointment/components/EducationalContent/EducationalContent"
import SelectDiscovers from "pages/NewAppointment/components/SelectDiscovers/SelectDiscovers"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import {
  PatientAppointmentType,
  PatientType,
  TraininScheduleType,
} from "../../api/patient"
import useJobIsKineOrPodo from "../../hooks/useIsJobKineOrPodo"
import SelectExercices from "./components/SelectExercices/SelectExercices"
import SelectReason from "./components/SelectReason/SelectReason"
import SelectSchedule, {
  ReminderDataType,
} from "./components/SelectSchedule/SelectSchedule"

type OnboardingProps = {
  isOnboarding?: boolean
  patientIdFromProps?: number
  treatmentIdFromProps?: string
  onAppointmentCreated?: () => void
}

const NewAppointment = ({
  patientIdFromProps = undefined,
  treatmentIdFromProps = undefined,
  onAppointmentCreated,
  isOnboarding = false,
}: OnboardingProps) => {
  const [step, setStep] = useState(0)
  const [patient, setPatient] = React.useState<PatientType | undefined>(
    undefined
  )
  const [advice, setAdvice] = React.useState<string | null>(null)
  const [internalNote, setInternalNote] = React.useState<string | null>(null)
  const [secondaryInternalNote, setSecondaryInternalNote] = React.useState<
    string | null
  >(null)
  const [pain, setPain] = React.useState<undefined | number>(undefined)
  const [frequency, setFrequency] = React.useState<undefined | number>(
    undefined
  )
  const [mobility, setMobility] = React.useState<undefined | number>(undefined)
  const [stress, setStress] = React.useState<undefined | number>(undefined)
  const [reasonType, setReasonType] = useState<"zones" | "pathologies" | null>(
    "zones"
  )
  const [reason, setReason] = useState<ZonePathologieType>()
  const [selectedExercices, setSelectedExercices] = useState<
    SelectedExerciceType[]
  >([])
  const [selectedUploadedExercices, setSelectedUploadedExercices] = useState<
    SelectedUploadedExerciceType[]
  >([])
  const [selectedVideoOrder, setSelectedVideoOrder] = useState<
    SelectedVideoOrderType[]
  >([])
  const [selectedDiscovers, setSelectedDiscovers] = useState<
    SelectedDiscoverType[]
  >([])
  const [trainingSchedule, setTrainingSchedule] = React.useState<
    TraininScheduleType[]
  >(patient?.trainingSchedule || [])
  const [isBabySwitchOn, setIsBabySwitchOn] = useState(false)
  const [selectedBabyMediaTag, setSelectedBabyMediaTag] =
    useState<BabyMediaTag | null>(null)
  const [educationalContent, setEducationalContent] = useState<
    EducationalContentType[]
  >([])
  const jobsMutation = useMutation(getAllJobs)

  const { patientId: patientIdFromParams, treatmentId: treatmentIdFromParams } =
    useParams()

  const treatmentId = useMemo(() => {
    if (treatmentIdFromProps) {
      return treatmentIdFromProps
    } else {
      return treatmentIdFromParams
    }
  }, [treatmentIdFromProps, treatmentIdFromParams])

  const patientId = useMemo(() => {
    if (patientIdFromProps) {
      return String(patientIdFromProps)
    } else {
      return patientIdFromParams
    }
  }, [patientIdFromProps, patientIdFromParams])

  const isJobKineOrPodo = useJobIsKineOrPodo()

  const [treatmentTitle, setTreatmentTitle] = useState<string>("")
  const [patientTitle, setPatientTitle] = useState<string>("")
  useEffect(() => {
    setTrainingSchedule(patient?.trainingSchedule || [])
  }, [patient])

  const setReasonAndReset = (newReasonType: "zones" | "pathologies" | null) => {
    setReasonType(newReasonType)
  }

  const { user, currentScope } = useAppContext()
  const newAppointmentExtraParams = getCurrentScopeParams(currentScope)
  const newAppointmentMutation = useMutation(newAppointment)
  const getTreatmentMutation = useMutation(getTreatmentById)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()
  const [jobs, setJobs] = useState<JobType[]>([])

  const handleNewAppointment = async (
    reminderData?: ReminderDataType | undefined
  ) => {
    setIsSubmitting(true)
    const dateInTwoMonths = new Date()
    dateInTwoMonths.setMonth(dateInTwoMonths.getMonth() + 2)

    const allDefined =
      pain !== undefined &&
      stress !== undefined &&
      frequency !== undefined &&
      mobility !== undefined

    if (!user || !patientId) {
      setIsSubmitting(false)
      return
    }

    const checkup = allDefined
      ? {
          patient: Number(patientId),
          pain,
          stress,
          frequency,
          mobility,
        }
      : undefined

    const playlist = {
      author: user?.osteo.id,
      expiry: format(dateInTwoMonths, "yyyy-MM-dd"),
    }

    const explorerMedias =
      selectedDiscovers.length === 0
        ? null
        : selectedDiscovers.map(({ id }) => id)

    const treatment =
      treatmentId !== undefined
        ? treatmentId === "0"
          ? { title: treatmentTitle, patientTitle: patientTitle }
          : {
              id: Number(treatmentId),
              title: treatmentTitle,
              patientTitle: patientTitle,
            }
        : undefined

    const jobs = await jobsMutation.mutateAsync(undefined, {
      onSuccess: (jobs) => {
        setJobs(jobs)
      },
    })

    if (jobs.length === 0 || !user.osteo || !user.osteo.jobs) {
      setIsSubmitting(false)
      return
    }

    const currentJob = jobs.find(
      (job) =>
        job.key === user.osteo?.jobs?.[0]?.key ??
        jobs.find((job) => job.key === "osteo")?.key
    )

    if (!currentJob) {
      setIsSubmitting(false)
      return
    }

    const videoExercices = selectedExercices.map((exercice) => ({
      videoGroup: exercice.videoGroup,
      osteoSelection: exercice.id,
      series: isJobKineOrPodo ? exercice.series : null,
      repetitions: isJobKineOrPodo ? exercice.repetitions : null,
      restDuration: isJobKineOrPodo ? exercice.restDuration : null,
      type: isJobKineOrPodo ? exercice.type : null,
      side: isJobKineOrPodo ? exercice.side : null,
      weight: isJobKineOrPodo ? exercice.weight : null,
      duration: exercice.duration,
      recommendation: exercice.recommendation,
    }))
    const videoUploadedExercices = selectedUploadedExercices.map(
      (exercice) => ({
        series: isJobKineOrPodo ? exercice.series : null,
        repetitions: isJobKineOrPodo ? exercice.repetitions : null,
        restDuration: isJobKineOrPodo ? exercice.restDuration : null,
        type: isJobKineOrPodo ? exercice.type : null,
        weight: isJobKineOrPodo ? exercice.weight : null,
        trainingVideoUploaded: exercice.id,
        duration: exercice.duration,
        recommendation: exercice.recommendation,
      })
    )

    newAppointmentMutation.mutate(
      {
        playlist,
        appointment: {
          osteo: user?.osteo.id,
          ...newAppointmentExtraParams,
          patient: Number(patientId),
          osteoAdvice:
            advice ||
            `Pensez à faire régulièrement vos exercices pour maximiser les effets de votre consultation.`,
          meetingDate: format(new Date(), "yyyy-MM-dd"),
          internalNote,
          secondaryInternalNote,
          explorerMedias,
          reminderScheduledDate: reminderData ? reminderData.dateTime : null,
          reminderMessage: reminderData ? reminderData.text : null,
          educationalContents:
            educationalContent.length !== 0 ? educationalContent : undefined,
          job: currentJob,
          patientTitle: treatment ? undefined : patientTitle,
        },
        videoExercices,
        videoUploadedExercices,
        videoOrder: selectedVideoOrder,
        checkup,
        trainingSchedule,
        patientId: Number(patientId),
        treatment,
      },
      {
        onSuccess: (appointment) => {
          toast({
            status: "success",
            title: "Consultation ajoutée avec succès",
          })
          //During on boarding, just create the appointment, and signal to go to next step
          if (isOnboarding && onAppointmentCreated) {
            onAppointmentCreated()
            return
          }
          if (
            appointment.job.key === "kine" ||
            appointment.job.key === "podo"
          ) {
            navigate(
              `/patients/${patientId}/treatment/${appointment.treatment.id}/appointment/${appointment.id}`
            )
          } else {
            navigate(`/patients/${patientId}/appointments/${appointment.id}`)
          }

          setIsSubmitting(false)
        },
        onError: () => {
          toast({
            status: "error",
            title: "Une erreur est survenue",
          })
          setIsSubmitting(false)
        },
      }
    )
  }
  useEffect(() => {
    if (treatmentId && treatmentId !== "0") {
      getTreatmentMutation.mutate(Number(treatmentId), {
        onSuccess: (treatment: any) => {
          setTreatmentTitle(treatment.title)
          setPatientTitle(treatment.patientTitle)
          const appointmentsNumber = treatment.appointments.length - 1
          const appointment: PatientAppointmentType =
            treatment.appointments[appointmentsNumber]
          setAdvice(appointment.osteoAdvice)
          setInternalNote(appointment.internalNote)
          setSecondaryInternalNote(appointment.secondaryInternalNote)

          const lastCheckupNumber = appointment.patient.checkups.length - 1
          const lastCheckup = appointment.patient.checkups[lastCheckupNumber]
          if (lastCheckup) {
            setPain(lastCheckup.pain)
            setStress(lastCheckup.stress)
            setFrequency(lastCheckup.frequency)
            setMobility(lastCheckup.mobility)
          }

          setSelectedDiscovers(appointment.explorerMedias || [])
          setEducationalContent(appointment.educationalContents || [])
          if (!appointment.trainingPlaylist) return
          const videoOrder: (SelectedVideoOrderType | null)[] =
            appointment.trainingPlaylist.exercices
              .map((ex) => {
                if (ex.osteoSelection) {
                  return {
                    id: ex.osteoSelection.id,
                    type: "video",
                  } as SelectedVideoOrderType
                }
                if (ex.trainingVideoUploaded) {
                  return {
                    id: ex.trainingVideoUploaded.id,
                    type: "uploaded_video",
                  } as SelectedVideoOrderType
                }
                return null
              })
              .filter((ex) => ex)
          setSelectedVideoOrder(
            videoOrder.filter((ex) => ex) as SelectedVideoOrderType[]
          )
          const previousAppointmentExercices =
            appointment.trainingPlaylist.exercices
              .filter((ex) => ex.osteoSelection && ex !== undefined)
              .map((exercice) => ({
                id: exercice!.osteoSelection!.id,
                level: exercice!.osteoSelection!.level,
                limitedMode: exercice!.osteoSelection!.limitedMode,
                title: exercice!.osteoSelection!.title,
                preview: exercice!.osteoSelection!.preview,
                defaultDuration: exercice!.duration ?? undefined,
                group: exercice!.osteoSelection!.group,
                videoGroup: exercice!.osteoSelection!.group[0].id,
                bunnyCDN: exercice!.osteoSelection!.bunnyCDN,
                isAsymmetrical: exercice!.osteoSelection!.isAsymmetrical,
                config: exercice!.osteoSelection!.config,
                duration: exercice!.duration,
                series: exercice!.series,
                repetitions: exercice!.repetitions,
                restDuration: exercice!.restDuration,
                type: exercice!.type,
                side: exercice!.side,
                canUseWeight: exercice!.weight !== null,
                weight: exercice!.weight,
                recommendation: exercice!.recommendation,
              }))
          setSelectedExercices(previousAppointmentExercices)
          const previousAppointmentUploadedExercices =
            appointment.trainingPlaylist.exercices
              .filter((ex) => ex.trainingVideoUploaded && ex !== undefined)
              .map((exercice) => ({
                id: exercice!.trainingVideoUploaded!.id,
                level: exercice!.trainingVideoUploaded!.level,
                title: exercice!.trainingVideoUploaded!.title,
                preview: exercice!.trainingVideoUploaded!.bunnyCDN.preview,
                defaultDuration: exercice!.duration ?? undefined,
                bunnyCDN: exercice!.trainingVideoUploaded!.bunnyCDN,
                isAsymmetrical: false,
                config: exercice!.trainingVideoUploaded!.config,
                duration: exercice!.duration,
                series: exercice!.series,
                repetitions: exercice!.repetitions,
                restDuration: exercice!.restDuration,
                type: exercice!.type,
                side: exercice!.side,
                canUseWeight: exercice!.weight !== null,
                weight: exercice!.weight,
                recommendation: exercice!.recommendation,
              }))
          setSelectedUploadedExercices(previousAppointmentUploadedExercices)
        },
        onError: (e) => {
          toast({
            status: "error",
            title: "Une erreur est survenue",
          })
          navigate(`/`)
        },
      })
    }
  }, [treatmentId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])
  if (step === 0)
    return (
      <SelectReason
        isOnboarding={isOnboarding}
        patientId={patientId}
        patient={patient}
        setPatient={setPatient}
        setStep={setStep}
        advice={advice}
        setAdvice={setAdvice}
        pain={pain}
        setPain={setPain}
        stress={stress}
        setStress={setStress}
        frequency={frequency}
        setFrequency={setFrequency}
        mobility={mobility}
        setMobility={setMobility}
        internalNote={internalNote}
        setInternalNote={setInternalNote}
        secondaryInternalNote={secondaryInternalNote}
        setSecondaryInternalNote={setSecondaryInternalNote}
        isBabySwitchOn={isBabySwitchOn}
        setIsBabySwitchOn={setIsBabySwitchOn}
        treatmentTitle={treatmentTitle}
        setTreatmentTitle={setTreatmentTitle}
        patientTitle={patientTitle}
        setPatientTitle={setPatientTitle}
        treatmentId={treatmentId}
      />
    )

  if (step === 1)
    return (
      <SelectExercices
        setStep={setStep}
        selectedExercices={selectedExercices}
        setSelectedExercices={setSelectedExercices}
        selectedUploadedExercices={selectedUploadedExercices}
        setSelectedUploadedExercices={setSelectedUploadedExercices}
        selectedVideoOrder={selectedVideoOrder}
        setSelectedVideoOrder={setSelectedVideoOrder}
        reason={reason}
        reasonType={reasonType}
        setReasonType={setReasonAndReset}
        setReason={setReason}
        isBabySwitchOn={isBabySwitchOn}
        setIsBabySwitchOn={setIsBabySwitchOn}
        selectedBabyMediaTag={selectedBabyMediaTag}
        setSelectedBabyMediaTag={setSelectedBabyMediaTag}
        babyMediaTags={babyMediaTags}
        isForKineOrPodo={isJobKineOrPodo}
        treatmentTitle={treatmentTitle}
        treatmentId={treatmentId}
        patientId={patient?.id}
      />
    )

  if (step === 2)
    return (
      <EducationalContent
        setStep={setStep}
        selectedEducationalContent={educationalContent}
        setSelectedEducationalContent={setEducationalContent}
        isSubmitting={isSubmitting}
      />
    )

  if (step === 3)
    return (
      <SelectDiscovers
        setStep={setStep}
        reason={reasonType}
        selectedDiscovers={selectedDiscovers}
        setSelectedDiscovers={setSelectedDiscovers}
        isBabySwitchOn={isBabySwitchOn}
        selectedBabyMediaTag={selectedBabyMediaTag}
        setSelectedBabyMediaTag={setSelectedBabyMediaTag}
        babyMediaTags={babyMediaTags}
        handleNewAppointment={handleNewAppointment}
        isSubmitting={isSubmitting}
      />
    )

  return (
    <SelectSchedule
      setStep={setStep}
      trainingSchedule={trainingSchedule}
      setTrainingSchedule={setTrainingSchedule}
      handleNewAppointment={handleNewAppointment}
      isSubmitting={isSubmitting}
      user={user}
    />
  )
}

export default NewAppointment
