import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "AppContext"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import InternalNotes from "pages/NewAppointment/components/SelectReason/components/InternalNotes"
import React, { ChangeEvent, useEffect, useId, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import { getPatient, PatientType } from "../../../../api/patient"
import arrowBack from "../../../../assets/arrow_back.svg"
import AButton from "../../../../components/AButton"
import Coordinates from "./components/Coordinates"
import PatientAdvice from "./components/PatientAdvice"
import Questionnaire from "./components/Questionnaire/Questionnaire"

interface IProps {
  isOnboarding: boolean
  patientId: string | undefined
  patient: PatientType | undefined
  setPatient: React.Dispatch<React.SetStateAction<PatientType | undefined>>
  setStep: React.Dispatch<React.SetStateAction<number>>
  advice: string | null
  setAdvice: React.Dispatch<React.SetStateAction<string | null>>
  pain: number | undefined
  setPain: React.Dispatch<React.SetStateAction<number | undefined>>
  stress: number | undefined
  setStress: React.Dispatch<React.SetStateAction<number | undefined>>
  frequency: number | undefined
  setFrequency: React.Dispatch<React.SetStateAction<number | undefined>>
  mobility: number | undefined
  setMobility: React.Dispatch<React.SetStateAction<number | undefined>>
  internalNote: string | null
  setInternalNote: React.Dispatch<React.SetStateAction<string | null>>
  secondaryInternalNote: string | null
  setSecondaryInternalNote: React.Dispatch<React.SetStateAction<string | null>>
  isBabySwitchOn: boolean
  setIsBabySwitchOn: React.Dispatch<React.SetStateAction<boolean>>
  treatmentTitle: string
  setTreatmentTitle: React.Dispatch<React.SetStateAction<string>>
  patientTitle: string
  setPatientTitle: React.Dispatch<React.SetStateAction<string>>
  treatmentId: string | undefined
}

const SelectReason: React.FC<IProps> = ({
  isOnboarding,
  patientId,
  patient,
  setPatient,
  setStep,
  advice,
  setAdvice,
  pain,
  setPain,
  stress,
  setStress,
  frequency,
  setFrequency,
  mobility,
  setMobility,
  internalNote,
  setInternalNote,
  secondaryInternalNote,
  setSecondaryInternalNote,
  isBabySwitchOn,
  setIsBabySwitchOn,
  treatmentTitle,
  setTreatmentTitle,
  patientTitle,
  setPatientTitle,
  treatmentId,
}) => {
  const { currentScope } = useAppContext()
  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()
  const getPatientParams = { patientId, ...getCurrentScopeParams(currentScope) }
  const switchStatusId = useId()
  const {
    onOpen: onBabyPopoverOpen,
    onClose: onBabyPopoverClose,
    isOpen: isBabyPopoverOpen,
  } = useDisclosure()
  const isAppointmentForKineOrPodo = treatmentId !== undefined

  const onBabySwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsBabySwitchOn(e.target.checked)
    if (isBabySwitchOn) {
      onBabyPopoverClose()
      return
    }
    if (!isBabySwitchOn && !isBabyPopoverOpen) {
      onBabyPopoverOpen()
    }
  }

  /**
   * Screen title when creating a new appointment
   */
  const newAppointmentTitle = useMemo(() => {
    if (isOnboarding) {
      return "Créons votre première consultation"
    } else {
      return "Nouvelle consultation"
    }
  }, [isOnboarding])

  /**
   * Screen title when creating a new treatment
   */
  const newTreatmentTitle = useMemo(() => {
    if (isOnboarding) {
      return "Créons votre premier traitement"
    } else {
      return "Nouveau traitement"
    }
  }, [isOnboarding])

  /**
   * Define screen title based on the type of appointment and treatment
   */
  const title = useMemo(() => {
    if (!isAppointmentForKineOrPodo) {
      return newAppointmentTitle
    }
    //If appointment, check if we're creating a new treatment, or a new appointment into an existing treatment
    if (treatmentId && treatmentId !== "0") {
      return newAppointmentTitle
    } else {
      return newTreatmentTitle
    }
  }, [isOnboarding, treatmentId, isAppointmentForKineOrPodo])

  useEffect(() => {
    if (!isOnboarding) return

    setPatientTitle("Mon premier programme")

    if (isAppointmentForKineOrPodo) {
      setTreatmentTitle("Lombalgie")
    }
  }, [isAppointmentForKineOrPodo, isOnboarding])

  useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(getPatientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error: any) =>
        retryPatientNoAccessRight(failureCount, error) &&
        retryCollaboratorUnauthorized(failureCount, error),
      onSuccess: (data) => {
        setPatient(data)
      },
      onError: (error: any) => {
        onCollaboratorUnauthorized(error)
        onPatientNoAccessRight(error)
      },
    }
  )

  const handleNextStepForReason = () => {
    if (isBabySwitchOn) {
      setPain(0)
      setStress(0)
      setMobility(0)
      setFrequency(0)
      setStep(3)
    } else setStep(1)
  }

  const isNextStepButtonDisabled =
    (treatmentId !== undefined && treatmentTitle === "") || patientTitle === ""

  const navigate = useNavigate()

  const handleReturn = () => {
    if (window.history.length > 1) {
      navigate(-1)
    } else {
      navigate("/")
    }
  }

  if (!patient) return null
  return (
    <Box flex={1} p="16px" backgroundColor="primary.100">
      {!isOnboarding && (
        <Button
          p="8px"
          bgColor="primary.200"
          w="fit-content"
          display="block"
          rounded="9px"
          onClick={handleReturn}
        >
          <Image src={arrowBack} width="24px" height="24px" />
        </Button>
      )}

      <Flex
        direction={"row"}
        width="full"
        gap={4}
        alignItems={"start"}
        mb={6}
        justifyContent="space-between"
        mt={7}
      >
        <Coordinates patient={patient} />
        <Flex direction="column" gap="32px" maxW={640} w="full">
          <Flex w="full" justifyContent="space-between" alignItems="center">
            {!isAppointmentForKineOrPodo ? (
              <Flex direction={"column"} gap={"8px"} w="full">
                <Flex
                  w="full"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Heading as="h1" fontSize={32} color="common.700">
                    {title}
                  </Heading>
                  <Popover
                    isOpen={isBabyPopoverOpen}
                    onOpen={onBabyPopoverOpen}
                    onClose={onBabyPopoverClose}
                    placement="bottom"
                  >
                    <PopoverTrigger>
                      <Switch
                        as="div"
                        id={switchStatusId}
                        whiteSpace="nowrap"
                        colorScheme="orange"
                        isChecked={isBabySwitchOn}
                        onChange={onBabySwitchChange}
                        height="fit-content"
                        bg="white"
                        py="12px"
                        px="16px"
                        display="flex"
                        alignItems="center"
                        borderRadius="xl"
                        fontWeight={"bold"}
                      >
                        Nourrisson
                      </Switch>
                    </PopoverTrigger>
                    <PopoverContent
                      backgroundColor={"common.800"}
                      px={2}
                      py={4}
                      maxW={240}
                      mt={5}
                      ml={44}
                    >
                      <PopoverBody>
                        <Flex direction={"column"} alignItems={"start"} gap={4}>
                          <Text color={"white"}>
                            La consultation nourrisson est activée : les vidéos
                            sont adaptées à ce type de consultation
                          </Text>
                          <Button
                            variant="link"
                            textDecoration={"underline"}
                            color={"white"}
                            onClick={onBabyPopoverClose}
                          >
                            C'est compris
                          </Button>
                        </Flex>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Flex>
                <Box>
                  <Text as="label" htmlFor="patientTitleOsteo" fontWeight={700}>
                    Titre du programme
                  </Text>
                  <Input
                    id="patientTitleOsteo"
                    placeholder="Visible par le patient"
                    border="none"
                    paddingX={2}
                    fontSize="16px"
                    fontWeight={700}
                    my={4}
                    bg="white"
                    _focusVisible={{ border: "none" }}
                    onChange={(evt) => setPatientTitle(evt.target.value)}
                    value={patientTitle}
                    autoFocus
                    maxLength={25}
                  />
                </Box>
              </Flex>
            ) : (
              <Flex direction={"column"} gap={"8px"}>
                <Heading>{title}</Heading>
                <Box>
                  <Text as="label" htmlFor="treatmentTitle" fontWeight={700}>
                    Pathologie
                  </Text>
                  <Input
                    id="treatmentTitle"
                    placeholder="Non visible par le patient"
                    border="none"
                    paddingX={2}
                    fontSize="16px"
                    fontWeight={700}
                    bg="white"
                    _focusVisible={{ border: "none" }}
                    onChange={(evt) => setTreatmentTitle(evt.target.value)}
                    value={treatmentTitle}
                    autoFocus
                  />
                </Box>
                <Box>
                  <Text as="label" htmlFor="patientTitleKine" fontWeight={700}>
                    Titre du programme
                  </Text>
                  <Input
                    id="patientTitleKine"
                    placeholder="Visible par le patient"
                    border="none"
                    paddingX={2}
                    fontSize="16px"
                    fontWeight={700}
                    bg="white"
                    _focusVisible={{ border: "none" }}
                    onChange={(evt) => setPatientTitle(evt.target.value)}
                    value={patientTitle}
                    maxLength={25}
                  />
                </Box>
              </Flex>
            )}
          </Flex>

          {!isBabySwitchOn && (
            <Questionnaire
              pain={pain}
              setPain={setPain}
              stress={stress}
              setStress={setStress}
              frequency={frequency}
              setFrequency={setFrequency}
              mobility={mobility}
              setMobility={setMobility}
            />
          )}
          <Box mb={24}>
            <PatientAdvice advice={advice} setAdvice={setAdvice} />
          </Box>
        </Flex>
        <InternalNotes
          internalNote={internalNote}
          setInternalNote={setInternalNote}
          secondaryInternalNote={secondaryInternalNote}
          setSecondaryInternalNote={setSecondaryInternalNote}
        />
      </Flex>

      <Flex
        direction={"column"}
        align={"center"}
        justify="center"
        w={"full"}
        gap={2}
        fontFamily="Montserrat"
        bg="primary.200"
        py={6}
        position="fixed"
        bottom={0}
        left={0}
        zIndex={100}
      >
        {treatmentId !== undefined && treatmentTitle === "" && (
          <Text color="primary.800" fontSize="14px">
            Une pathologie est requise
          </Text>
        )}
        {patientTitle === "" && (
          <Text color="primary.800" fontSize="14px">
            Un titre de programme est requis
          </Text>
        )}
        <AButton
          text="Suivant"
          px={120}
          py={3}
          onClick={handleNextStepForReason}
          isDisabled={isNextStepButtonDisabled}
        />
      </Flex>
    </Box>
  )
}

export default SelectReason
