import { Box } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "AppContext"
import { JobType } from "api/job"
import { getOneModal } from "api/modal"
import { getAllStats } from "api/stats"
import ACooptionModal from "components/ACooptionModal"
import Statistics from "components/Stats/Statistics"
import { SESSION_ID_KEY } from "constants/subscription"
import useCooptionModalDisclosure from "hooks/useCooptionModalDisclosure"
import useNewFeaturesModalDisclosure from "hooks/useNewFeaturesModalDisclosure"
import useShouldDisplayOnboarding from "pages/Onboarding/useShouldDisplayOnboarding"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import ANewFeaturesModal from "../../components/ANewFeaturesModal"

const Dashboard = () => {
  const { data: stats } = useQuery(["getAllStats"], getAllStats)
  const navigate = useNavigate()

  const { shouldDisplayOnboarding } = useShouldDisplayOnboarding()

  const [searchParams] = useSearchParams()
  const sessionId = searchParams.get(SESSION_ID_KEY)

  const { isOpen: isCooptionModalOpen, onClose: onCooptionModalClose } =
    useCooptionModalDisclosure()

  const newFeaturesModalQuery = useQuery(["getOneModal", 1], () =>
    getOneModal(1)
  )

  const newFeaturesModalName = newFeaturesModalQuery.isSuccess
    ? newFeaturesModalQuery.data.name
    : undefined
  const newFeaturesModalIsDiplayed = newFeaturesModalQuery.isSuccess
    ? newFeaturesModalQuery.data.isDisplayed
    : false

  const newFeaturesModalJobs = newFeaturesModalQuery.isSuccess
    ? newFeaturesModalQuery.data.jobs
    : undefined

  const { user } = useAppContext()

  const userJobs = user?.osteo.jobs

  const userJobsIds = userJobs?.map((job) => job.id)
  const newFeaturesModalJobsIds = newFeaturesModalJobs?.map(
    (job: JobType) => job.id
  )
  const hasUserJob = userJobsIds?.some((jobId) =>
    newFeaturesModalJobsIds?.includes(jobId)
  )

  useEffect(() => {
    if (shouldDisplayOnboarding === true) {
      if (sessionId) {
        navigate(`/onboarding?session_id=${sessionId}`)
      } else {
        navigate("/onboarding")
      }
    }
  }, [shouldDisplayOnboarding, sessionId])

  const { isOpen: isNewFeaturesModalOpen, onClose: onNewFeaturesModalClose } =
    useNewFeaturesModalDisclosure(
      newFeaturesModalName,
      newFeaturesModalIsDiplayed,
      isCooptionModalOpen,
      hasUserJob
    )

  return (
    <Box>
      <Statistics stats={stats} />
      <ACooptionModal
        isOpen={isCooptionModalOpen}
        onClose={onCooptionModalClose}
      />
      <ANewFeaturesModal
        isOpen={isNewFeaturesModalOpen}
        onClose={onNewFeaturesModalClose}
        queryData={{
          data: newFeaturesModalQuery.data,
          isLoading: newFeaturesModalQuery.isLoading,
          isError: newFeaturesModalQuery.isError,
        }}
      />
    </Box>
  )
}

export default Dashboard
