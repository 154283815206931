import {
  Box,
  Flex,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import {
  getAllTeleconsultation,
  TeleconsultationType,
} from "api/teleconsultation"
import { useState } from "react"
import { useAppContext } from "../../AppContext"
import { useQuery } from "@tanstack/react-query"
import TeleconsultationList from "components/Teleconsultation/TeleconsultationList"
import AButton from "components/AButton"
import { AddIcon } from "@chakra-ui/icons"
import TeleconsultationModal from "components/Teleconsultation/TeleconsultationModal"
import StripeConnectModal from "components/StripeConnect/StripeConnectModal"
import StripeConnectButton from "components/StripeConnect/StripeConnectButton"
import { useNavigate, useSearchParams } from "react-router-dom"
import PaymentSuccessModal from "pages/Teleconsultation/PaymentSuccessModal"
import StripeConnectLoginLink from "pages/Profile/StripeConnectLoginLink"
import StripeConnectLoginButton from "pages/Profile/StripeConnectLoginButton"
import NoTeleconsultationAccess from "pages/Teleconsultation/NoTeleconsultationAccess"

const Teleconsultation = () => {
  const { user, hasTeleconsultationAccess } = useAppContext()
  const [tabIndex, setTabIndex] = useState(0)

  const [searchParams] = useSearchParams()
  const paymentSuccess = searchParams.get("paymentSuccess")
  const navigate = useNavigate()
  const { isOpen: isPaymentSuccessOpen, onClose: onClosePaymentSuccess } =
    useDisclosure({
      defaultIsOpen: paymentSuccess === "true",
    })
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenStripeModal,
    onClose: onCloseStripeModal,
    onOpen: onOpenStripeModal,
  } = useDisclosure()
  const [editedTeleconsultation, setEditedTeleconsultation] = useState<
    TeleconsultationType | undefined
  >(undefined)

  const osteoId = user?.osteo?.id

  const { data: teleconsultations, isLoading } = useQuery(
    ["getAllTeleconsultation"],
    getAllTeleconsultation
  )

  const upcomingTeleconsultations =
    teleconsultations?.filter(
      (t) => !t.isFinished && !t.isPaid && !t.isCancelled
    ) || []

  const pendingPaymentTeleconsultations =
    teleconsultations?.filter((t) => t.isFinished && !t.isPaid) || []

  const paidTeleconsultations =
    teleconsultations?.filter((t) => t.isFinished && t.isPaid) || []

  const cancelledTeleconsultations =
    teleconsultations?.filter((t) => t.isCancelled) || []

  const handleOpenEditModal = (teleconsultation: TeleconsultationType) => {
    setEditedTeleconsultation(teleconsultation)
    onOpen()
  }

  const handleCloseEditModal = () => {
    setEditedTeleconsultation(undefined)
    onClose()
  }

  const tabData = [
    { label: "À venir", count: upcomingTeleconsultations.length },
    {
      label: "Paiement en attente",
      count: pendingPaymentTeleconsultations.length,
    },
    { label: "Réglées", count: paidTeleconsultations.length },
    { label: "Annulées", count: cancelledTeleconsultations.length },
  ]

  const tabStyle = {
    _selected: {
      color: "common.800",
      fontWeight: "bold",
      borderBottom: "2px solid",
      borderColor: "primary.600",
    },
  }

  const handleClick = () => {
    onOpen()
  }

  if (!hasTeleconsultationAccess) return <NoTeleconsultationAccess />

  return (
    <Flex direction="column" gap="24px">
      <Flex justifyContent="space-between">
        <Heading as="h1" fontSize={28} color="tertiary.500">
          Téléconsultation
        </Heading>
        <Flex flexDir={"column"} gap={2} justifyContent={"flex-end"}>
          <AButton
            text="Nouvelle téléconsultation"
            fontFamily="Montserrat"
            onClick={handleClick}
            variant="kine"
            leftIcon={<AddIcon fontSize={12} />}
          />
          {user?.osteo?.stripeConnectAccount && (
            <StripeConnectLoginButton
              accountId={user.osteo.stripeConnectAccount}
            />
          )}
        </Flex>
        {!user?.osteo.stripeConnectAccount && (
          <Box
            pos="absolute"
            top={"100px"}
            right={"45px"}
            w={365}
            bg="white"
            p={6}
            borderRadius={9}
          >
            <Text fontSize={18} fontWeight="bold" mb={4}>
              Créez votre profil de paiement
            </Text>
            <Text mb={4}>
              Pour permettre à vos patients de régler leurs téléconsultations,
              vous devez d’abord créer un compte Stripe Connect. Cela vous
              permettra d’envoyer des liens de paiement directement à vos
              patients et de recevoir vos paiements à distance.
            </Text>
            <Text>
              <b>Note :</b> Les paiements sont gérés par Stripe, sans transiter
              par Andrew®. Ils sont directement versés sur votre compte Stripe,
              avec des frais de 1,5 % appliqués par Stripe.
            </Text>
            <Flex mt={4} justifyContent="flex-end">
              <StripeConnectButton />
            </Flex>
          </Box>
        )}
      </Flex>
      <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
        <TabList>
          {tabData.map((tab, index) => (
            <Tab key={index} {...tabStyle}>
              {tab.label} ({tab.count})
            </Tab>
          ))}
        </TabList>
        {isLoading ? (
          <Spinner color="primary.500" />
        ) : (
          <TabPanels>
            <TabPanel paddingLeft={0}>
              <TeleconsultationList
                teleconsultations={upcomingTeleconsultations}
                handleOpenEditModal={handleOpenEditModal}
              />
            </TabPanel>
            <TabPanel paddingLeft={0}>
              <TeleconsultationList
                teleconsultations={pendingPaymentTeleconsultations}
                handleOpenEditModal={handleOpenEditModal}
              />
            </TabPanel>
            <TabPanel paddingLeft={0}>
              <TeleconsultationList
                teleconsultations={paidTeleconsultations}
                handleOpenEditModal={handleOpenEditModal}
              />
            </TabPanel>
            <TabPanel paddingLeft={0}>
              <TeleconsultationList
                teleconsultations={cancelledTeleconsultations}
                handleOpenEditModal={handleOpenEditModal}
              />
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>
      {osteoId && (
        <TeleconsultationModal
          isOpen={isOpen}
          onClose={handleCloseEditModal}
          osteoId={osteoId}
          isEditing={!!editedTeleconsultation}
          teleconsultation={editedTeleconsultation}
        />
      )}
      <StripeConnectModal
        isOpen={isOpenStripeModal}
        onClose={onCloseStripeModal}
      />
      <PaymentSuccessModal
        isOpen={isPaymentSuccessOpen}
        onClose={() => {
          onClosePaymentSuccess()
          navigate("/teleconsultation")
        }}
      />
    </Flex>
  )
}

export default Teleconsultation
