import { Box, Flex, Text } from "@chakra-ui/react"
import AAlertDialogConfirm from "components/AAlertDialogConfirm"
import useStoreSuccessPaymentSessionIndividual from "hooks/useStoreSuccessPaymentSession/individual"
import OnboardingStep1 from "pages/Onboarding/OnboardingStep1"
import OnboardingStep2 from "pages/Onboarding/OnboardingStep2"
import OnboardingStep3 from "pages/Onboarding/OnboardingStep3"
import OnboardingStep4 from "pages/Onboarding/OnboardingStep4"
import OnboardingStep5 from "pages/Onboarding/OnboardingStep5"
import useShouldDisplayOnboarding from "pages/Onboarding/useShouldDisplayOnboarding"
import { useEffect, useMemo, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import analytics from "utils/analytics"

type Props = {}

export default function Onboarding({}: Props) {
  const navigate = useNavigate()

  const [currentStep, setCurrentStep] = useState(1)
  const {} = useStoreSuccessPaymentSessionIndividual() //Export the sessionId if available in the query params.

  const [isSkipDialogOpen, setIsSkipDialogOpen] = useState(false)
  const { shouldDisplayOnboarding, displayedOnboarding } =
    useShouldDisplayOnboarding()
  const [patientIDToInvite, setPatientIDToInvite] = useState<
    number | undefined
  >(undefined)
  const totalSteps = useRef(5)

  const isLastStep = useMemo(
    () => currentStep === totalSteps.current,
    [currentStep, totalSteps]
  )

  useEffect(() => {
    //TODO : uncomment this when going to PROD
    //   if (!shouldDisplayOnboarding) {
    //     //If user is not supposed to do the onboarding, skip the page if he manually set the url to /onboarding
    //     navigate("/")
    //   }
  }, [shouldDisplayOnboarding])

  const handleNextStep = () => {
    if (currentStep < totalSteps.current) {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleFinsh = () => {
    analytics.logEvent("onboarding_finished")
    displayedOnboarding()
    navigate("/")
  }

  const skipOnboarding = () => {
    analytics.logEvent("onboarding_skipped", {
      step: currentStep,
    })
    displayedOnboarding()
    navigate("/")
  }

  const onPatientCreated = (patientId: number) => {
    setPatientIDToInvite(patientId)
    handleNextStep()
  }

  useEffect(() => {
    analytics.logEvent("onboarding_started")
  }, [])

  useEffect(() => {
    analytics.logEvent("onboarding_step_changed", {
      step: currentStep,
    })
  }, [currentStep])

  return (
    <Box
      flex={1}
      minHeight="100vh"
      height="100%"
      backgroundColor={"primary.100"}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {/* {!isFirstStep && !isLastStep && (
        <Box
          as="button"
          position="absolute"
          top={2}
          left={2}
          onClick={handlePreviousStep} //To act like a navigator back action.
          p="8px"
          bgColor="primary.200"
          w="fit-content"
          display="block"
          rounded="9px"
        >
          <Image src={arrowBack} width="32px" height="24px" />
        </Box>
      )} */}
      {currentStep === 1 && <OnboardingStep1 onNextStep={handleNextStep} />}
      {currentStep === 2 && (
        <OnboardingStep2 onPatientCreated={onPatientCreated} />
      )}
      {currentStep === 3 && (
        <OnboardingStep3
          patientId={patientIDToInvite}
          onNextStep={handleNextStep}
        />
      )}
      {currentStep === 4 && <OnboardingStep4 onNextStep={handleNextStep} />}
      {currentStep === 5 && <OnboardingStep5 onFinsh={handleFinsh} />}

      {!isLastStep && (
        <Flex
          mb={4}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap={4}
        >
          <Link
            to="#"
            onClick={() => setIsSkipDialogOpen(true)}
            style={{
              //  marginRight: "72px", //in order to display the text at the page center
              textDecoration: "underline",
              textUnderlineOffset: 4,
              textAlign: "center",
            }}
          >
            <Text fontSize="md">Passer l'intro</Text>
          </Link>
        </Flex>
      )}
      <AAlertDialogConfirm
        isOpen={isSkipDialogOpen}
        onClose={() => setIsSkipDialogOpen(false)}
        onConfirm={skipOnboarding}
        headerContent="Promis, c'est rapide !"
        bodyContent="Prenez juste quelques instants pour découvrir pourquoi Andrew® est un véritable atout pour vous et vos patients."
        cancelText="Je continue"
        destructiveActionText="Quitter"
        destructiveActionVariant="tertiary"
      />
    </Box>
  )
}
