import { CloseButton, Flex, FlexProps, Heading } from "@chakra-ui/react"
import ProgramOptionsButton from "pages/NewAppointment/components/SelectExercices/components/AddProgramModal/ProgramOptionsButton"
import React from "react"

interface AModalHeaderProps extends FlexProps {
  title: string
  info?: React.ReactNode
  handleEdit: () => void
  handleDelete: () => void
  handleClose: () => void
  canEdit?: boolean
}

const AModalHeader = ({
  title,
  info,
  handleEdit,
  handleDelete,
  handleClose,
  canEdit = false,
  ...props
}: AModalHeaderProps) => {
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} {...props}>
      <Flex alignItems="center" gap={2}>
        <Heading fontSize={20} textAlign="left">
          {title}
        </Heading>

        {info}
      </Flex>

      <Flex justifyContent={"flex-end"} alignItems={"center"} gap={2}>
        {canEdit && (
          <ProgramOptionsButton
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        )}
        <CloseButton
          w={10}
          h={8}
          _hover={{ bg: "gray.100" }}
          onClick={handleClose}
          position="relative"
        />
      </Flex>
    </Flex>
  )
}

export default AModalHeader
