import { Flex, Modal, ModalContent, ModalOverlay, Text } from "@chakra-ui/react"
import AButton from "components/AButton"
import React from "react"

interface DeleteProgramModalProps {
  deleteModalIsOpen: boolean
  deleteModalOnClose: () => void
  handleDelete: () => void
}

const DeleteProgramModal: React.FC<DeleteProgramModalProps> = ({
  deleteModalIsOpen,
  deleteModalOnClose,
  handleDelete,
}) => {
  return (
    <Modal isOpen={deleteModalIsOpen} onClose={deleteModalOnClose} isCentered>
      <ModalOverlay bg="blackAlpha.400" />
      <ModalContent p={8}>
        <Text>Voulez-vous vraiment supprimer ce programme ?</Text>

        <Flex gap={4} justifyContent="flex-end" mt={8}>
          <AButton
            text="Annuler"
            variant="tertiary"
            onClick={deleteModalOnClose}
          />
          <AButton text="Supprimer" variant="primary" onClick={handleDelete} />
        </Flex>
      </ModalContent>
    </Modal>
  )
}

export default DeleteProgramModal
