import { Text } from "@chakra-ui/react"

type OnboardingData = {
  kine: {
    headline: string
    items: JSX.Element[]
    video: string
  }
  osteo: {
    headline: string
    items: JSX.Element[]
    video: string
  }
  podo: {
    headline: string
    items: JSX.Element[]
    video: string
  }
}
export const OnboardingData: OnboardingData = {
  kine: {
    headline: "La révolution de la rééducation en kinésithérapie",
    items: [
      <>
        Une <Text as="b">expérience patient</Text> qui décuple l'adhésion au
        traitement
      </>,
      <>
        Prescription d'<Text as="b">exercices en vidéo</Text>
      </>,
      <>
        Capsules d'<Text as="b">éducation santé</Text> pour les patients
      </>,
      <>
        Modules <Text as="b">e-learning</Text> pour les kinésithérapeutes
      </>,
      <>
        Outils de <Text as="b">téléconsultation</Text> et{" "}
        <Text as="b">télépaiement</Text>
      </>,
      <>
        Suivi <Text as="b">patient-centré</Text> avec des centaines d'heures de
        contenus santé et bien-être
      </>,
    ],
    video:
      "https://vz-cac8b715-1e1.b-cdn.net/042a33b9-cc6d-48c7-a7cd-82584c0c39c1/play_720p.mp4",
  },
  osteo: {
    headline: "Devenez le practicien que tout le monde recommande",
    items: [
      <>
        Créez des <Text as="b">programmes de mobilisation</Text> pour vos
        patients
      </>,
      <>
        Nouez un lien <Text as="b">patient-thérapeute</Text> fort
      </>,
      <>
        Développez votre activité grâce à notre{" "}
        <Text as="b">référencement</Text>
      </>,
      <>
        <Text as="b">Communiquez</Text> auprès de toute votre patientèle en un
        clic
      </>,
      <>
        Prise en charge <Text as="b">patient centrée</Text> : stresse, anxiété,
        sédentarité, éducation thérapeutique
      </>,
    ],
    video:
      "https://andrewapp.b-cdn.net/Teaser_Andrew_Version_Therapeute_v1_7e60dc29e4.mp4",
  },
  podo: {
    headline: "La révolution de la prise en charge en podologie",
    items: [
      <>
        Une <Text as="b">expérience patient</Text> qui décuple l'adhésion au
        traitement
      </>,
      <>
        Prescription d'<Text as="b">exercices en vidéo</Text>
      </>,
      <>
        Capsules d'<Text as="b">éducation santé</Text> pour les patients
      </>,
      <>
        Modules <Text as="b">e-learning</Text> pour les podologues
      </>,
      <>
        Outils de <Text as="b">téléconsultation</Text> et{" "}
        <Text as="b">télépaiement</Text>
      </>,
      <>
        Suivi <Text as="b">patient-centré</Text> avec des centaines d'heures de
        contenus santé et bien-être
      </>,
    ],
    video:
      "https://vz-cac8b715-1e1.b-cdn.net/49fb2080-e9ab-4e62-9f19-2fd3b55651df/play_720p.mp4",
  },
}
