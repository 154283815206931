import { PatientType, UserReviewType } from "api/patient"
import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import getUtcDate from "utils/getUtcDate"
import { IoArrowUndo } from "react-icons/io5"

interface ReviewProps {
  review: UserReviewType
  patient: PatientType
  width?: string
}

const Review = ({ review, patient, width = "100%" }: ReviewProps) => {
  const getTitle = (note: number) => {
    if (note < 3) {
      return "Patient non satisfait"
    } else return "🥳 Patient satisfait"
  }
  return (
    <Box
      py={4}
      px={2}
      bg="common.100"
      borderRadius={8}
      w={width}
      position="relative"
    >
      <Link
        href={`mailto:${patient.account?.email}?subject=Retour votre évaluation&body=${review.comment}`}
        position="absolute"
        top={4}
        right={2}
        title="Répondre par mail"
      >
        <IoArrowUndo />
      </Link>
      <Heading as="h3" fontSize={14}>
        Évaluation
      </Heading>
      <Text>{getTitle(review.rating)}</Text>
      {review.comment && (
        <Text mt={2} fontStyle="italic">
          {review.comment}
        </Text>
      )}
      <Flex direction={"row"} justifyContent="flex-end" mt={2}>
        <Text>le {format(getUtcDate(review.createdAt), "dd/MM/yyyy")}</Text>
      </Flex>
    </Box>
  )
}

export default Review
