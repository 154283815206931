import {
  Box,
  Flex,
  Heading,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react"
import { PatientType, UserPainFeedbackType } from "api/patient"
import { format } from "date-fns"
import React from "react"
import getUtcDate from "utils/getUtcDate"
import { IoEllipsisHorizontalSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom"

interface PainReviewProps {
  review: UserPainFeedbackType
  patient: PatientType
  width?: string
}

const PainReview = ({ review, patient, width = "100%" }: PainReviewProps) => {
  const navigate = useNavigate()
  return (
    <Box
      py={4}
      px={2}
      bg="common.100"
      borderRadius={8}
      w={width}
      pos="relative"
    >
      <Flex alignItems="center" gap={2}>
        <Heading as="h3" fontSize={14}>
          Douleur exercice
        </Heading>
        {review.isMaximum && (
          <Text
            bg="primary.700"
            color="white"
            fontSize={14}
            px={2}
            py={"2px"}
            borderRadius={4}
          >
            critique
          </Text>
        )}
      </Flex>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<IoEllipsisHorizontalSharp />}
          position="absolute"
          top={6}
          right={0}
          h="2px"
          onClick={(e) => e.stopPropagation()}
          bg={"transparent"}
          _hover={{ bg: "transparent" }}
          _focus={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
        />
        <MenuList>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              navigate(
                `/patients/${patient.id}/appointments/${review.appointment.id}/edit`
              )
            }}
          >
            Modifier
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              // href={`mailto:${patient.account?.email}?subject=Retour votre évaluation&body=${review.comment}`}
              window.open(
                `mailto:${patient.account?.email}?subject=Retour votre évaluation&body=${review.description}`,
                "_blank"
              )
            }}
          >
            Répondre
          </MenuItem>
        </MenuList>
      </Menu>
      <Text>{review.exercice.videoGroup.title}</Text>
      {review.description && (
        <Text mt={2} fontStyle="italic">
          {review.description}
        </Text>
      )}
      <Flex direction={"row"} justifyContent="flex-end" mt={2}>
        <Text>le {format(getUtcDate(review.createdAt), "dd/MM/yyyy")}</Text>
      </Flex>
    </Box>
  )
}

export default PainReview
