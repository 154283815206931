import { AddIcon, ArrowLeftIcon, ChevronLeftIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Heading, Image, Link, Text } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import {
  getProNotifications,
  ProNotificationType,
  readAllProNotifications,
  readProNotification,
} from "api/proNotification"
import ANewButton from "components/ANewButton"
import SearchBar from "components/SearchBar"
import { format } from "date-fns"
import useCenterSubscription from "hooks/useCenterSubscription"
import useJobIsOsteo from "hooks/useJobIsOsteo"
import { useEffect, useState } from "react"
import { FaBook } from "react-icons/fa"
import { IoMdTrendingUp } from "react-icons/io"
import { IoStatsChart } from "react-icons/io5"
import { Link as RouterLink, useLocation } from "react-router-dom"
import getUtcDate from "utils/getUtcDate"
import { AdminCenterCurrentScope, useAppContext } from "../AppContext"
import andrewLogo from "../assets/ANDREW_logo_color.png"
import exercicesIcon from "../assets/icons/accessibility_new.svg"
import bellIcon from "../assets/icons/bell.svg"
import checkIcon from "../assets/icons/check_circle_outline.svg"
import emailIcon from "../assets/icons/email.svg"
import consultIcon from "../assets/icons/folder_shared.svg"
import homeIcon from "../assets/icons/home.svg"
import explorerIcon from "../assets/icons/ondemand_video.svg"
import externalLinkIcon from "../assets/icons/open_in_new.svg"
import noteIcon from "../assets/icons/sticky_note.svg"
import AddOsteo from "./AddOsteo"
import AProfileButton from "./AProfileButton/AProfileButton"
import { centerGroupQueryKeys } from "pages/Center/constants/queryKeys"
import { getCenterGroup } from "api/centerGroup"

const FILTER_ALL = 0
const FILTER_NOT_READ = 1

const Notifications = ({
  isOpen,
  onClose,
  notifications,
  onReadNotification,
  onReadAllNotifications,
}: {
  isOpen: boolean
  onClose: () => void
  notifications: ProNotificationType[]
  onReadNotification: (
    index: number,
    notification: ProNotificationType
  ) => Promise<void>
  onReadAllNotifications: () => Promise<void>
}) => {
  const [selectedFilter, setSelectedFilter] = useState(FILTER_ALL)

  return (
    <Flex
      position="fixed"
      left="240px"
      top={0}
      width="332px"
      height="100vh"
      maxHeight="100vh"
      overflowY="auto"
      sx={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "primary.300",
          borderRadius: "24px",
        },
      }}
      style={{
        transform: isOpen ? "translateX(0)" : "translateX(-332px)",
        transition: "transform 0.3s ease-out",
      }}
      bg="primary.200"
      zIndex={40}
      p="18px"
      direction="column"
      alignItems="center"
    >
      <Flex justifyContent="space-between" w="full" alignItems="center">
        <Heading fontSize="18px">Notifications</Heading>
        <Button
          bg=""
          _hover={{ bg: "", opacity: 0.7 }}
          _active={{ bg: "" }}
          p={0}
          onClick={onClose}
        >
          <ArrowLeftIcon w="18px" />
        </Button>
      </Flex>
      <Flex
        w="200px"
        justifyContent="space-around"
        bg="primary.300"
        rounded="8px"
        p={1}
        position="relative"
        mt={"32px"}
        mb={notifications.length !== 0 ? "16px" : "24px"}
      >
        <Button
          w="100%"
          p={1}
          lineHeight="100%"
          h="fit-content"
          bg={selectedFilter === FILTER_ALL ? "white" : ""}
          _hover={{ bg: "" }}
          _active={{ bg: "" }}
          fontSize="14px"
          onClick={() => setSelectedFilter(FILTER_ALL)}
        >
          Tout
        </Button>
        <Button
          w="100%"
          p={1}
          lineHeight="100%"
          h="fit-content"
          bg={selectedFilter === FILTER_NOT_READ ? "white" : ""}
          _hover={{ bg: "" }}
          _active={{ bg: "" }}
          fontSize="14px"
          onClick={() => setSelectedFilter(FILTER_NOT_READ)}
        >
          Non lu
        </Button>
      </Flex>
      {notifications.length !== 0 && (
        <Flex w={"full"} justify={"flex-end"} mb={"12px"}>
          <Button
            p={0}
            w="fit-content"
            h="fit-content"
            _hover={{ bg: "", opacity: 0.7 }}
            _active={{ bg: "" }}
            bg=""
            onClick={onReadAllNotifications}
          >
            <Text fontSize="10px" textDecoration={"underline"}>
              Tout marquer comme lu
            </Text>
          </Button>
        </Flex>
      )}
      <Flex direction="column" w="full" gap="8px">
        {notifications.map((notification, index) =>
          selectedFilter === FILTER_NOT_READ && notification.hasRead ? null : (
            <Flex
              key={index}
              w="full"
              bg="white"
              justifyContent="space-between"
              p="8px"
              rounded="2px"
              gap="8px"
              alignItems="center"
              opacity={notification.hasRead ? 0.6 : 1}
              _hover={{ opacity: 0.8 }}
            >
              {notification.link ? (
                <Box
                  onClick={() => {
                    onClose()
                    onReadNotification(index, notification)
                  }}
                  w="full"
                >
                  <Link
                    _hover={{ textDecoration: "none" }}
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                    to={notification.link}
                    as={RouterLink}
                    w="full"
                  >
                    <Text fontSize="14px">{notification.text}</Text>
                    <Text fontSize="10px">
                      {format(getUtcDate(notification.createdAt), "dd/MM/yyyy")}
                    </Text>
                  </Link>
                </Box>
              ) : (
                <Box>
                  <Text fontSize="14px">{notification.text}</Text>
                  <Text fontSize="10px">
                    {format(getUtcDate(notification.createdAt), "dd/MM/yyyy")}
                  </Text>
                </Box>
              )}
              {!notification.hasRead && (
                <Button
                  p={0}
                  w="18px"
                  minW="18px"
                  minH="18px"
                  h="18px"
                  _hover={{ bg: "", opacity: 0.7 }}
                  _active={{ bg: "" }}
                  bg=""
                  onClick={() => onReadNotification(index, notification)}
                >
                  <Image src={checkIcon} w="18px" h="18px" />
                </Button>
              )}
            </Flex>
          )
        )}
      </Flex>
    </Flex>
  )
}

const AHeader = () => {
  const { pathname } = useLocation()
  const { user, jwt } = useAppContext()

  const { hasCurrentCenter, isLoading, isError } = useCenterSubscription()
  const [isMenuOpen, setIsMenuOpen] = useState(true)
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const [notifications, setNotifications] = useState<ProNotificationType[]>([])

  const handleFetchNotifications = async () => {
    const fetchedNotifications = await getProNotifications()
    setNotifications(fetchedNotifications)
  }

  const handleReadNotification = async (
    index: number,
    notification: ProNotificationType
  ) => {
    await readProNotification(notification)
    setNotifications((prev) => {
      const newNotifications = [...prev]
      newNotifications[index].hasRead = true
      return newNotifications
    })
  }

  const handleReadAllNotifications = async () => {
    await readAllProNotifications()
    setNotifications((prev) =>
      prev.map((notification) => ({ ...notification, hasRead: true }))
    )
  }

  const isJobOsteo = useJobIsOsteo()

  useEffect(() => {
    handleFetchNotifications()
  }, [])

  const ToggleMenuButton = () => (
    <Button
      position="absolute"
      right={-4}
      top="50%"
      transform={`translateY(-50%) ${!isMenuOpen ? "rotateY(180deg)" : ""}`}
      bg=""
      _hover={{
        opacity: 0.7,
      }}
      _active={{}}
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    >
      <ChevronLeftIcon boxSize={6} />
    </Button>
  )

  const unreadNotifications = notifications.filter(
    (notification) => !notification.hasRead
  )

  const { currentScope } = useAppContext()

  const { data: centerGroup } = useQuery(
    centerGroupQueryKeys.current(
      (currentScope as AdminCenterCurrentScope)?.centerGroupId
    ),
    () =>
      getCenterGroup((currentScope as AdminCenterCurrentScope).centerGroupId)
  )

  const currentCenter = centerGroup?.centers.find(
    (center) => center.id === (currentScope as AdminCenterCurrentScope).id
  )

  if (!isMenuOpen)
    return (
      <Box w="24px" minW="24px" zIndex={10}>
        <Box h="100vh" w="24px" minW="24px" position="fixed" bg="primary.200">
          <ToggleMenuButton />
        </Box>
      </Box>
    )
  return (
    <Box w="250px" minW="250px" position="relative">
      <Flex
        bg={
          currentCenter && currentCenter.color
            ? currentCenter.color
            : "primary.200"
        }
        w="250px"
        minW="250px"
        direction="column"
        justifyContent="space-between"
        px="16px"
        py="16px"
        h="100vh"
        position="fixed"
        zIndex={50}
        maxH="100vh"
        overflowY="auto"
        overflowX="hidden"
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "primary.300",
            borderRadius: "24px",
          },
        }}
      >
        <ToggleMenuButton />
        <Flex fontWeight={600} direction="column" gap="24px">
          <Link as={RouterLink} to="/" _hover={{ textDecoration: "none" }}>
            <Flex alignItems="center">
              <Image src={andrewLogo} w={81} />{" "}
              <Text color="tertiary.500" fontSize={16} fontWeight={700} ml={1}>
                Pro
              </Text>
            </Flex>
          </Link>
          <Flex direction={"column"} gap="8px">
            <AProfileButton />
            <ANewButton
              text="Nouveau patient"
              icon={<AddIcon />}
              as={RouterLink}
              to="/patients/new"
            />
            {user?.osteo?.canAddOsteos === true && <AddOsteo />}
          </Flex>
          <Flex>
            <SearchBar
              isDisabled={hasCurrentCenter && (isLoading || isError)}
            />
          </Flex>
          <Flex direction="column" gap="8px">
            <Link
              as={RouterLink}
              to="/"
              bgColor={pathname === "/" ? "primary.300" : ""}
              rounded="9px"
              _hover={{
                textDecoration: "none",
                bgColor: "primary.300",
              }}
              _active={{
                bgColor: "primary.400",
              }}
              p="8px"
              position="relative"
              display="flex"
              alignItems="center"
              gap="8px"
              textColor="common.700"
            >
              <Image src={homeIcon} width={"24px"} height={"24px"} />
              <Text>Dashboard</Text>
            </Link>
            <Link
              as={RouterLink}
              to="/dashboard"
              bgColor={pathname === "/dashboard" ? "primary.300" : ""}
              rounded="9px"
              _hover={{
                textDecoration: "none",
                bgColor: "primary.300",
              }}
              _active={{
                bgColor: "primary.400",
              }}
              p="8px"
              position="relative"
              display="flex"
              alignItems="center"
              gap="8px"
              textColor="common.700"
            >
              <Image src={consultIcon} width="24px" height="24px" />
              <Text>Consultations</Text>
            </Link>
            <Link
              as={RouterLink}
              to="/explorer"
              bgColor={pathname === "/explorer" ? "primary.300" : ""}
              rounded="9px"
              _hover={{
                textDecoration: "none",
                bgColor: "primary.300",
              }}
              _active={{
                bgColor: "primary.400",
              }}
              p="8px"
              position="relative"
              display="flex"
              alignItems="center"
              gap="8px"
              textColor="common.700"
            >
              <Image src={explorerIcon} width="24px" height="24px" />
              <Text>Explorer</Text>
            </Link>
            <Link
              as={RouterLink}
              to="/exercices"
              bgColor={pathname === "/exercices" ? "primary.300" : ""}
              rounded="9px"
              _hover={{
                textDecoration: "none",
                bgColor: "primary.300",
              }}
              _active={{
                bgColor: "primary.400",
              }}
              p="8px"
              position="relative"
              display="flex"
              alignItems="center"
              gap="8px"
              textColor="common.700"
            >
              <Image src={exercicesIcon} width="24px" height="24px" />
              <Text>Exercices</Text>
            </Link>
            <Button
              bg={isNotificationsOpen ? "primary.300" : ""}
              rounded="9px"
              _hover={{
                textDecoration: "none",
                bgColor: "primary.300",
              }}
              _active={{
                bgColor: "primary.400",
              }}
              p="8px"
              position="relative"
              display="flex"
              alignItems="center"
              gap="8px"
              textColor="common.700"
              onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
              justifyContent="left"
            >
              <Image src={bellIcon} width="22px" height="22px" />
              <Text>Notifications</Text>
              {unreadNotifications.length !== 0 && (
                <Text
                  position="absolute"
                  right="6px"
                  top="50%"
                  transform="translateY(-50%)"
                  px="5px"
                  bg="primary.500"
                  rounded="4px"
                  textColor="white"
                  fontSize="14px"
                >
                  {unreadNotifications.length}
                </Text>
              )}
            </Button>
            <Link
              as={RouterLink}
              to="/messages"
              bgColor={pathname === "/messages" ? "primary.300" : ""}
              rounded="9px"
              _hover={{
                textDecoration: "none",
                bgColor: "primary.300",
              }}
              _active={{
                bgColor: "primary.400",
              }}
              p="8px"
              position="relative"
              display="flex"
              alignItems="center"
              gap="8px"
              textColor="common.700"
            >
              <Image src={emailIcon} width="24px" height="24px" />
              <Text>Messages</Text>
            </Link>
            {!isJobOsteo && (
              <Link
                as={RouterLink}
                to="/teleconsultation"
                bgColor={pathname === "/teleconsultation" ? "primary.300" : ""}
                rounded="9px"
                _hover={{
                  textDecoration: "none",
                  bgColor: "primary.300",
                }}
                _active={{
                  bgColor: "primary.400",
                }}
                p="8px"
                position="relative"
                display="flex"
                alignItems="center"
                gap="8px"
                textColor="common.700"
              >
                <Image src={consultIcon} width="24px" height="24px" />
                <Text>Téléconsultation</Text>
              </Link>
            )}
            {user?.osteo?.canAddOsteos && (
              <Link
                as={RouterLink}
                to="/statistiques"
                bgColor={pathname === "/statistiques" ? "primary.300" : ""}
                rounded="9px"
                _hover={{
                  textDecoration: "none",
                  bgColor: "primary.300",
                }}
                _active={{
                  bgColor: "primary.400",
                }}
                p="8px"
                position="relative"
                display="flex"
                alignItems="center"
                gap="8px"
                textColor="common.700"
              >
                <IoStatsChart width={24} fontSize={24} />
                Statistiques
              </Link>
            )}
          </Flex>
        </Flex>
        <Flex fontWeight={600} direction="column" gap="8px">
          <Link
            as={RouterLink}
            to="/e-learning"
            color={pathname === "/e-learning" ? "primary.300" : ""}
            rounded="9px"
            _hover={{
              textDecoration: "none",
              bgColor: "primary.300",
            }}
            _active={{
              bgColor: "primary.400",
            }}
            p="8px"
            position="relative"
            display="flex"
            alignItems="center"
            textColor="common.700"
            justifyContent="space-between"
          >
            <Flex gap="8px" alignItems="center">
              <FaBook width={24} fontSize={24} />
              <Text>Micro-learning</Text>
            </Flex>
          </Link>

          {isJobOsteo && (
            <Link
              as={RouterLink}
              to="/developper-patientele"
              color={pathname === "/developper-patientele" ? "primary.300" : ""}
              rounded="9px"
              _hover={{
                textDecoration: "none",
                bgColor: "primary.300",
              }}
              _active={{
                bgColor: "primary.400",
              }}
              p="8px"
              position="relative"
              display="flex"
              alignItems="center"
              textColor="common.700"
              justifyContent="space-between"
            >
              <Flex gap="8px" alignItems="center">
                <IoMdTrendingUp width={24} fontSize={24} />
                <Text lineHeight={"16px"}>Développer sa patientèle</Text>
              </Flex>
            </Link>
          )}
          {isJobOsteo && (
            <Link
              as={"a"}
              href={`https://forum.andrewapp.fr/?jwt=${jwt}`}
              target={"_blank"}
              color={"common.700"}
              rounded="9px"
              _hover={{
                textDecoration: "none",
                bgColor: "primary.300",
              }}
              _active={{
                bgColor: "primary.400",
              }}
              p="8px"
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              textColor="common.700"
            >
              <Flex gap="8px" alignItems="center">
                <Image src={noteIcon} width="24px" height="24px" />
                <Text>Forum</Text>
              </Flex>
              <Image src={externalLinkIcon} width="24px" height="24px" />
            </Link>
          )}
        </Flex>
      </Flex>
      <Notifications
        isOpen={isNotificationsOpen}
        onClose={() => setIsNotificationsOpen(false)}
        notifications={notifications}
        onReadNotification={handleReadNotification}
        onReadAllNotifications={handleReadAllNotifications}
      />
    </Box>
  )
}

export default AHeader
