import { Box, Flex, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
} from "api/appointmentReason"
import { deleteTrainingVideoUploaded } from "api/trainingVideoExercice"
import AUploadedExerciceCardBase from "components/AUploadedExerciceCard/Base"
import DeleteUplaodedExerciceModal from "components/AUploadedExerciceCard/DeleteUplaodedExerciceModal"
import ProgramOptionsButton from "pages/NewAppointment/components/SelectExercices/components/AddProgramModal/ProgramOptionsButton"
import AddUploadedExerciceModal from "pages/NewAppointment/components/SelectExercices/components/AddUploadedExerciceModal"
import NewExerciceModal from "pages/NewProgram/components/NewExerciceModal"
import React, { useEffect, useState } from "react"

interface IProps {
  video: SelectedUploadedExerciceType
  selectedExercices?: SelectedUploadedExerciceType[]
  setSelectedExercices?: React.Dispatch<
    React.SetStateAction<SelectedUploadedExerciceType[]>
  >
  setSelectedVideoOrder?: React.Dispatch<
    React.SetStateAction<SelectedVideoOrderType[]>
  >
  isForKineOrPodo?: boolean
  appointmentReason?: number | undefined
  canEdit?: boolean
}

const AUploadedExerciceCard: React.FC<IProps> = ({
  video,
  selectedExercices,
  setSelectedExercices,
  setSelectedVideoOrder,
  isForKineOrPodo = false,
  appointmentReason,
  canEdit = false,
}) => {
  const [isSelecting, setIsSelecting] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isSelected = !selectedExercices
    ? false
    : selectedExercices.some((exercice) => exercice.id === video.id)

  const onClickSelection = isSelected ? () => null : () => setIsSelecting(true)

  const handleOsteoAdd = () => {
    if (!setSelectedExercices || !setSelectedVideoOrder) return

    setSelectedVideoOrder((prev) => [
      ...prev,
      {
        id: video.id,
        type: "uploaded_video",
      },
    ])

    setSelectedExercices((prev) => [
      ...prev,
      {
        ...video,
        duration: video.config.duration,
        series: null,
        repetitions: null,
        restDuration: null,
        type: null,
        canUseWeight: false,
        weight: null,
        recommendation: video.config.description,
      },
    ])
  }

  const onAdd = () => {
    if (!setSelectedExercices) return
    if (isForKineOrPodo) {
      onOpen()
      return
    } else {
      handleOsteoAdd()
      return
    }
  }
  useEffect(() => {
    setIsSelecting(false)
  }, [isSelected])
  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure()
  const {
    isOpen: deleteModalIsOpen,
    onOpen: deleteModalOnOpen,
    onClose: deleteModalOnClose,
  } = useDisclosure()
  const toast = useToast()

  const queryClient = useQueryClient()
  const deleteTrainingVideoUploadedMutation = useMutation({
    mutationFn: deleteTrainingVideoUploaded,
    onSuccess: async () => {
      await queryClient.refetchQueries([
        "uploadedVideos",
        appointmentReason ?? -1,
      ])
      deleteModalOnClose()
      toast({
        status: "success",
        title: "Exercice supprimé avec succès",
      })
      onClose()
    },
    onError: () => {
      toast({
        status: "error",
        title: "Une erreur est survenue lors de la suppression",
      })
    },
  })

  const handleDelete = () => {
    deleteTrainingVideoUploadedMutation.mutate(video.id)
  }

  const handleEditUploadedExercice = () => {
    onClose()
    onEditModalOpen()
  }

  return (
    <>
      <Box bg="white" borderRadius="10px" p={2} w={"calc((100% - 32px) / 3)"}>
        <Flex flexDir={"column"} h="full" position="relative">
          <Text fontWeight={700} fontSize={14} pb={2}>
            {video.title}
          </Text>
          {canEdit && (
            <Box pos="absolute" top={-1} right={0}>
              <ProgramOptionsButton
                handleEdit={handleEditUploadedExercice}
                handleDelete={deleteModalOnOpen}
              />
            </Box>
          )}
          <AUploadedExerciceCardBase
            video={video}
            isSelecting={isSelecting}
            isSelected={isSelected}
            isDisabled={isSelected}
            onClick={onClickSelection}
            onAdd={onAdd}
            isAddCard={selectedExercices !== undefined}
            appointmentReason={appointmentReason}
            canEdit={canEdit}
          />
        </Flex>
      </Box>
      {isOpen && setSelectedExercices && (
        <AddUploadedExerciceModal
          isOpen={isOpen}
          onClose={onClose}
          video={video}
          setSelectedExercices={setSelectedExercices}
          setSelectedVideoOrder={setSelectedVideoOrder}
        />
      )}
      <DeleteUplaodedExerciceModal
        isOpen={deleteModalIsOpen}
        onClose={deleteModalOnClose}
        handleDelete={handleDelete}
      />
      {isEditModalOpen && (
        <NewExerciceModal
          isOpen={isEditModalOpen}
          onClose={onEditModalClose}
          currentVideo={video}
        />
      )}
    </>
  )
}

export default AUploadedExerciceCard
