import { Heading } from "@chakra-ui/react"

type Props = {
  title: string
}

export default function ExerciceListSectionHeading({ title }: Props) {
  return (
    <Heading
      as="h6"
      fontSize="18px"
      lineHeight="100%"
      flex={1}
      p={4}
      my={4}
      backgroundColor={"primary.200"}
      borderRadius={"4px"}
    >
      {title}
    </Heading>
  )
}
