import { DeleteIcon, EditIcon } from "@chakra-ui/icons"
import {
  IconButton,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import React from "react"
import { IoEllipsisHorizontalSharp } from "react-icons/io5"

interface ProgramOptionsButtonProps extends MenuButtonProps {
  handleEdit: () => void
  handleDelete: () => void
  iconColor?: string
}

const ProgramOptionsButton = ({
  handleEdit,
  handleDelete,
  iconColor = "black",
  ...props
}: ProgramOptionsButtonProps) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<IoEllipsisHorizontalSharp color={iconColor} />}
        onClick={(e) => e.stopPropagation()}
        bg={"transparent"}
        _hover={{ bg: "gray.100" }}
        opacity={1}
        w={10}
        h={8}
        {...props}
      />
      <MenuList>
        <MenuItem
          onClick={(e) => {
            handleEdit()
          }}
          icon={<EditIcon />}
        >
          Modifier
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            handleDelete()
          }}
          icon={<DeleteIcon />}
          color="danger.500"
        >
          Supprimer
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default ProgramOptionsButton
