import axiosInstance from "api/axiosInstance"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"

export type TrainingVideoExerciceInputType = {
  title: string
  description?: string
  video: File
  level: number
  zones: ZonePathologieType[]
  series: number
  rest: number
  value: {
    idType: number
    value: number
  }
}

export const createTrainingVideoUploaded = async (data: FormData) => {
  const response = await axiosInstance.post(
    `/training-video-uploaded/newTrainingVideoUploaded`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )
  return response.data
}

export const updateTrainingVideoUploaded = async ({
  id,
  data,
}: {
  id: number
  data: FormData
}) => {
  const response = await axiosInstance.put(
    `/training-video-uploaded/${id}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )
  return response.data
}

export const deleteTrainingVideoUploaded = async (id: number) => {
  const response = await axiosInstance.delete<{ success: boolean }>(
    `/training-video-uploaded/${id}`
  )
  return response.data.success
}
