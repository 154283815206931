import { Flex } from "@chakra-ui/react"
import useJobIsKineOrPodo from "hooks/useIsJobKineOrPodo"
import NewAppointment from "pages/NewAppointment/NewAppointment"

type Props = {
  patientId?: number
  onNextStep: () => void
}

export default function OnboardingStep3({ patientId, onNextStep }: Props) {
  const isJobKineOrPodo = useJobIsKineOrPodo()

  if (!patientId) return null

  return (
    <Flex backgroundColor="primary.100">
      <NewAppointment
        patientIdFromProps={patientId}
        treatmentIdFromProps={isJobKineOrPodo ? "0" : undefined}
        isOnboarding={true}
        onAppointmentCreated={onNextStep}
      />
    </Flex>
  )
}
